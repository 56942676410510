import { Component, OnInit } from '@angular/core';
import { ItemList } from '../../store/interface/item';
import { Store } from '@ngrx/store';
import { State } from '../../store/reducer/item.reducer'
import * as ItemAction from '../../store/action/item.action';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-additem-container',
  templateUrl: './additem-container.component.html',
  styleUrls: ['./additem-container.component.scss']
})
export class AdditemContainerComponent implements OnInit {


  constructor(private store: Store<State>) { }

  ngOnInit() {
  }

}
