import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'posted'
})
export class PostedPipe implements PipeTransform {
  transform(value: any, isPost:any): any {
    return isPost ? value.filter(invoice =>{
      return  invoice.postedStatus == true})
      : value
  }

}
