import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ReturnInvoiceService } from './../../services';
import { ReturnInvoiceModalComponent } from '../return-invoice-modal/return-invoice-modal.component';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { FFromDatePipe, FToDatePipe, FInvoicePipe } from './../../pipe/index';
import * as jsPDF from 'jspdf';

@Component({
  selector: 'app-return-invoice',
  templateUrl: './return-invoice.component.html',
  styleUrls: ['./return-invoice.component.scss']
})

export class ReturnInvoiceComponent implements OnInit {

  private invoiceModalRef: NgbModalRef;
  public fromDate: string = '';
  public toDate: string = '';
  public searchName: string = '';
  public returnInvoiceList = [];
  public errMsg: string;

  constructor(
    public RIS: ReturnInvoiceService,
    private _invoiceModal: NgbModal,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    public fromDatePipe: FFromDatePipe,
    public toDatePipe: FToDatePipe,
    public fInvoicePipe:FInvoicePipe
  ) { }

  ngOnInit() {
    this.RIS.getReturnInvoice().subscribe(result => {
      this.returnInvoiceList = result;
    },err=>{
      this.errMsg = err.error.message;
      this.toastr.error(err.error.message,'Error!');
    });
  }

  invoiceView(returnOInvice) {
    this.invoiceModalRef = this._invoiceModal.open(ReturnInvoiceModalComponent, { size: 'lg' });
    this.invoiceModalRef.componentInstance.returnInvoiceDetail = returnOInvice
    this.invoiceModalRef.result.then((result) => {
    }, (reason) => { })
  }

  clearAll() {
    this.searchName = '';
    this.fromDate = null;
    this.toDate = null;
  }

  captureScreen () {
    if (this.returnInvoiceList) {
      let list = this.fInvoicePipe.transform(this.returnInvoiceList, this.searchName,'invoiceNo')
      list = this.fromDatePipe.transform(list, this.fromDate, 'date');
      list = this.toDatePipe.transform(list, this.toDate, 'date');
      const doc = new jsPDF();

      doc.setFontSize(18)
      doc.text('Return Invoices list', 14, 22)
      doc.autoTable({ html: '#my-table' })
      // Or use javascript directly:
      doc.autoTable({
        head: [
          [
            'Return Invoice Date',
            'Return Invoice No',
            'Invoice No',
            'Munnal Book No',
            'Total ReturnQty'
          ]
        ],
        body: list.reduce((acc, cur) => {
          let date = this.datepipe.transform(cur.createdAt, 'yyyy-MM-dd')
          acc.push([
            date,
            cur.returnInvoiceNo,
            cur.invoiceNo,
            cur.manualBookNo,
            cur.totalReturnQty
          ])
          return acc
        }, []),
        startY: 30,
        showHead: 'firstPage',
        styles: { overflow: 'ellipsize', cellWidth: 'wrap' },
        columnStyles: { text: { cellWidth: 'auto' } }
      })
      let date = new Date().toDateString()
      doc.save(`return-invoices-${date}.pdf`)
    }
  }
}
