import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StockService, SharedService } from './../../services';
import { SearchBrandPipe, FFromDatePipe, FToDatePipe } from './../../pipe/index';
import { ToastrService } from 'ngx-toastr';
import jsPDF from 'jspdf'
import 'jspdf-autotable'

@Component({
  selector: 'app-show-damage',
  templateUrl: './show-damage.component.html',
  styleUrls: ['./show-damage.component.scss']
})
export class ShowDamageComponent implements OnInit {
  
  public stockDamageList = [];
  public errMsg: string = '';
  public fromDate: Date;
  public toDate: Date;
  public searchName: string = '';


  constructor(
    private SS: StockService,
    private sharedService: SharedService,
    private router: Router,
    public SearchBrandPipe: SearchBrandPipe,
    public fromDatePipe: FFromDatePipe,
    public toDatePipe: FToDatePipe,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.SS.getDamageStock().subscribe(result => {
      this.stockDamageList = result
    },err=>{
      this.errMsg = err.error.message;
      this.toastr.error(err.error.message,'Error!');
    });
  }

  createStock() {
    this.router.navigateByUrl('/dashboard/add-stock')
  }

  captureScreen() {
    if (this.stockDamageList) {
      let list = this.SearchBrandPipe.transform(this.stockDamageList, this.searchName, 'brandDamage');
      list = this.fromDatePipe.transform(list, this.fromDate, 'stockSummaryList');
      list = this.toDatePipe.transform(list, this.toDate, 'stockSummaryList');

      const doc = new jsPDF()

      doc.setFontSize(18)
      doc.text('Damage List', 14, 22)
      doc.autoTable({ html: '#my-table' })

    // Or use javascript directly:
    doc.autoTable({
      head: [
        [
          'Date',
          'Item Name',
          'Brand Name',
          'Modal Number',
          'Color',
          'Size',
          'Damage Quantity'
        ]
      ],
      body: list.reduce((acc, cur) => {
        acc.push([
          cur.date,
          cur.itemName,
          cur.brandName,
          cur.modelNumber,
          cur.color ?  cur.color : '',
          cur.size ? cur.size : 0 ,
          cur.damageQty.toString()
        ])
        return acc
      }, []),
      startY: 30,
      showHead: 'firstPage',
      styles: { overflow: 'ellipsize', cellWidth: 'wrap' },
      columnStyles: { text: { cellWidth: 'auto' } }
    })
      let date = new Date().toDateString()
      doc.save(`damage-report-${date}.pdf`);
    }
  }

  saleDamage() {
    this.sharedService.setSaleDamageStock(true);
    this.router.navigateByUrl('/dashboard/damage-sale-invoice');
  }

  clearAll() {
    this.searchName = '';
    this.fromDate = null;
    this.toDate = null;
  }
}
