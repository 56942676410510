import { Component, OnInit } from '@angular/core';
import { PaymentService, CustomerService } from './../../services';
import * as jsPDF from 'jspdf';
import { DatePipe } from '@angular/common';
import { SearchByIdPipe,FFromDatePipe,FToDatePipe,NumberPipe } from './../../pipe/index';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ledger-report',
  templateUrl: './ledger-report.component.html',
  styleUrls: ['./ledger-report.component.scss']
})
export class LedgerReportComponent implements OnInit {

  public fromDate: Date;
  public toDate: Date;
  public searchId: string = '';
  public ledgerReportList = [];
  public customers = [];
  public totalDebit: number;
  public totalCredit: number;
  public totalBalance: number;
  public totalOutstandingBalance:number;
  public isLoading: Boolean = true;
  public errMsg: String;
  public showDetailDescription:boolean = false;
  public showDetailIndex:number = null;
  public sort: any = {};
  
  constructor(
    private PS: PaymentService,
    private CS: CustomerService,
    public datepipe: DatePipe,
    public SearchByIdPipe:SearchByIdPipe,
    public fromDatePipe:FFromDatePipe,
    public toDatePipe:FToDatePipe,
    public numberPipe:NumberPipe,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.CS.getCustomersFromBack().subscribe(customers => {
      this.customers = customers;
    },err=>{
      this.toastr.error(err.error.message,'Error!');
    });

    this.PS.getPaymentLedgerReport().subscribe(result => {
      this.ledgerReportList = result;
      this.calculateSum(result)
      this.isLoading = false;
    },err=>{
      this.isLoading = false;
      this.errMsg = err.error.message;
      this.toastr.error(err.error.message,'Error!');
    });
  }

  calculateSum(paymentList){
    this.totalDebit = paymentList.reduce((accumolator, cuurent) => { return accumolator + cuurent.debit }, 0);
    this.totalCredit = paymentList.reduce((accumolator, cuurent) => { return accumolator + cuurent.credit }, 0);
    this.totalBalance = this.totalCredit - this.totalDebit;
    this.totalOutstandingBalance = this.totalCredit - this.totalDebit;
  }

  sortData(active: any, direction: any) {
    this.sort = {
      direction,
      active,
    };
  }

  currentSorting(active: any, direction: any)  {
    return this.sort.active === active && this.sort.direction === direction
  }

  filterCustomer(id) {
    this.searchId = id;
    this.totalDebit = this.ledgerReportList.reduce((accumolator, cuurent: any) => { return cuurent.customerId == id ? accumolator + cuurent.debit : accumolator }, 0);
    this.totalCredit = this.ledgerReportList.reduce((accumolator, cuurent) => { return cuurent.customerId == id ? accumolator + cuurent.credit : accumolator }, 0);
    let temp = this.ledgerReportList.filter(obj => { return obj.customerId == id });
    this.totalBalance = this.totalCredit - this.totalDebit;
  }

  changeDate(date, type) {
    if (type == 'from') {
      this.fromDate = new Date(date);
    } else if (type == 'to') {
      this.toDate = new Date(date);
    }
  }

  clearAll() {
    this.searchId = undefined;
    this.fromDate = null;
    this.toDate = null;
  }

  createTransaction() {
    this.router.navigateByUrl('/dashboard/payment-receive')
  }

  fromDateChange(date){
    if (this.ledgerReportList) {
      let list = this.SearchByIdPipe.transform(this.ledgerReportList,this.searchId,'customer');
      list = this.fromDatePipe.transform(list,date,'customer');
      list = this.toDatePipe.transform(list,this.toDate,'customer');
      this.calculateSum(list);
    }
  }

  toDateChange(date){
    if (this.ledgerReportList) {
      let list = this.SearchByIdPipe.transform(this.ledgerReportList,this.searchId,'customer');
      list = this.fromDatePipe.transform(list,this.fromDate,'customer');
      list = this.toDatePipe.transform(list,date,'customer');
      this.calculateSum(list);
    }

  }

  generateSummery(){
    if (this.ledgerReportList) {
      let list = this.fromDatePipe.transform(this.ledgerReportList,this.fromDate,'customer');
      list = this.toDatePipe.transform(list,this.toDate,'customer');
      let finalList = list.reduce((acc,cur)=>{
        if(acc && acc.length && acc.some(obj => obj.companyName == cur.companyName)){
        let index = acc.findIndex(obj => obj.companyName == cur.companyName);
          acc[index].outstandingBalance += (parseInt(cur.credit) - parseInt(cur.debit));
           return acc;
        }else{
        acc.push({"companyName":cur.companyName,"outstandingBalance":(parseInt(cur.balance)-parseInt(cur.debit))});
        return acc;
        }
        },[])
      const doc = new jsPDF()
      doc.setFontSize(18)
      doc.text('Summery Report', 14, 22)
      doc.autoTable({
      head: [
        [
          'Company Name',
          {content:'Outstanding Balance',styles:{halign: 'right'}}
        ]
      ],
      body: finalList.reduce((acc, cur) => {
        acc.push([
          cur.companyName,
          this.numberPipe.transform(cur.outstandingBalance)
        ])
        return acc
      }, []),
      startY: 35,
      showHead: 'firstPage',
      styles: { overflow: 'ellipsize', cellWidth: 'wrap'},
      headStyles: {
        text: { cellWidth: 'auto' },1:{halign:'right'}
    },
          columnStyles: { text: { cellWidth: 'auto' },1:{halign:'right'} }
    })
      let date = new Date().toDateString()
      doc.save(`Summery-report-${date}.pdf`);
    }

  }

  captureScreen() {
    if (this.ledgerReportList) {
      let list = this.SearchByIdPipe.transform(this.ledgerReportList,this.searchId,'customer');
      list = this.fromDatePipe.transform(list,this.fromDate,'customer');
      list = this.toDatePipe.transform(list,this.toDate,'customer');
      const doc = new jsPDF()
      doc.setFontSize(18)
      doc.text('Ledger Report', 14, 22)
      doc.setFontSize(12)
      doc.setTextColor(100)
      doc.text(`Total Debit :  ${this.numberPipe.transform(this.totalDebit)}`, 14, 30)
      doc.text(`Total Credit : ${this.numberPipe.transform(this.totalCredit)}`, 70, 30)
      doc.text(`Total Balance : ${this.numberPipe.transform(this.totalBalance)}`, 140, 30)
      doc.autoTable({
      head: [
        [
          'Date',
          'Company Name',
          'Bank Name',
          'Description',
          'Debit',
          'Credit',
          'Balance'
        ]
      ],
      body: list.reduce((acc, cur) => {
        acc.push([
          this.datepipe.transform(cur.date, 'yyyy-MM-dd'),
          cur.companyName,
          cur.bankName ? cur.bankName : '',
          cur.description ? cur.description : '',
          this.numberPipe.transform(cur.debit),
          this.numberPipe.transform(cur.credit),
          this.numberPipe.transform(cur.balance)
        ])
        return acc
      }, []),
      startY: 35,
      showHead: 'firstPage',
      styles: { overflow: 'ellipsize', cellWidth: 'wrap' },
      columnStyles: {  3: {
        columnWidth: 'auto'
      } }
    })
      let date = new Date().toDateString()
      doc.save(`ledger-report-${date}.pdf`);
    }
  }

}
