import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { EffectsModule } from '@ngrx/effects'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { DatePipe } from '@angular/common'
import { CloudinaryModule } from '@cloudinary/angular-5.x'
import { Cloudinary as CloudinaryCore } from 'cloudinary-core'
import { NgxPaginationModule } from 'ngx-pagination'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HttpClientModule } from '@angular/common/http'
import { GetItems } from './store/interface/item-get.service'
import { Containers } from './container/index'
import { Components } from './components/index'
import { getItemReducer } from './store/reducer/item.reducer'
import { getBrandReducer } from './store/reducer/brand.reducer'
import { getStockReducer } from './store/reducer/stock.reducer'
import { ItemEffect } from './store/effect/item.effect'
import { BrandEffect } from './store/effect/brand.effect'
import { StockEffect } from './store/effect/stock.effect'

import { environment } from '../environments/environment'
import { Services } from './services'
import { InfiniteScrollModule } from 'ngx-infinite-scroll'
import { NgSelectModule } from '@ng-select/ng-select'
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt'
import { Pipes } from './pipe/index';
import {
  ToastNoAnimationModule
} from 'ngx-toastr';
import { SidebarModule } from 'ng-sidebar';

import { DateTimePipe } from './pipe/dateTime/date-time.pipe';
import { SubStringPipe } from './pipe/subString/sub-string.pipe';
import { SaleInvoicePipe } from './pipe/saleInvoice/sale-invoice.pipe';
export const cloudinary = {
  Cloudinary: CloudinaryCore
}

@NgModule({
  declarations: [
    AppComponent,
    Containers,
    Components,
    Pipes,
    DateTimePipe,
    SubStringPipe,
    SaleInvoicePipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxPaginationModule,
    NgbModule,
    ToastNoAnimationModule.forRoot(),
    StoreModule.forRoot({
      getItems: getItemReducer,
      getBrands: getBrandReducer,
      stockReducer: getStockReducer
    }),
    EffectsModule.forRoot([ItemEffect, BrandEffect, StockEffect]),
    StoreDevtoolsModule.instrument({ maxAge: 10 }),
    ReactiveFormsModule,
    HttpClientModule,
    CloudinaryModule.forRoot(cloudinary, {
      cloud_name: environment.cloudinaryName,
      api_key: environment.cloudinaryApiKey,
      api_secret: environment.cloudinaryApiSecret,
      upload_preset: environment.CloudinaryUploadPreset
    }),
    FormsModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    InfiniteScrollModule,
    NgSelectModule,
    SidebarModule.forRoot()
  ],
  entryComponents: [
    Components
  ],
  providers: [
    GetItems,
    ...Services,
    Pipes,
    DatePipe,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService
  ],
  exports:[Pipes],
  bootstrap: [AppComponent]
})
export class AppModule {}
