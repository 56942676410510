import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UsersService } from "./../../services";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import * as jsPDF from "jspdf";
import { SearchPipe } from "./../../pipe/index";
import { ToastrService } from "ngx-toastr";
import { ConfirmModalComponent } from "./../confirm-modal/confirm-modal.component";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit {
  public userForm: FormGroup;
  public isUpdateBank: boolean = false;
  public isLoad: boolean = true;
  public errMsg: string;
  public alreadyExistError: Boolean = false;
  public serverError: Boolean = false;
  public editUserData;
  public isEdit: Boolean = false;
  public searchName: string = "";
  public userList = [];

  private confirmUserDeleteModal: NgbModalRef;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private US: UsersService,
    public datepipe: DatePipe,
    public SearchPipe: SearchPipe,
    private toastr: ToastrService,
    private _confirmDeleteUserModal: NgbModal
  ) {}

  ngOnInit() {
    this.userForm = this.fb.group({
      name: ["", Validators.required],
      userName: ["", Validators.required],
      password: [""],
      role: ["", Validators.required],
    });
    this.US.getUser().subscribe(
      (users) => {
        this.isLoad = false;
        this.userList = users;
      },
      (err) => {
        this.isLoad = false;
        this.toastr.error(err.error.message, "Error!");
      }
    );
  }

  editUser(user) {
    this.isEdit = true;
    this.editUserData = user;
    this.userForm.controls["userName"].disable();
    this.userForm.patchValue({
      name: user.name,
      userName: user.userName,
      role: user.role,
    });
  }

  submit() {
    if (!this.isEdit) {
      let userObj = {
        name: this.userForm.value.name,
        userName: this.userForm.value.userName,
        role: this.userForm.value.role,
        password: this.userForm.value.password,
      };
      this.isLoad = true;
      this.US.addUser(userObj).subscribe(
        (result) => {
          this.alreadyExistError = false;
          this.US.getUser().subscribe((users) => {
            this.isLoad = false;
            this.userList = users;
            this.userForm.reset();
          });
        },
        (err) => {
          this.isLoad = false;
          this.toastr.error(err.error.message, "Error!");
        }
      );
    } else {
      this.editUserData.name = this.userForm.value.name;
      this.editUserData.role = this.userForm.value.role;
      this.US.updateUser(this.editUserData).subscribe((result) => {
        this.userForm.reset();
        this.userForm.controls["userName"].enable();
        this.US.getUser().subscribe(
          (users) => {
            this.isLoad = false;
            this.userList = users;
          },
          (err) => {
            this.isLoad = false;
            this.toastr.error(err.error.message, "Error!");
          }
        );
      });
    }
  }

  deleteUser(user) {
    this.confirmUserDeleteModal = this._confirmDeleteUserModal.open(
      ConfirmModalComponent
    );
    this.confirmUserDeleteModal.componentInstance.title = `Delete ${user.name}`;
    this.confirmUserDeleteModal.componentInstance.description = `Are you sure you want to delete ${user.name}?`;
    this.confirmUserDeleteModal.result.then((result) => {
      if (result) {
        this.US.deleteUser({ userId: user._id }).subscribe(
          (users) => {
            this.userList = users;
          },
          (err) => {
            this.toastr.error(err.error.message, "Error!");
          }
        );
      }
    });
  }

  generatePdf() {
    if (this.userList) {
      let list = this.SearchPipe.transform(
        this.userList,
        this.searchName,
        "name"
      );
      let doc = new jsPDF("l", "pt", "a4");
      doc.setFontStyle("bold");
      doc.setFontSize(24);
      doc.setTextColor(192, 192, 192);
      doc.cell(100, 20, 640, 50, "USER REPORT", 0);
      doc.setFontSize(8);
      doc.setTextColor("#1a5761");
      doc.cell(100, 10, 160, 20, "createdAt", 2);
      doc.cell(100, 10, 160, 20, "Name", 2);
      doc.cell(100, 10, 160, 20, "userName", 2);
      doc.cell(100, 10, 160, 20, "Action", 2);
      doc.setFontStyle("italic");
      for (let i = 3; i < list.length + 3; ++i) {
        doc.cell(
          100,
          10,
          160,
          15,
          this.datepipe.transform(list[i - 3].createdAt, "yyyy-MM-dd"),
          i
        );
        doc.cell(100, 10, 160, 15, list[i - 3].name, i);
        doc.cell(
          100,
          10,
          160,
          15,
          list[i - 3].userName ? list[i - 3].userName : " ",
          i
        );
        doc.cell(100, 10, 160, 15, list[i - 3].role.toString(), i);
      }
      doc.save("user-report.pdf");
    }
  }
}
