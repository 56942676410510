import { Component, OnInit } from '@angular/core'
import { HistoryService, UsersService } from './../../services'
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  public itemlist = []
  public filteredList = []
  public throttle = 2
  public scrollDistance = 1
  public scrollUpDistance = 1
  public start = 0
  public limit = 15
  public users = []
  public userId: String = ''
  public isLoading: Boolean = false
  public finished: Boolean = false
  public fromDate: String = ''
  public toDate: String = ''

  constructor (
    private HS: HistoryService,
    private US: UsersService,
    private toastr: ToastrService
  ) {}

  ngOnInit () {
    this.getUsers()
    this.getFirstHistory()
  }

  hasProp (obj, name) {
    return obj.hasOwnProperty(name)
  }

  fromDateChange (fromDate) {
    this.fromDate = fromDate
    this.start = 0
    this.limit = 15
    this.itemlist = []
    this.getFirstHistory()
  }

  toDateChange (toDate) {
    this.toDate = toDate
    this.start = 0
    this.limit = 15
    this.itemlist = []
    this.getFirstHistory()
  }

  getFirstHistory () {
    this.HS.getHistory(
      this.start,
      this.limit,
      this.userId,
      this.toDate,
      this.fromDate
    ).subscribe(
      data => {
        data.map((obj, i) => {
          return (data[i]['changes'] = obj.changes ? JSON.parse(obj.changes) : {})
        })
        this.itemlist = data
      },
      err => {
        this.toastr.error(err.error.message, 'Error!')
        this.isLoading = false
        this.finished = true
      }
    )
  }

  getUsers () {
    this.US.getUserNameList().subscribe(
      result => {
        this.users = result
      },
      err => {
        this.toastr.error(err.error.message, 'Error!')
      }
    )
  }

  getCustomerHistory (userId) {
    this.userId = userId
    this.start = 0
    this.limit = 15
    this.itemlist = []
    this.getFirstHistory()
  }

  onScrollDown () {
    this.isLoading = true
    this.start += 15
    this.limit += 15
    this.HS.getHistory(
      this.start,
      this.limit,
      this.userId,
      this.toDate,
      this.fromDate
    ).subscribe(
      data => {
        data.map((obj, i) => {
          return (data[i]['changes'] = JSON.parse(obj.changes))
        })
        Array.prototype.push.apply(this.itemlist, data)
      },
      err => {
        this.toastr.error(err.error.message, 'Error!')
        this.isLoading = false
        this.finished = true
      }
    )
  }
}
