import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sorting'
})
export class SortingPipe implements PipeTransform {

    compare(a: any, b: any, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    transform(value: any, direction: string, propName: string): any {
        return value ? 
        value.sort((a: any, b: any) => {
            const isAsc = direction === 'asc';
            switch (propName) {
              case 'date':
                return this.compare(
                  new Date(a.date),
                  new Date(b.date),
                  isAsc
                );
              case 'id':
                return this.compare(a.transactionId, b.transactionId, isAsc);
              default:
                return 0;
            }
          })
        : value;
    }

}
