import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(value: any, searchName: string, propName: string): any {
    return searchName ? value.filter((item) => { return item.name.toLocaleLowerCase().indexOf(searchName.toLocaleLowerCase()) !== -1 }) : value;
  }

}
