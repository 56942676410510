import { Action } from '@ngrx/store'

export const GET_ITEM ='GET_ITEM';
export const GET_ITEM_SUCCESS ='GET_ITEM_SUCCESS';
export const GET_ITEM_ERROR ='GET_ITEM_ERROR';
export const ADD_ITEM ='ADD_ITEM';
export const ADD_ITEM_SUCCESS ='ADD_ITEM_SUCCESS';
export const DELETE_ITEM ='DELETE_ITEM';
export const DELETE_ITEM_SUCCESS ='DELETE_ITEM_SUCCESS';
export const UPDATE_ITEM ='UPDATE_ITEM';
export const UPDATE_ITEM_SUCCESS ='UPDATE_ITEM_SUCCESS';


export class GetItem implements Action {
    readonly type = GET_ITEM;
    constructor(public payload: any){}
}

export class GetItemSuccess implements Action {
    readonly type = GET_ITEM_SUCCESS;
    constructor(public payload: any){}
}

export class GetItemError implements Action {
    readonly type = GET_ITEM_ERROR;
    constructor(public payload: any){}
}

export class AddItem implements Action {
    readonly type = ADD_ITEM;
    constructor(public payload: any){}
}
export class AddItemSuccess implements Action {
    readonly type = ADD_ITEM_SUCCESS;
    constructor(public payload: any){}
}
export class DeleteItem implements Action {
    readonly type = DELETE_ITEM;
    constructor(public payload: any){}
}
export class DeleteItemSuccess implements Action {
    readonly type = DELETE_ITEM_SUCCESS;
    constructor(public payload: any){}
}
export class UpdateItem implements Action {
    readonly type = UPDATE_ITEM;
    constructor(public payload: any){}
}
export class UpdateItemSuccess implements Action {
    readonly type = UPDATE_ITEM_SUCCESS;
    constructor(public payload: any){}
}

export type Actions = GetItem | GetItemSuccess | GetItemError | AddItem | AddItemSuccess | DeleteItem | DeleteItemSuccess | UpdateItem | UpdateItemSuccess;

