import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UsersService } from "./../../services/index";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  private _opened: boolean = false;

  public tabs = [
    {
      name: "Inventory Properties",
      isActive: true,
      children: [
        {
          name: "Item Name",
          allowAttribute: "addAndViewItem",
          isActive: false,
        },
        {
          name: "Brands",
          allowAttribute: "brandListing",
          isActive: false,
        },
      ],
    },
    {
      name: "Stock Management",
      isActive: true,
      children: [
        {
          name: "Add Stock",
          allowAttribute: "addStock",
          isActive: false,
        },
        {
          name: "Stock Report",
          allowAttribute: "viewStock",
          isActive: false,
        },
        {
          name: "Stock Summary",
          allowAttribute: "stockReport",
          isActive: false,
        },
        {
          name: "Stock Details",
          allowAttribute: "stockSummery",
          isActive: false,
        },
        {
          name: "Damage Show",
          allowAttribute: "showDamage",
          isActive: false,
        },
      ],
    },
    {
      name: "Sale Invoice",
      isActive: true,
      children: [
        {
          name: "Add Sale Invoice",
          allowAttribute: "addInvoice",
          isActive: false,
        },
        {
          name: "Sale Report",
          allowAttribute: "viewInvoice",
          isActive: false,
        },
        {
          name: "Return Invoice",
          allowAttribute: "returnInvoice",
          isActive: false,
        },
        {
          name: "Model Color Wise Sale",
          allowAttribute: "colorModelWise",
          isActive: false,
        },
      ],
    },
    {
      name: "Ledger",
      isActive: true,
      children: [
        {
          name: "Payment Receive",
          allowAttribute: "updateAndCreateLedger",
          isActive: false,
        },
        {
          name: "Receive Money Report",
          allowAttribute: "recieveMoneyReport",
          isActive: false,
        },
        {
          name: "Ledger Report",
          allowAttribute: "ledgerReport",
          isActive: false,
        },
      ],
    },
    {
      name: "Banks",
      isActive: true,
      children: [
        {
          name: "Add Bank",
          allowAttribute: "updateAndCreateBank",
          isActive: false,
        },
        {
          name: "Banks",
          allowAttribute: "bankListing",
          isActive: false,
        },
        {
          name: "Bank Ledger",
          allowAttribute: "bankLedger",
          isActive: false,
        },
      ],
    },
    {
      name: "Petty Cash",
      isActive: true,
      children: [
        {
          name: "Add Petty Cash",
          allowAttribute: "updateAndCreatePettyCash",
          isActive: false,
        },
        {
          name: "Petty Cash List",
          allowAttribute: "pettyCashListing",
          isActive: false,
        },
      ],
    },
    {
      name: "Contacts",
      isActive: true,
      children: [
        {
          name: "Add Customer",
          allowAttribute: "updateAndCreateCustomer",
          isActive: false,
        },
        {
          name: "Customer",
          allowAttribute: "customerListing",
          isActive: false,
        },
      ],
    },
    {
      name: "Admin",
      isActive: false,
      children: [
        {
          name: "Create User",
          isActive: true,
        },
        {
          name: "Add User Role",
          isActive: true,
        },
        {
          name: "History",
          isActive: true,
        },
      ],
    },
    {
      name: "Upload PDF",
      isActive: true,
      children: [
        {
          name: "Add pdf",
          allowAttribute: "uploadPdf",
          isActive: false,
        },
        {
          name: "pdf list",
          allowAttribute: "pdfListing",
          isActive: false,
        },
      ],
    },
  ];

  constructor(private router: Router, private US: UsersService) {}

  gotoDashboard() {
    this.router.navigate(["dashboard/home"]);
  }

  goto(routeName) {
    routeName = routeName.toLowerCase().replace(/[/ ]/g, "-");
    this.router.navigate(["dashboard/" + routeName]);
  }

  closeSidebar() {
    this._opened = false;
  }

  ngOnInit() {
    let currentUser = JSON.parse(localStorage.getItem("STU"));
    if (currentUser) {
      this.tabs.forEach((tab: any, i) => {
        tab.children.map((child, j) => {
          if (currentUser.hasOwnProperty(child.allowAttribute)) {
            this.tabs[i].children[j]["isActive"] =
              currentUser[child.allowAttribute];
          }
        });
        if (tab.children.every((obj) => obj.isActive == false)) {
          tab.isActive = false;
        }
        if (currentUser.role == "admin" && tab.name == "Admin") {
          tab.isActive = true;
        }
      });
    }
  }

  goToHome() {
    this.router.navigateByUrl("/dashboard/home");
  }

  logout() {
    localStorage.clear();
    this.router.navigateByUrl("/login");
  }

  _toggleSidebar() {
    this._opened = !this._opened;
  }
}
