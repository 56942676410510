import { Injectable } from '@angular/core'
import { Actions, ofType, Effect } from '@ngrx/effects';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators'
import * as ItemAction from '../action/item.action'


const httpOptions = {
  headers: new HttpHeaders({ 'content-type': 'application/json' }),

};
@Injectable()
export class ItemEffect {
  constructor(
    private action$: Actions,
    private http: HttpClient,
  ) { }

  @Effect()
  deleteItems$: Observable<any> = this.action$.pipe(
    ofType(ItemAction.DELETE_ITEM),
    map((action: ItemAction.DeleteItem) => action.payload),
    switchMap((payload : any ) =>
      this.http.delete('http://localhost:3005/api/item/'+ payload ).pipe(map((response: any) => {

        return new ItemAction.DeleteItemSuccess(response);
      }),
        catchError((error) => {
          return of(new ItemAction.DeleteItemSuccess({ error: error }));
        })
      )
    )
  );

  @Effect()
  updateItems$: Observable<any> = this.action$.pipe(
    ofType(ItemAction.UPDATE_ITEM),
    map((action: ItemAction.UpdateItem) => action.payload),
    switchMap((payload : any ) =>
      this.http.put('https://sasko.herokuapp.com/api/items/', payload, httpOptions ).pipe(map((response: any) => {
        return new ItemAction.UpdateItemSuccess(response);
      }),
        catchError((error) => {
          return of(new ItemAction.UpdateItemSuccess({ error: error }));
        })
      )
    )
  );

}
