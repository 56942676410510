import { Component, OnInit } from '@angular/core';
import { Routes,Router,RouterModule} from '@angular/router'

@Component({
  selector: 'app-models',
  templateUrl: './models.component.html',
  styleUrls: ['./models.component.scss']
})
export class ModelsComponent implements OnInit {
  modelDataList = [
    {
      brandName: 'Samsung',
      modelNo: 'SM123',
      date: '12/7/2019',
    },
    {
      brandName: 'Samsung',
      modelNo: 'SM123',
      date: '12/7/2019',
    },
    {
      brandName: 'Samsung',
      modelNo: 'SM123',
      date: '12/7/2019',
    },
    {
      brandName: 'Samsung',
      modelNo: 'SM123',
      date: '12/7/2019',
    },
    {
      brandName: 'Samsung',
      modelNo: 'SM123',
      date: '12/7/2019',
    },
  ];
  constructor(private router: Router) { }

  ngOnInit() {
  }

  createModel(){
    this.router.navigate(['dashboard/addmodel']);
  }

}
