import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fCustomer'
})
export class FCustomerPipe implements PipeTransform {

  transform(value: any, clientName: string, propName: string): any {
    return clientName ? value.filter((client) => {
      return client.companyName.toLocaleLowerCase().indexOf(clientName.toLocaleLowerCase()) !== -1 }) : value;
  }

}
