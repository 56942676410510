import { Component, OnInit, OnDestroy } from '@angular/core';
import { BrandList } from '../../store/interface/brand';
import { Store } from '@ngrx/store';
import { State } from '../../store/reducer/brand.reducer'
import { Subscription } from 'rxjs';
import { BrandsService, ItemService } from './../../services'

@Component({
  selector: 'app-brands-container',
  templateUrl: './brands-container.component.html',
  styleUrls: ['./brands-container.component.scss']
})
export class BrandsContainerComponent implements OnInit, OnDestroy {

  public itemList: any;
  public brandlist: any;
  public brandList: BrandList[];
  brandClassificationSubscription: Subscription;
  itemClassificationSubscription: Subscription;

  constructor(private store: Store<State>, private BS: BrandsService, private IT: ItemService) {
    this.itemList = this.IT.getItems();
    if(!this.itemList){
      this.itemClassificationSubscription = this.IT.getItemsFromBack().subscribe(items =>{
        this.itemList = items;
      })
    }
  }

  getDeleteBrand(id: any): any {
    this.BS.deleteBrand(id).subscribe(result =>{
      this.BS.getBrandsFromBack().subscribe(brands =>{
        return this.brandlist = brands;
      })
    })
  }

  ngOnInit() {
    if(!this.BS.getBrandsWithItems()){
      this.BS.getBrandsWithItemsFromBack().subscribe(result=>{
        this.brandList = result;
      })
    }else{
      this.brandList = this.BS.getBrandsWithItems();
    }
  }

  ngOnDestroy() {}

}
