import { Component, OnInit } from "@angular/core";
import {
  ReturnInvoiceService,
  SharedService,
  CustomerService,
} from "./../../services";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-add-return-invoice",
  templateUrl: "./add-return-invoice.component.html",
  styleUrls: ["./add-return-invoice.component.scss"],
})
export class AddReturnInvoiceComponent implements OnInit {
  public invoice: any;
  public invoiceDetailId: any;
  public returnInvoiceNo: number;
  public invoiceSelectorForm: FormGroup;
  public addStockForm: FormGroup;
  public returnInvoiceForm: FormGroup;
  public isBtnDisable: boolean = false;
  public customerList: any;
  public isLoading: boolean = false;
  public disabledForm = false;

  constructor(
    private fb: FormBuilder,
    public RIS: ReturnInvoiceService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private toastr: ToastrService,
    private HS: SharedService,
    private CS: CustomerService
  ) {}

  ngOnInit() {
    this.getCustomers();
    this.initializeForm();
  }

  initializeForm() {
    this.invoiceDetailId = this.activatedRoute.snapshot.params.invoiceDetailId;
    this.RIS.getReturnInvoiceNo().subscribe(
      (result) => {
        this.returnInvoiceNo = result.invoiceId;
        this.RIS.getReturnInvoiceDetails(this.invoiceDetailId).subscribe(
          (result) => {
            this.invoice = result;

            this.invoiceSelectorForm = this.fb.group({
              invoiceNo: [this.invoice.invoice.invoiceNo, Validators.required],
              manualBookNo: [
                this.invoice.invoice.manualBookNo,
                Validators.required,
              ],
              returnInvNo: [this.returnInvoiceNo, Validators.required],
              customer: [this.invoice.invoice.companyName, Validators.required],
              date: [
                this.datepipe.transform(
                  this.invoice.invoice.date,
                  "yyyy-MM-dd"
                ),
                Validators.required,
              ],
            });
            this.returnInvoiceForm = this.fb.group({
              itemName: [
                this.invoice.invoiceDetail.itemName,
                Validators.required,
              ],
              brandName: [
                this.invoice.invoiceDetail.brandName,
                Validators.required,
              ],
              modelNumber: [
                this.invoice.invoiceDetail.modelNumber,
                Validators.required,
              ],
              color: [this.invoice.invoiceDetail.color, Validators.required],
              pieceQty: [
                this.invoice.invoiceDetail.pieceQty,
                Validators.required,
              ],
              returnedQty: [
                this.invoice.invoiceDetail.returnQty,
                Validators.required,
              ],
              returnQty: [0, Validators.required],
              afterDiscount: [
                this.invoice.invoiceDetail.afterDiscount,
                Validators.required,
              ],
              discount: [
                this.invoice.invoiceDetail.discount,
                Validators.required,
              ],
              rate: [this.invoice.invoiceDetail.rate, Validators.required],
              damageReturn: [0, Validators.required],
              totalCost: [
                this.invoice.invoiceDetail.totalCost,
                Validators.required,
              ],
            });
            if (
              this.invoice.invoiceDetail.pieceQty ==
              this.invoice.invoiceDetail.returnQty
            ) {
              this.disabledForm = true;
              this.isBtnDisable = true;
              this.toastr.error("This invoice has been returned.", "Error!");
            }
          },
          (err) => {
            this.toastr.error(err.error.message, "Error!");
            this.router.navigate(["/dashboard/home"]);
          }
        );
      },
      (err) => {
        this.toastr.error(err.error.message, "Error!");
      }
    );
  }

  submit() {
    this.isBtnDisable = true;
    this.isLoading = true;
    let currentQty =
      this.invoice.invoiceDetail.pieceQty -
      this.invoice.invoiceDetail.returnQty;
    if (
      this.returnInvoiceForm.value.returnQty +
        this.returnInvoiceForm.value.damageReturn ==
      0
    ) {
      this.toastr.info("Enter Return Quantity", "Info!");
      this.isBtnDisable = false;
      this.isLoading = false;
      return;
    } else if (
      currentQty <
      this.returnInvoiceForm.value.returnQty +
        this.returnInvoiceForm.value.damageReturn
    ) {
      this.toastr.info(
        "Return Quantity is greater then your selling Quantity",
        "Info!"
      );
      this.isBtnDisable = false;
      this.isLoading = false;
      return;
    }
    let obj = {
      invoiceId: this.invoice.invoice._id,
      invoiceDetailId: this.invoiceDetailId,
      returnQty: this.returnInvoiceForm.value.returnQty,
      damageQty: this.returnInvoiceForm.value.damageReturn,
      totalReturnQty:
        this.returnInvoiceForm.value.returnQty +
        this.returnInvoiceForm.value.damageReturn,
      returnAmmount:
        (this.returnInvoiceForm.value.returnQty +
          this.returnInvoiceForm.value.damageReturn) *
          this.invoice.invoiceDetail.rate -
        (this.returnInvoiceForm.value.returnQty +
          this.returnInvoiceForm.value.damageReturn) *
          this.invoice.invoiceDetail.rate *
          (this.invoice.invoiceDetail.discount / 100),
      returnInvoiceNo: this.invoiceSelectorForm.value.returnInvNo,
      date: this.invoiceSelectorForm.value.date,
      itemId: this.invoice.invoiceDetail.itemId,
      brandId: this.invoice.invoiceDetail.brandId,
      discount: this.invoice.invoiceDetail.discount,
      modelNumber: this.returnInvoiceForm.value.modelNumber,
      color: this.returnInvoiceForm.value.color,
      rate: this.invoice.invoiceDetail.rate,
      customerId: this.invoice.invoice.customerId,
    };
    obj["history"] = this.getHistory(Object.assign({}, obj));
    this.RIS.addReturnInvoice(obj).subscribe(
      (result) => {
        this.isBtnDisable = false;
        this.isLoading = false;
        this.router.navigateByUrl("/dashboard/return-invoice");
      },
      (err) => {
        this.isBtnDisable = false;
        this.isLoading = false;
        this.toastr.error(err.error.message, "Error!");
      }
    );
  }

  getHistory(newObject) {
    delete newObject["itemId"];
    delete newObject["invoiceId"];
    delete newObject["brandId"];
    delete newObject["invoiceDetailId"];
    newObject["itemName"] = this.invoice.invoiceDetail.itemName;
    newObject["brandName"] = this.invoice.invoiceDetail.brandName;
    return this.HS.getHistory(newObject, "add", null, this.customerList, null);
  }

  getCustomers() {
    if (!this.CS.getCustomers()) {
      this.CS.getCustomersFromBack().subscribe(
        (result) => {
          this.customerList = result;
        },
        (err) => {
          this.toastr.error(err.error.message, "Error!");
        }
      );
    } else {
      this.customerList = this.CS.getCustomers();
    }
  }
}
