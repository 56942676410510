import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fPettyCash'
})
export class FPettyCashPipe implements PipeTransform {

  transform(value: any, pettyCashName: string, propName: string): any {
    return pettyCashName ? value.filter((item) => { return item.pettyCashName.toLocaleLowerCase().indexOf(pettyCashName.toLocaleLowerCase()) !== -1 }) : value;
  }

}
