import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'

@Injectable()
export class GetItems{

    private url: string = "https://sasko.herokuapp.com/api/items"

    constructor(private http:  HttpClient){}
}
