import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  AuthGuradService as AuthGuard
} from './services/auth-guard/auth-gurad.service';
import * as containerObj from './container/index';
import * as componentObj from './components/index'

const routes: Routes = [

  { path: '', redirectTo: 'dashboard/home', pathMatch: 'full' },
  { path: 'login', component: componentObj.SignInComponent },
  {
    path: 'dashboard',
    component: componentObj.DashboardComponent,
    canActivate: [AuthGuard] ,
    children: [
      { path: 'create-user', component: componentObj.UsersComponent },
      { path: 'user-role', component: componentObj.UserRoleComponent },
      { path: 'add-user-role', component: componentObj.AddUserRoleComponent },
      { path: 'item-name', component: containerObj.ItemsContainerComponent },
      { path: 'additem', component: containerObj.AdditemContainerComponent },
      { path: 'brands', component: containerObj.BrandsContainerComponent },
      { path: 'add-brand', component: componentObj.AddBrandComponent },
      { path: 'stock-report', component: componentObj.StockComponent },
      { path: 'add-stock', component: containerObj.AddStockContainerComponent },
      { path: 'edit-stock/:stockId', component: containerObj.AddStockContainerComponent },
      { path: 'stock-summary', component: componentObj.StockReport2Component },
      { path: 'model-color-wise-sale', component: componentObj.ModelColorComponent },
      { path: 'damage-show', component: componentObj.ShowDamageComponent },
      { path: 'stock-details', component: componentObj.StockSummaryComponent },
      { path: 'petty-cash-list', component: componentObj.PettyCashComponent },
      { path: 'add-petty-cash', component: componentObj.AddPettyComponent },
      { path: 'update-petty/:pettyCashId', component: componentObj.AddPettyComponent },
      { path: 'add-bank', component: componentObj.AddBankComponent },
      { path: 'update-bank/:bankId', component: componentObj.AddBankComponent },
      { path: 'banks', component: componentObj.BankComponent },
      { path: 'payment-receive', component: componentObj.RecieveMoneyComponent },
      { path: 'update-payment/:paymentId', component: componentObj.RecieveMoneyComponent },
      { path: 'add-sale-invoice', component: componentObj.SaleInvoiceComponent },
      { path: 'edit-sale-invoice/:invoiceId', component: componentObj.SaleInvoiceComponent },
      { path: 'damage-sale-invoice', component: componentObj.SaleInvoiceComponent },
      { path: 'add-customer', component: componentObj.AddCustomerComponent },
      { path: 'edit-customer/:customerId', component: componentObj.AddCustomerComponent },
      { path: 'add-pdf', component: componentObj.UploadPdfComponent },
      { path: 'bank-ledger', component: componentObj.BankLedgerComponent },
      { path: 'add-return-invoice/:invoiceDetailId', component: componentObj.AddReturnInvoiceComponent },
      { path: 'receive-money-report', component: componentObj.RecieveMoneyReportComponent },
      { path: 'ledger-report', component: componentObj.LedgerReportComponent },
      { path: 'pdf-list', component: componentObj.PdfListingComponent },
      { path: 'customer', component: componentObj.CustomerComponent },
      { path: 'return-invoice', component: componentObj.ReturnInvoiceComponent },
      { path: 'sale-report', component: componentObj.SaleInvoiceListingComponent },
      { path: 'history', component: componentObj.HistoryComponent },
      { path: 'home', component: componentObj.DashboardHomeComponent }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
