import { Component, OnInit } from '@angular/core';
import { UsersService } from './../../services';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-user-role',
  templateUrl: './add-user-role.component.html',
  styleUrls: ['./add-user-role.component.scss']
})
export class AddUserRoleComponent implements OnInit {

  public userList = [];
  public allScreensAccess: Boolean = false;
  public inventoryManagement: Boolean = false;
  public stockManagement: Boolean = false;
  public invoiceManagement: Boolean = false;
  public LedgerManagement: Boolean = false;
  public bankManagement: Boolean = false;
  public pettyCashManagement: Boolean = false;
  public pdfManagement: Boolean = false;
  public customerManagement: Boolean = false;
  public roles;
  public isLoading: boolean = false;

  constructor
  (private US: UsersService,
    private toastr: ToastrService,
     private router: Router) { }

  ngOnInit() {
    this.US.getUserNameList().subscribe(list => {
      this.userList = list;
    },err=>{
      this.toastr.error(err.error.message,'Error!');
    });  }

  onUserChange(val) {
    this.US.getUserRoles(val).subscribe(userRoles => {
      this.roles = userRoles;
      this.setInventoryManagemet(userRoles)
      this.setStockManagement(userRoles);
      this.setInvoiceManagement(userRoles);
      this.setBankManagement(userRoles);
      this.setCustomerManagement(userRoles);
      this.setPdfManagement(userRoles);
      this.setLedgerManagement(userRoles);
      this.setPettyCashManagement(userRoles);
      this.checkAllAccess(userRoles);
    },err=>{
      this.toastr.error(err.error.message,'Error!');
    });
    }

  checkAllAccess(userRoles) {
    if (
      userRoles.addAndViewItem &&
      userRoles.addAndUpdateBrand &&
      userRoles.brandListing &&
      userRoles.addStock &&
      userRoles.viewStock &&
      userRoles.stockSummery &&
      userRoles.stockReport &&
      userRoles.showDamage &&
      userRoles.addInvoice &&
      userRoles.viewInvoice &&
      userRoles.invoiceReport &&
      userRoles.returnInvoice &&
      userRoles.colorModelWise &&
      userRoles.updateAndCreateLedger &&
      userRoles.recieveMoneyReport &&
      userRoles.ledgerReport &&
      userRoles.updateAndCreateBank &&
      userRoles.bankListing &&
      userRoles.bankLedger &&
      userRoles.updateAndCreatePettyCash &&
      userRoles.pettyCashListing &&
      userRoles.updateAndCreateCustomer &&
      userRoles.customerListing &&
      userRoles.uploadPdf &&
      userRoles.pdfListing
    ) {
      this.allScreensAccess = true;
    }
  }

  updateRoles() {
    this.isLoading = true;
    this.US.updateUserRoles(this.roles).subscribe(result => {
      this.isLoading = false;
      this.router.navigateByUrl('/dashboard/create-user');
    },err=>{
      this.toastr.error(err.error.message,'Error!');
    });  }

  setInventoryManagemet(userRoles) {
    if (!userRoles.addAndViewItem || !userRoles.addAndUpdateBrand || !userRoles.brandListing) {
      this.inventoryManagement = false;
      this.allScreensAccess = false;
    }
    if (userRoles.addAndViewItem && userRoles.addAndUpdateBrand && userRoles.brandListing) {
      this.inventoryManagement = true;
      this.checkAllAccess(userRoles);
    }
  }

  setStockManagement(userRoles) {
    if (!userRoles.addStock || !userRoles.viewStock || !userRoles.stockSummery || !userRoles.stockReport || !userRoles.showDamage) {
      this.stockManagement = false;
      this.allScreensAccess = false;
    }
    if (userRoles.addStock && userRoles.viewStock && userRoles.stockSummery && userRoles.stockReport && userRoles.showDamage) {
      this.stockManagement = true;
      this.checkAllAccess(userRoles);
    }
  }

  setInvoiceManagement(userRoles) {
    if (!userRoles.addInvoice || !userRoles.viewInvoice || !userRoles.invoiceReport || !userRoles.returnInvoice || !userRoles.colorModelWise) {
      this.invoiceManagement = false;
      this.allScreensAccess = false;
    }
    if (userRoles.addInvoice && userRoles.viewInvoice && userRoles.invoiceReport && userRoles.returnInvoice && userRoles.colorModelWise) {
      this.invoiceManagement = true;
      this.checkAllAccess(userRoles);
    }
  }

  setLedgerManagement(userRoles) {
    if (!userRoles.updateAndCreateLedger || !userRoles.recieveMoneyReport || !userRoles.ledgerReport) {
      this.LedgerManagement = false;
      this.allScreensAccess = false;

    }
    if (userRoles.updateAndCreateLedger && userRoles.recieveMoneyReport && userRoles.ledgerReport) {
      this.LedgerManagement = true;
      this.checkAllAccess(userRoles);
    }
  }

  setBankManagement(userRoles) {
    if (!userRoles.updateAndCreateBank || !userRoles.bankListing || !userRoles.bankLedger) {
      this.bankManagement = false;
      this.allScreensAccess = false;
    }
    if (userRoles.updateAndCreateBank && userRoles.bankListing && userRoles.bankLedger) {
      this.bankManagement = true;
      this.checkAllAccess(userRoles);
    }
  }

  setPettyCashManagement(userRoles) {
    if (!userRoles.updateAndCreatePettyCash || !userRoles.pettyCashListing) {
      this.pettyCashManagement = false;
      this.allScreensAccess = false;
    }
    if (userRoles.updateAndCreatePettyCash && userRoles.pettyCashListing) {
      this.pettyCashManagement = true;
      this.checkAllAccess(userRoles);
    }
  }

  setCustomerManagement(userRoles) {
    if (!userRoles.updateAndCreateCustomer || !userRoles.customerListing) {
      this.customerManagement = false;
      this.allScreensAccess = false;
    }
    if (userRoles.updateAndCreateCustomer && userRoles.customerListing) {
      this.customerManagement = true;
      this.checkAllAccess(userRoles);
    }
  }

  setPdfManagement(userRoles) {
    if (!userRoles.uploadPdf || !userRoles.pdfListing) {
      this.pdfManagement = false;
    }
    if (userRoles.uploadPdf && userRoles.pdfListing) {
      this.pdfManagement = true;
      this.checkAllAccess(userRoles);
    }
  }

  setChangeAccess(checked, value) {
    this.roles[value] = checked;
    this.setInventoryManagemet(this.roles);
    this.setStockManagement(this.roles);
    this.setInvoiceManagement(this.roles);
    this.setBankManagement(this.roles);
    this.setCustomerManagement(this.roles);
    this.setPdfManagement(this.roles);
    this.setLedgerManagement(this.roles);
    this.setPettyCashManagement(this.roles);
  }

  allAccess(val) {
    if (this.roles) {
      this.selectAllInventoryManagement(val);
      this.selectAllBankManagement(val);
      this.selectAllCustomerManagement(val);
      this.selectAllInvoiceManagement(val);
      this.selectAllLedgerManagement(val);
      this.selectAllPdfManagement(val);
      this.selectAllPettyCashManagement(val);
      this.selectAllStockManagement(val);
      this.setStockManagement(this.roles);
      this.setInvoiceManagement(this.roles);
      this.setBankManagement(this.roles);
      this.setCustomerManagement(this.roles);
      this.setPdfManagement(this.roles);
      this.setLedgerManagement(this.roles);
      this.setPettyCashManagement(this.roles);
    }
  }

  selectAllInventoryManagement(val) {
    if (this.roles) {
      this.roles.addAndViewItem = val;
      this.roles.addAndUpdateBrand = val;
      this.roles.brandListing = val;
    }
  }

  selectAllStockManagement(val) {
    if (this.roles) {
      this.roles.addStock = val;
      this.roles.viewStock = val;
      this.roles.stockSummery = val;
      this.roles.stockReport = val;
      this.roles.showDamage = val;
    }
  }

  selectAllInvoiceManagement(val) {
    if (this.roles) {
      this.roles.addInvoice = val;
      this.roles.viewInvoice = val;
      this.roles.invoiceReport = val;
      this.roles.returnInvoice = val
      this.roles.colorModelWise = val;
    }
  }

  selectAllLedgerManagement(val) {
    if (this.roles) {
      this.roles.updateAndCreateLedger = val;
      this.roles.recieveMoneyReport = val;
      this.roles.ledgerReport = val;
    }
  }

  selectAllBankManagement(val) {
    if (this.roles) {
      this.roles.updateAndCreateBank = val;
      this.roles.bankListing = val;
      this.roles.bankLedger = val;
    }

  }

  selectAllPettyCashManagement(val) {
    if (this.roles) {
      this.roles.updateAndCreatePettyCash = val;
      this.roles.pettyCashListing = val;
    }
  }

  selectAllPdfManagement(val) {
    if (this.roles) {
      this.roles.uploadPdf = val;
      this.roles.pdfListing = val;
    }
  }

  selectAllCustomerManagement(val) {
    if (this.roles) {
      this.roles.updateAndCreateCustomer = val;
      this.roles.customerListing = val;
    }
  }

}
