import { Injectable } from "@angular/core";
import { HttpService } from "./../http/http.service";
import { environment } from "./../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class StockService {
  stocks: any;
  constructor(private http: HttpService) {}

  public addStock(obj) {
    return this.http.post(
      `${environment.baseUrl}/api/stockDetails/?token=${localStorage.getItem(
        "STUID"
      )}`,
      obj
    );
  }

  public getStock() {
    return this.stocks;
  }

  public getStockId() {
    return this.http.get(
      `${environment.baseUrl}/api/stock/getStockId?token=${localStorage.getItem(
        "STUID"
      )}`
    );
  }

  public getStockWithDetails(id) {
    return this.http.get(
      `${
        environment.baseUrl
      }/api/stock/getStockWithStockDetails/${id}?token=${localStorage.getItem(
        "STUID"
      )}`
    );
  }

  public getStocksFromBack() {
    return this.http.get(
      `${environment.baseUrl}/api/stockDetails/?token=${localStorage.getItem(
        "STUID"
      )}`
    );
  }

  public getStockSecondReport() {
    return this.http.get(
      `${
        environment.baseUrl
      }/api/stockDetails/stockSecondReport?token=${localStorage.getItem(
        "STUID"
      )}`
    );
  }

  public getStockSummaryReport() {
    return this.http.get(
      `${
        environment.baseUrl
      }/api/stockDetails/getStockSummary?token=${localStorage.getItem("STUID")}`
    );
  }

  public getDamageStock() {
    return this.http.get(
      `${
        environment.baseUrl
      }/api/stockDetails/getDamageStock?token=${localStorage.getItem("STUID")}`
    );
  }

  public updateEditStock(obj) {
    return this.http.put(
      `${environment.baseUrl}/api/stockDetails/?token=${localStorage.getItem(
        "STUID"
      )}`,
      obj
    );
  }

  public getItemsInStockDetails() {
    return this.http.get(
      `${
        environment.baseUrl
      }/api/stockDetails/getItemsInStockDetails?token=${localStorage.getItem(
        "STUID"
      )}`
    );
  }

  public getBrandsOfItemsInStockDetails(id) {
    return this.http.get(
      `${
        environment.baseUrl
      }/api/stockDetails/getBrandsOfItemsInStockDetails/${id}?token=${localStorage.getItem(
        "STUID"
      )}`
    );
  }

  public getModelsOfItemsAndBrands(obj) {
    return this.http.post(
      `${
        environment.baseUrl
      }/api/stock/getModelsOfItemsAndBrands?token=${localStorage.getItem(
        "STUID"
      )}`,
      obj
    );
  }

  public getColorsOfModelsItemsAndBrands(obj) {
    return this.http.post(
      `${
        environment.baseUrl
      }/api/stock/getColorsOfModelsItemsAndBrands?token=${localStorage.getItem(
        "STUID"
      )}`,
      obj
    );
  }

  public getItemFromStockDetails() {
    return this.http.get(
      `${
        environment.baseUrl
      }/api/stockDetails/getItemsInStockDetails?token=${localStorage.getItem(
        "STUID"
      )}`
    );
  }

  public getBrandsFromStockDetails(itemID) {
    return this.http.get(
      `${
        environment.baseUrl
      }/api/stockDetails/getBrandsOfItemsInStockDetails/${itemID}?token=${localStorage.getItem(
        "STUID"
      )}`
    );
  }

  public getModelsFromStockDetails(obj): any {
    return this.http.post(
      `${
        environment.baseUrl
      }/api/stockDetails/getModelsOfItemsAndBrands?token=${localStorage.getItem(
        "STUID"
      )}`,
      obj
    );
  }

  public getColorsFromStockDetails(obj): any {
    return this.http.post(
      `${
        environment.baseUrl
      }/api/stockDetails/getColorsOfModelsItemsAndBrands?token=${localStorage.getItem(
        "STUID"
      )}`,
      obj
    );
  }

  public getStockFromStockDetails(obj): any {
    return this.http.post(
      `${
        environment.baseUrl
      }/api/stockDetails/getStockOfColorModelItemAndBrand?token=${localStorage.getItem(
        "STUID"
      )}`,
      obj
    );
  }

  public getDamageFromStockDetails(obj): any {
    return this.http.post(
      `${
        environment.baseUrl
      }/api/stockDetails/getDamageOfColorModelItemAndBrand?token=${localStorage.getItem(
        "STUID"
      )}`,
      obj
    );
  }
}
