import { Component, OnInit } from '@angular/core';
import { Routes,Router,RouterModule} from '@angular/router';

@Component({
  selector: 'app-colors',
  templateUrl: './colors.component.html',
  styleUrls: ['./colors.component.scss']
})
export class ColorsComponent implements OnInit {
  constructor(private router: Router) { }

  ngOnInit() {
  }

  createColor(){
    this.router.navigate(['dashboard/addcolor']);
  }
}
