import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import {
  SaleInvoiceService,
  CustomerService,
  BrandsService,
  ItemService,
  ReturnInvoiceService,
  ExcelService
} from "./../../services";
import { SaleInvoiceModalComponent } from "../sale-invoice-modal/sale-invoice-modal.component";
import { ConfirmModalComponent } from "./../confirm-modal/confirm-modal.component";
import * as jsPDF from "jspdf";
import { DatePipe } from "@angular/common";
import {
  FFromDatePipe,
  FToDatePipe,
  FInvoicePipe,
  FBrandNamePipe,
  FModelNoPipe,
  FColorPipe,
  FItemNamePipe,
  FCustomerPipe,
  PostedPipe,
  NumberPipe,
} from "./../../pipe/index";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-sale-invoice-listing",
  templateUrl: "./sale-invoice-listing.component.html",
  styleUrls: ["./sale-invoice-listing.component.scss"],
})
export class SaleInvoiceListingComponent implements OnInit {
  private saleInvoiceRef: NgbModalRef;
  private confirmReturnInvoiceModal: NgbModalRef;
  public saleInvoiceList = [];
  public errMsg: string;
  public customers: any;
  public brands: any;
  public itemList: any;
  public isAddInvoiceAccess: boolean = false;
  public summeryDetails: any;
  public filterObj: object = {};
  public invoiceNo;
  public customerName;
  public itemName;
  public fromDate;
  public modelNo;
  public manualBookNo;
  public brandName;
  public color;
  public toDate;
  public isPosted: boolean = false;
  public sort: any = {};

  constructor(
    private router: Router,
    public SIS: SaleInvoiceService,
    public CS: CustomerService,
    public BS: BrandsService,
    public IT: ItemService,
    public RTS: ReturnInvoiceService,
    private _saleInvoiceModal: NgbModal,
    private _confirmReturnInvoiceModal: NgbModal,
    public datepipe: DatePipe,
    public fromDatePipe: FFromDatePipe,
    public toDatePipe: FToDatePipe,
    public invoicePipe: FInvoicePipe,
    public brandPipe: FBrandNamePipe,
    public modelPipe: FModelNoPipe,
    public colorPipe: FColorPipe,
    public itemPipe: FItemNamePipe,
    public customerPipe: FCustomerPipe,
    public postedPipe: PostedPipe,
    public numberPipe: NumberPipe,
    private toastr: ToastrService,
    private ES: ExcelService,
  ) {}

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem("STU"));
    this.isAddInvoiceAccess = user.addInvoice;
    this.getInvoiceDetails();
    this.getInvoiceSummeries();
    this.getItems();
    this.getCustomers();
    this.getBrands();
  }

  getItems() {
    this.IT.getItemsFromBack().subscribe(
      (res) => {
        this.itemList = res;
      },
      (err) => {
        this.toastr.error(err.error.message, "Error!");
      }
    );
  }

  getBrands() {
    this.BS.getBrandsFromBack().subscribe(
      (res) => {
        this.brands = res;
      },
      (err) => {
        this.toastr.error(err.error.message, "Error!");
      }
    );
  }

  sortData(active: any, direction: any) {
    this.sort = {
      direction,
      active,
    };
  }

  currentSorting(active: any, direction: any)  {
    return this.sort.active === active && this.sort.direction === direction
  }

  getCustomers() {
    this.CS.getCustomersFromBack().subscribe(
      (res) => {
        this.customers = res;
      },
      (err) => {
        this.toastr.error(err.error.message, "Error!");
      }
    );
  }

  getInvoiceSummeries() {
    this.SIS.getSummeryDetails({}).subscribe(
      (data) => {
        this.summeryDetails = data;
      },
      (err) => {
        this.toastr.error(err.error.message, "Error!");
      }
    );
  }

  getInvoiceDetails() {
    this.SIS.getInvoiceDetails().subscribe(
      (result) => {
        this.saleInvoiceList = result;
        this.saleInvoiceList.sort(
          (d1, d2) =>
            new Date(d1.date.slice(0, 10)).getTime() -
            new Date(d2.date.slice(0, 10)).getTime()
        );
      },
      (err) => {
        this.errMsg = err.error.message;
        this.toastr.error(err.error.message, "Error!");
      }
    );
  }

  getSummery(val, type) {
    switch (type) {
      case "invoiceNo":
        this.filterObj["invoiceNo"] = val;
        break;
      case "customer":
        this.filterObj["customerId"] = val._id;
        break;
      case "item":
        this.filterObj["itemId"] = val._id;
        break;
      case "brand":
        this.filterObj["brandId"] = val._id;
        break;
      case "color":
        this.filterObj["color"] = val;
        break;
      case "fromDate":
        this.filterObj["fromDate"] = val;
        break;
      case "toDate":
        this.filterObj["toDate"] = val;
        break;
      case "modelNo":
        this.filterObj["modelNumber"] = val;
        break;
      case "manualBookNo":
        this.filterObj["manualBookNo"] = val;
        break;
      default:
        break;
    }
    this.SIS.getSummeryDetails(this.filterObj).subscribe(
      (data) => {
        this.summeryDetails = data;
      },
      (err) => {
        this.toastr.error(err.error.message, "Error!");
      }
    );
  }

  showAllSale(e) {
    if (e) {
      this.invoiceNo = "";
      this.customerName = "";
      this.itemName = "";
      this.fromDate = "";
      this.modelNo = "";
      this.manualBookNo = "";
      this.brandName = "";
      this.color = "";
      this.toDate = "";
      this.filterObj = {};
      this.SIS.getSummeryDetails(this.filterObj).subscribe(
        (data) => {
          this.summeryDetails = data;
        },
        (err) => {
          this.toastr.error(err.error.message, "Error!");
        }
      );
    }
  }

  createSale() {
    this.router.navigateByUrl("/dashboard/add-sale-invoice");
  }

  editInvoice(id) {
    this.router.navigateByUrl(`/dashboard/edit-sale-invoice/${id}`);
  }

  invoiceDetailView(id) {
    this.saleInvoiceRef = this._saleInvoiceModal.open(
      SaleInvoiceModalComponent,
      { size: "lg" }
    );
    this.saleInvoiceRef.componentInstance.invoiceId = id;
    this.saleInvoiceRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  showPosted(e) {
    this.isPosted = e;
  }

  returnFullInvoice(invoiceId) {
    this.confirmReturnInvoiceModal = this._confirmReturnInvoiceModal.open(
      ConfirmModalComponent
    );
    this.confirmReturnInvoiceModal.componentInstance.title =
      "Return Whole Invoice";
    this.confirmReturnInvoiceModal.componentInstance.description =
      "Are you sure to return this invoice?";
    this.confirmReturnInvoiceModal.result.then((result) => {
      if (result == true) {
        this.saleInvoiceList = [];
        this.RTS.returnFullInvoice(invoiceId).subscribe(
          (result) => {
            this.saleInvoiceList = result;
            this.getInvoiceSummeries();
          },
          (err) => {
            this.getInvoiceSummeries();
            this.getInvoiceDetails();
            this.toastr.error(err.error.message, "Error!");
          }
        );
      }
    });
  }

  exportExcel() {
    const invoiceList = [];
    if (this.saleInvoiceList) {
      Promise.all(
        this.saleInvoiceList.map(detail => {
          let obj = {
            "Invoice No": detail.invoiceNo,
            "Invoice Date": this.datepipe.transform(detail.date, "dd-MM-yyyy"),
            "Manual Book No": detail.manualBookNo,
            "Company Name": detail.companyName,
            "Buy Cost": detail.buyCost,
            "Quantity": detail.totalQty,
            "Total Net Cost": detail.totalNetCost
          }
          invoiceList.push(obj);
        })
      )
    }
    this.ES.exportAsExcelFile(invoiceList, `invoice-details-${new Date().toDateString()}`)
  }

  captureScreen() {
    if (this.saleInvoiceList) {
      let list = this.customerPipe.transform(
        this.saleInvoiceList,
        this.customerName,
        "companyName"
      );
      list = this.invoicePipe.transform(list, this.invoiceNo, "invoiceNo");

      const doc = new jsPDF();

      doc.setFontSize(18);
      doc.text("Sale Report", 14, 22);
      doc.autoTable({ html: "#my-table" });
      // Or use javascript directly:
      doc.autoTable({
        head: [
          [
            "Invoice Date",
            "Invoice No",
            "Manual Book no",
            "Posted",
            "Quantity",
            "Net Amount",
          ],
        ],
        body: list.reduce((acc, cur) => {
          acc.push([
            this.datepipe.transform(cur.date, "yyyy-MM-dd"),
            cur.invoiceNo,
            cur.manualBookNo,
            this.numberPipe.transform(cur.postedStatus),
            this.numberPipe.transform(cur.totalQty),
            this.numberPipe.transform(cur.totalNetCost),
          ]);
          return acc;
        }, []),
        startY: 30,
        showHead: "firstPage",
        styles: { overflow: "ellipsize", cellWidth: "wrap" },
        columnStyles: { text: { cellWidth: "auto" } },
      });
      let date = new Date().toDateString();
      doc.save(`sale-ledger-${date}.pdf`);
    }
  }
}
