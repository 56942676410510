import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  constructor(private http: HttpClient, private router: Router) {}
  /**
   * set header values
   */

  public getHeaders() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const options = {
      headers: headers,
      withCrendentials: true,
    };
    return options;
  }

  /**
   * get request
   */

  public get(url: string) {
    return this.http.get(url).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((resp) => {
        if (resp.status == 401) {
          this.router.navigateByUrl("/login");
        }
        throw resp;
      })
    );
  }

  /**
   * post request
   */

  public post(url: string, obj: Object) {
    return this.http.post(url, obj, this.getHeaders()).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((resp) => {
        if (resp.status == 401) {
          this.router.navigateByUrl("/login");
        }
        throw resp;
      })
    );
  }

  public uploadMultiPath(url: string, obj: Object) {
    return this.http.post(url, obj, { reportProgress: true }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((resp) => {
        if (resp.status == 401) {
          this.router.navigateByUrl("/login");
        }
        throw resp;
      })
    );
  }

  /**
   * put request
   */

  public put(url: string, obj: Object) {
    return this.http.put(url, JSON.stringify(obj), this.getHeaders()).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        throw err;
      })
    );
  }

  public delete(url: string) {
    return this.http.delete(url).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((resp) => {
        throw resp;
      })
    );
  }
}
