import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fBrandName'
})
export class FBrandNamePipe implements PipeTransform {

  transform(value: any, brandName: string): any {
    return brandName ? value.filter((brand) => {
      return brand ? brand.brandId.brandName ? brand.brandId.brandName.toLocaleLowerCase().indexOf(brandName.toLocaleLowerCase()) !== -1 : value : value;
    }) : value;
  }

}
