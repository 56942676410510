import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UsersService } from './../../services/index';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  public loginForm: FormGroup;
  public isLoading: boolean = false;
  public errMsg: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private US: UsersService,
    private toastr: ToastrService
  ) {

  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      userName: ['', Validators.required],
      password: ['', Validators.required],
    })
  }

  submit() {
    if(!this.loginForm.valid){
      return
    }
    this.isLoading = true;
    this.errMsg = '';
    this.US.signin(this.loginForm.value).subscribe((cred:any) => {
      localStorage.setItem('STUID',cred.token);
      this.US.getCurrentUserFromBack().subscribe(user=>{
        this.isLoading = false;
        this.router.navigateByUrl('/dashboard/home');
      },err=>{
        this.isLoading = false;
        this.toastr.error(err.error.message,'Error!');
      });
    },err=>{
      this.isLoading = false;
      this.toastr.error(err.error.message,'Error!');
    });
  }
}
