import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transaction'
})
export class TransactionPipe implements PipeTransform {

  transform(value: any, transactionId: string, propName: string): any {
    return transactionId ? value.filter((id) => {
      return id ? id.transactionId ? id.transactionId.indexOf(transactionId) !== -1 : value : value;
    }) : value;
  }

}
