import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fModelNo'
})
export class FModelNoPipe implements PipeTransform {

  transform(value: any, modelNumber: string, ): any {
    return modelNumber ? value.filter((brand) => {
      return brand ? brand.modelNumber ? brand.modelNumber.toLocaleLowerCase().indexOf(modelNumber.toLocaleLowerCase()) !== -1 : value : value;
    }) : value;
  }

}
