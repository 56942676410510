import { Injectable } from '@angular/core';
import { HttpService } from './../http/http.service';
import { environment } from './../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BankService {

  public banks;

  constructor(private http: HttpService) { }

  public addBank(obj) {
    obj['token'] = localStorage.getItem('STUID');
    return this.http.post(`${environment.baseUrl}/api/bank/?token=${localStorage.getItem('STUID')}`, obj)
    .pipe(map((response: any) => {
      this.banks = response;
      return response;
    }),
      catchError(err => {
        throw err;
      })
    );
  }

  public getBanks() {
    return this.banks;
  }

  getBankDetail(id){
    return this.http.get(`${environment.baseUrl}/api/bank/${id}?token=${localStorage.getItem('STUID')}`)
  }

  public updateBank(obj){
    return this.http.put(`${environment.baseUrl}/api/bank/?token=${localStorage.getItem('STUID')}`,obj)
    .pipe(map((response: any) => {
      this.banks = response;
      return response;
    }),
      catchError(err => {
        throw err;
      })
    );
  }

  public deleteBank(id){
    return this.http.delete(`${environment.baseUrl}/api/bank/${id}?token=${localStorage.getItem('STUID')}`)
  }

  public getBanksFromBack() {
    return this.http.get(`${environment.baseUrl}/api/bank/?token=${localStorage.getItem('STUID')}`)
    .pipe(map((response: any) => {
      this.banks = response;
      return response;
    }),
      catchError(err => {
        throw err;
      })
    );
  }

  getBankLedgerListing(){
    return this.http.get(`${environment.baseUrl}/api/bank/getBankLedgerListing?token=${localStorage.getItem('STUID')}`)
  }

}
