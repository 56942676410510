import { Injectable } from '@angular/core';
import { HttpService } from './../http/http.service';
import { environment } from './../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  public uploadPdf;
  public currentUser;

  constructor(private http: HttpService) { }

  public addUser(obj) {
    return this.http.post(`${environment.baseUrl}/api/users/?token=${localStorage.getItem('STUID')}`, obj);
  }

  public deleteUser(obj) {
    return this.http.post(`${environment.baseUrl}/api/users/deleteUser?token=${localStorage.getItem('STUID')}`, obj);
  }

  public getUser() {
    return this.http.get(`${environment.baseUrl}/api/users/?token=${localStorage.getItem('STUID')}`);
  }

  public updateUser(obj) {
    return this.http.put(`${environment.baseUrl}/api/users/?token=${localStorage.getItem('STUID')}`, obj);
  }

  public getUserNameList() {
    return this.http.get(`${environment.baseUrl}/api/users/getUserNameList?token=${localStorage.getItem('STUID')}`);
  }

  public getUserRoles(id) {
    return this.http.get(`${environment.baseUrl}/api/usersRoles/${id}?token=${localStorage.getItem('STUID')}`);
  }

  public updateUserRoles(obj) {
    return this.http.put(`${environment.baseUrl}/api/usersRoles/?token=${localStorage.getItem('STUID')}`, obj);
  }

  public signin(obj) {
    return this.http.post(`${environment.baseUrl}/api/auth/signin`, obj);
  }

  public getCurrentUserFromBack() {
    return this.http.get(`${environment.baseUrl}/api/auth/${localStorage.getItem('STUID')}`).pipe(map((response: any) => {
      localStorage.setItem(`STU`, JSON.stringify(response));
      return response;
    }),
      catchError(err => {
        throw err;
      })
    );
  }

  public getCurrentUser() {
    return this.currentUser;
  }
}
