
import * as StockAction from '../action/stock.action'

export interface State {
  stock: any,
  getStock: {},
  getStockLoader,
  getStockError: {},
}

const initialState: State = {
  stock: null,

  getStock: {},
  getStockLoader: Boolean,
  getStockError: {},

}
export function getStockReducer(state = initialState, action: StockAction.Actions) {
  switch (action.type) {
    case StockAction.GET_STOCK:
      return {
        ...state,
        getStock: {},
        getStockLoader: true,
        getStockError: null,

      }
    case StockAction.GET_STOCK_SUCCESS:
      return {
        ...state,
        getStock: action.payload,
        getStockLoader: false,
        getStockError: null
      }

    case StockAction.GET_STOCK_ERROR:
      return {
        ...state,
        getStock: {},
        getStockLoader: false,
        getStockError: action.payload
      }
    case StockAction.ADD_STOCK:
      return {
        ...state,
        brand: action.payload
      }
    case StockAction.ADD_STOCK_SUCCESS:
      return {
        ...state,
        brand: action.payload
      }
    default:
      break;
  }
}
