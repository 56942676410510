import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UsersService } from './../users/users.service';
import { HttpService } from './../http/http.service';
import { environment } from './../../../environments/environment';
import { Observable ,Subject} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AuthGuradService implements CanActivate{
  public loader = false;
  constructor(private http: HttpService,public auth: UsersService, public router: Router) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let token = localStorage.getItem('STUID') ? localStorage.getItem('STUID') : '';
    if(!token) return this.router.navigate(['login']);
    this.loader = true;
    return this.http.get(`${environment.baseUrl}/api/auth/${token}`).pipe(map((response: any) => {
      this.loader = false;
      return true;
    }),
    catchError(err => {
      this.loader = false;
      this.router.navigate(['login']);
      throw err;
    }));
  }
}
