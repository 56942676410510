import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrandListService {

  private _itemData = new Subject<any>();
  itemList$ = this._itemData.asObservable();
  constructor() { }

  sendMsg(data: any) {
    this._itemData.next(data);
  }
}
