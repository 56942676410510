import { Injectable } from "@angular/core";
import { environment } from "./../../../environments/environment";
import { HttpService } from "./../http/http.service";

@Injectable({
  providedIn: "root",
})
export class SaleInvoiceService {
  constructor(private http: HttpService) {}

  public getInvoiceDetails() {
    return this.http.get(
      `${environment.baseUrl}/api/invoice/?token=${localStorage.getItem(
        "STUID"
      )}`
    );
  }

  public getInvoiceWithInvoiceDetails(id) {
    return this.http.get(
      `${
        environment.baseUrl
      }/api/invoice/getInvoiceWithInvoiceDetails/${id}?token=${localStorage.getItem(
        "STUID"
      )}`
    );
  }

  public getInvoiceId() {
    return this.http.get(
      `${
        environment.baseUrl
      }/api/invoice/getInvoiceId?token=${localStorage.getItem("STUID")}`
    );
  }

  public addSaleInvoice(obj) {
    return this.http.post(
      `${environment.baseUrl}/api/invoiceDetails/?token=${localStorage.getItem(
        "STUID"
      )}`,
      obj
    );
  }

  public addDamageSaleInvoice(obj) {
    return this.http.post(
      `${
        environment.baseUrl
      }/api/invoiceDetails/addDamageSale/?token=${localStorage.getItem(
        "STUID"
      )}`,
      obj
    );
  }

  public ubdateSaleInvoice(obj) {
    return this.http.put(
      `${environment.baseUrl}/api/invoiceDetails/?token=${localStorage.getItem(
        "STUID"
      )}`,
      obj
    );
  }
  public getModelColorWiseSale() {
    return this.http.get(
      `${
        environment.baseUrl
      }/api/invoiceDetails/modelColorWiseSale?token=${localStorage.getItem(
        "STUID"
      )}`
    );
  }

  public getSummeryDetails(obj) {
    return this.http.post(
      `${
        environment.baseUrl
      }/api/invoice/getSummeryDetails?token=${localStorage.getItem("STUID")}`,
      obj
    );
  }
}
