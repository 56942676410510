import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PaymentService } from "./../../services";
import * as jsPDF from "jspdf";
import { DatePipe } from "@angular/common";
import {
  TransactionPipe,
  FFromDatePipe,
  FToDatePipe,
  NumberPipe,
} from "./../../pipe/index";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-recieve-money-report",
  templateUrl: "./recieve-money-report.component.html",
  styleUrls: ["./recieve-money-report.component.scss"],
})
export class RecieveMoneyReportComponent implements OnInit {
  public fromDate: Date;
  public toDate: Date;
  public searchName: string = "";
  public recieveMoneyList = [];
  public isAddRecieveMoneyAccess: boolean = false;
  public isLoading: boolean = true;
  public errMsg: string;
  public showDetailDescription: boolean = false;
  public showDetailIndex: number = null;
  public sort: any = {};

  constructor(
    private router: Router,
    private PS: PaymentService,
    public datepipe: DatePipe,
    public TransactionPipe: TransactionPipe,
    public fromDatePipe: FFromDatePipe,
    public toDatePipe: FToDatePipe,
    public numberPipe: NumberPipe,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem("STU"));

    this.isAddRecieveMoneyAccess = user.updateAndCreateLedger;

    this.PS.getPayments().subscribe(result => {
      this.recieveMoneyList = result;
      this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        this.errMsg = err.error.message;
        this.toastr.error(err.error.message, "Error!");
      }
    );
  }
  
  sortData(active: any, direction: any) {
    this.sort = {
      direction,
      active,
    };
  }

  currentSorting(active: any, direction: any)  {
    return this.sort.active === active && this.sort.direction === direction
  }

  createPayment() {
    this.router.navigateByUrl("/dashboard/payment-receive");
  }

  updatePayment(payment) {
    this.router.navigateByUrl(`/dashboard/update-payment/${payment._id}`);
  }

  clearAll() {
    this.searchName = "";
    this.fromDate = null;
    this.toDate = null;
  }

  captureScreen() {
    if (this.recieveMoneyList) {
      let list = this.TransactionPipe.transform(
        this.recieveMoneyList,
        this.searchName,
        "transactionId"
      );
      list = this.fromDatePipe.transform(list, this.fromDate, "customer");
      list = this.toDatePipe.transform(list, this.toDate, "customer");

      const doc = new jsPDF();

      doc.setFontSize(18);
      doc.text("Payment Received Report", 14, 22);
      doc.autoTable({ html: "#my-table" });
      // Or use javascript directly:
      doc.autoTable({
        head: [
          [
            "Date",
            "Transaction No",
            "Customer\nName",
            "Description",
            "Bank",
            "Amount",
          ],
        ],
        body: list.reduce((acc, cur) => {
          let date = this.datepipe.transform(cur.date, "yyyy-MM-dd");
          acc.push([
            date,
            cur.transactionId,
            cur.customerId.companyName,
            cur.description,
            cur.bankId.name,
            this.numberPipe.transform(cur.amount),
          ]);
          return acc;
        }, []),
        startY: 30,
        showHead: "firstPage",
        styles: { overflow: "ellipsize", cellWidth: "wrap" },
        columnStyles: {
          3: {
            columnWidth: "auto",
          },
        },
      });
      let date = new Date().toDateString();
      doc.save(`payment-received-report-${date}.pdf`);
    }
  }
}
