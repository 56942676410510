import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fInvoice'
})
export class FInvoicePipe implements PipeTransform {

  transform(value: any, invoiceNo: string, propName: string): any {
    return invoiceNo ? value.filter((brand) => {
      return brand ? brand.invoiceNo ? brand.invoiceNo.toLocaleLowerCase().indexOf(invoiceNo.toLocaleLowerCase()) !== -1 : value : value;
    }) : value;
  }

}
