import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../store/reducer/item.reducer'

@Component({
  selector: 'app-add-stock-container',
  templateUrl: './add-stock-container.component.html',
  styleUrls: ['./add-stock-container.component.scss']
})

export class AddStockContainerComponent {

  constructor(private store: Store<State>) {
  }

}
