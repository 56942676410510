import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BankService,ExcelService } from './../../services';
import * as jsPDF from 'jspdf';
import { DatePipe } from '@angular/common';
import { SearchPipe } from './../../pipe/index';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.scss']
})
export class BankComponent implements OnInit {

  public errMsg: string = '';
  public banks = [];
  public isCreateBankAccess: boolean = false;
  public searchName: string = '';

  constructor(
    private router: Router,
    private BS: BankService,
    public datepipe: DatePipe,
    private excelService:ExcelService,
    public SearchPipe:SearchPipe,
    private toastr: ToastrService

  ) { }

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem('STU'));
    this.isCreateBankAccess = user.updateAndCreateBank;

    if (!this.BS.getBanks()) {
      this.BS.getBanksFromBack().subscribe(result => {
        this.banks = result;
      },err=>{
        this.errMsg = err.error.message;
        this.toastr.error(err.error.message,'Error!');
      });
    } else {
      this.banks = this.BS.getBanks();
    }
  }

  createBank() {
    this.router.navigateByUrl('/dashboard/add-bank')
  }

  editBank(bank) {
    this.router.navigateByUrl(`/dashboard/update-bank/${bank._id}`)
  }

  deleteBank(bank) {
    this.BS.deleteBank(bank._id).subscribe(result => {
      this.BS.getBanksFromBack().subscribe(banks => {
        this.banks = banks
      },err=>{
        this.toastr.error(err.error.message,'Error!');
      });
    },err=>{
      this.toastr.error(err.error.message,'Error!');
    });
  }

  clearAll() {
    this.searchName = '';
  }

  captureScreen() {
    if (this.banks) {
      let list = this.SearchPipe.transform(this.banks, this.searchName, 'name');
      const doc = new jsPDF()

      doc.setFontSize(18)
      doc.text('Bank Listing report', 14, 22)
      doc.autoTable({ html: '#my-table' })
    // Or use javascript directly:
    doc.autoTable({
      head: [
        [
          'Created on',
          'Bank Name',
          'Account no',
          'Address'
        ]
      ],
      body: list.reduce((acc, cur) => {
        let date = this.datepipe.transform(cur.createdAt, 'yyyy-MM-dd');
      acc.push([
          date,
          cur.name,
          cur.accountNo.toString(),
          cur.address
        ])
        return acc
      }, []),
      startY: 30,
      showHead: 'firstPage',
      styles: { overflow: 'ellipsize', cellWidth: 'wrap' },
      columnStyles: { text: { cellWidth: 'auto' } }
    })
      let date = new Date().toDateString()
      doc.save(`bank-listing-${date}.pdf`);
    }
  }
}
