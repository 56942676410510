
import * as BrandAction from '../action/brand.action'
import { createSelector, ActionReducerMap, createFeatureSelector } from '@ngrx/store';

export interface State {
  brand: any,

  getBrands: {},
  getBrandsLoader,
  getBrandsError: {},

  getItemsBrands: {},
  getItemsBrandsLoader,
  getItemsBrandsError: {},
}

const initialState: State = {
  brand: null,

  getBrands: {},
  getBrandsLoader: Boolean,
  getBrandsError: {},

  getItemsBrands: {},
  getItemsBrandsLoader: Boolean,
  getItemsBrandsError: {},

}
export function getBrandReducer(state = initialState, action: BrandAction.Actions) {
  switch (action.type) {
    case BrandAction.GET_BRAND:
      return {
        ...state,
        getBrands: {},
        getBrandsLoader: true,
        getBrandsError: null
      }
    case BrandAction.GET_BRAND_SUCCESS:
      return {
        ...state,
        getBrands: action.payload,
        getBrandsLoader: false,
        getBrandsError: null
      }

    case BrandAction.GET_BRAND_ERROR:
      return {
        ...state,
        getBrands: {},
        getBrandsLoader: false,
        getBrandsError: action.payload
      }
    case BrandAction.ADD_BRAND:
      return {
        ...state,
        brand: action.payload
      }
    case BrandAction.ADD_BRAND_SUCCESS:
      return {
        ...state,
        brand: action.payload
      }
    case BrandAction.DELETE_BRAND:
      return {
        ...state,
        brand: null
      }
    case BrandAction.DELETE_BRAND_SUCCESS:
      return {
        ...state,
        brand: action.payload
      }
    case BrandAction.UPDATE_BRAND:
      return {
        ...state,
        brand: null
      }
    case BrandAction.UPDATE_BRAND_SUCCESS:
      return {
        ...state,
        brand: action.payload
      }
    case BrandAction.GET_ITEM_BRAND:
      return {
        ...state,
        getItemsBrands: {},
        getItemsBrandsLoader: true,
        getItemsBrandsError: null
      }
    case BrandAction.GET_ITEM_BRAND_SUCCESS:
      return {
        ...state,
        getItemsBrands: action.payload,
        getItemsBrandsLoader: false,
        getItemsBrandsError: null
      }

    case BrandAction.GET_ITEM_BRAND_ERROR:
      return {
        ...state,
        getItemsBrands: {},
        getItemsBrandsLoader: false,
        getItemsBrandsError: action.payload
      }
    default:
      break;
  }
}
