import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subString'
})
export class SubStringPipe implements PipeTransform {

  transform(value: string,show:string,detailIndex:number, index:number, type:string): any {
    switch(type){
      case 'lessMore':
        return ((value.length > 20) && (detailIndex == index) && show ) ? value : (value.length > 20) ? `${value.slice(0,20)}...` : value
        break;
      case 'simple':
        return value && value.length > 20 ? `${value.slice(0,20)}...` : value;
        break;
      default:
        return value;
    }
  }

}
