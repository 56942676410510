import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { BrandList } from '../../store/interface/brand'
import { ItemList } from '../../store/interface/item'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AddBrandComponent } from './../add-brand/add-brand.component';
import { BankService, BrandsService } from './../../services'
import { SearchBrandPipe } from './../../pipe/index';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import jsPDF from 'jspdf'
import 'jspdf-autotable'

const MODALS = {
  focusFirst: AddBrandComponent
};

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})

export class BrandsComponent implements OnInit {

  private addBrandRef: NgbModalRef;
  public brandDataList: BrandList[];
  public isCreateBrandAccess: boolean;
  public errMsg: string = '';
  public isViewable: boolean = false;
  public listBrand = [];
  public list: ItemList[];
  public brandList = [];
  public isLoading: Boolean = true;
  public searchName: string = '';
  @Input() itemList: any;
  @Input() brandlist: any;
  @Output() editBrand: EventEmitter<any>;
  @Output() deleteBrand: EventEmitter<any>;

  constructor(
    private router: Router,
    private _addBrandService: NgbModal,
    private BS: BrandsService,
    public datepipe: DatePipe,
    public SearchBrandPipe:SearchBrandPipe,
    private toastr: ToastrService
  ) {
    this.editBrand = new EventEmitter();
    this.deleteBrand = new EventEmitter();
  }

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem('STU'));
    this.isCreateBrandAccess = user.addAndUpdateBrand;
    this.BS.getBrandsFromBack().subscribe(result => {
      this.brandList = result;
      this.isLoading = false;
    },err=>{
      this.isLoading = false;
      this.errMsg = err.error.message;
      this.toastr.error(err.error.message,'Error!');
    });
  }

  createBrand() {
    this.addBrandRef = this._addBrandService.open(AddBrandComponent);
    this.addBrandRef.componentInstance.list = this.list;
    this.addBrandRef.componentInstance.brand_list = this.brandList;
    this.addBrandRef.componentInstance.idEdit = false;
    this.addBrandRef.result.then((obj) => {
      this.isLoading = true;
      this.errMsg = '';
      this.brandList = [];
      this.BS.addBrands(obj).subscribe((result:any) => {
        this.errMsg = '';
        this.isLoading = false;
        this.brandList = result;
      },err=>{
        this.toastr.error(err.error.message,'Error!');
      });
    })
  }

  brandEdit(id: string) {
    let item = this.brandList.find(x => x._id === id);
    this.addBrandRef = this._addBrandService.open(AddBrandComponent);
    this.addBrandRef.componentInstance.editBrand = item;
    this.addBrandRef.componentInstance.list = this.list;
    this.addBrandRef.componentInstance.brand_list = this.brandList;
    this.addBrandRef.componentInstance.idEdit = true;
    this.addBrandRef.result.then((result) => {
      this.BS.getBrandsFromBack().subscribe(result => {
        this.brandList = result;
      },err=>{
        this.toastr.error(err.error.message,'Error!');
      });
    })
  }

  brandDelete(id: string) {
    this.deleteBrand.emit(id)
  }

  ngOnChanges(changes) {
    if (changes && changes.itemList != undefined) {
      if (changes.itemList.currentValue && changes.itemList.currentValue.length > 0) {
        this.list = changes.itemList.currentValue;
      }
    }
    if (changes && changes.brandlist != undefined) {
      if (changes.brandlist.currentValue && changes.brandlist.currentValue.length > 0) {
        this.brandList = changes.brandlist.currentValue;
      }
    }
  }

  captureScreen() {
    if (this.brandList) {
      let list = this.SearchBrandPipe.transform(this.brandList,this.searchName,'brandList');
      const doc = new jsPDF()

      doc.setFontSize(18)
      doc.text('Brands Name List', 14, 22)
      doc.autoTable({ html: '#my-table' })
    // Or use javascript directly:
    doc.autoTable({
      head: [
        [
          'Date',
          'Item Name',
          'Brand Name'
        ]
      ],
      body: list.reduce((acc, cur) => {
        let date = this.datepipe.transform(cur.createdAt, 'yyyy-MM-dd');
        acc.push([
          date,
          cur.itemId.name,
          cur.brandName,
        ])
        return acc
      }, []),
      startY: 30,
      showHead: 'firstPage',
      styles: { overflow: 'ellipsize', cellWidth: 'wrap' },
      columnStyles: { text: { cellWidth: 'auto' } }
    })
      let date = new Date().toDateString()
      doc.save(`brands-list-${date}.pdf`);
    }
  }

  clearAll(){
    this.searchName = '';
  }
}
