import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { HttpService } from './../http/http.service';

@Injectable({
  providedIn: 'root'
})

export class ReturnInvoiceService {

  constructor(
    private http:HttpService
  ) { }

  public getReturnInvoiceDetails(id){
    return this.http.get(`${environment.baseUrl}/api/invoiceDetails/getInvoiceDetailWithInvoice/${id}?token=${localStorage.getItem('STUID')}`);
  }

  public getReturnInvoiceNo(){
    return this.http.get(`${environment.baseUrl}/api/returnInvoice/getReturnInvoiceId?token=${localStorage.getItem('STUID')}`);
  }

  public getReturnInvoice(){
    return this.http.get(`${environment.baseUrl}/api/returnInvoice/?token=${localStorage.getItem('STUID')}`);
  }

  public addReturnInvoice(obj){
    return this.http.post(`${environment.baseUrl}/api/returnInvoice/?token=${localStorage.getItem('STUID')}`,obj);
  }

  public returnFullInvoice(id){
    return this.http.get(`${environment.baseUrl}/api/returnInvoice/returnWholeInvoice/${id}?token=${localStorage.getItem('STUID')}`);
  }

}
