import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { UsersService,AuthGuradService } from './services/index';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private location: Location,
     private router:Router,
     private US:UsersService,
     private authGuard: AuthGuradService
     ){}

  ngOnInit(){
  }
}
