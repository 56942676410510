export const Cities: any[] = [
        {
            "city": "Karachi", 
            "admin": "Sindh", 
            "country": "Pakistan", 
            "population_proper": "11624219", 
            "iso2": "PK", 
            "capital": "admin", 
            "lat": "24.9056", 
            "lng": "67.0822", 
            "population": "12130000"
        }, 
        {
            "city": "Lahore", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "6310888", 
            "iso2": "PK", 
            "capital": "admin", 
            "lat": "31.549722", 
            "lng": "74.343611", 
            "population": "6577000"
        }, 
        {
            "city": "Faisal\u0101b\u0101d", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "2506595", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "31.416667", 
            "lng": "73.083333", 
            "population": "2617000"
        }, 
        {
            "city": "Serai", 
            "admin": "Khyber Pakhtunkhwa", 
            "country": "Pakistan", 
            "population_proper": "1860310", 
            "iso2": "PK", 
            "capital": "", 
            "lat": "34.73933", 
            "lng": "72.335655", 
            "population": "1860310"
        }, 
        {
            "city": "R\u0101walpindi", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "1743101", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "33.597331", 
            "lng": "73.047904", 
            "population": "1858000"
        }, 
        {
            "city": "Mult\u0101n", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "1437230", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "30.196789", 
            "lng": "71.478241", 
            "population": "1522000"
        }, 
        {
            "city": "Gujr\u0101nw\u0101la", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "1384471", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "32.155667", 
            "lng": "74.187052", 
            "population": "1513000"
        }, 
        {
            "city": "Hyder\u0101b\u0101d City", 
            "admin": "Sindh", 
            "country": "Pakistan", 
            "population_proper": "1386330", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "25.396891", 
            "lng": "68.377183", 
            "population": "1459000"
        }, 
        {
            "city": "Pesh\u0101war", 
            "admin": "Khyber Pakhtunkhwa", 
            "country": "Pakistan", 
            "population_proper": "1218773", 
            "iso2": "PK", 
            "capital": "admin", 
            "lat": "34.008", 
            "lng": "71.578488", 
            "population": "1303000"
        }, 
        {
            "city": "Abbott\u0101b\u0101d", 
            "admin": "Khyber Pakhtunkhwa", 
            "country": "Pakistan", 
            "population_proper": "881000", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "34.1463", 
            "lng": "73.211684", 
            "population": "1183647"
        }, 
        {
            "city": "Islamabad", 
            "admin": "Isl\u0101m\u0101b\u0101d", 
            "country": "Pakistan", 
            "population_proper": "601600", 
            "iso2": "PK", 
            "capital": "primary", 
            "lat": "33.69", 
            "lng": "73.0551", 
            "population": "780000"
        }, 
        {
            "city": "Quetta", 
            "admin": "Balochist\u0101n", 
            "country": "Pakistan", 
            "population_proper": "733675", 
            "iso2": "PK", 
            "capital": "admin", 
            "lat": "30.184138", 
            "lng": "67.00141", 
            "population": "768000"
        }, 
        {
            "city": "Bannu", 
            "admin": "Khyber Pakhtunkhwa", 
            "country": "Pakistan", 
            "population_proper": "550000", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "32.985414", 
            "lng": "70.602701", 
            "population": "622419"
        }, 
        {
            "city": "Bah\u0101walpur", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "552607", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "29.4", 
            "lng": "71.683333", 
            "population": "552607"
        }, 
        {
            "city": "Sargodha", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "542603", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "32.083611", 
            "lng": "72.671111", 
            "population": "542603"
        }, 
        {
            "city": "Si\u0101lkot City", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "477396", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "32.499101", 
            "lng": "74.52502", 
            "population": "477396"
        }, 
        {
            "city": "Sukkur", 
            "admin": "Sindh", 
            "country": "Pakistan", 
            "population_proper": "417767", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "27.705164", 
            "lng": "68.857383", 
            "population": "417767"
        }, 
        {
            "city": "L\u0101rk\u0101na", 
            "admin": "Sindh", 
            "country": "Pakistan", 
            "population_proper": "364033", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "27.558985", 
            "lng": "68.212035", 
            "population": "364033"
        }, 
        {
            "city": "Sheikhupura", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "361303", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "31.713056", 
            "lng": "73.978333", 
            "population": "361303"
        }, 
        {
            "city": "M\u012brpur Kh\u0101s", 
            "admin": "Sindh", 
            "country": "Pakistan", 
            "population_proper": "215657", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "25.5251", 
            "lng": "69.0159", 
            "population": "356435"
        }, 
        {
            "city": "Rah\u012bmy\u0101r Kh\u0101n", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "353203", 
            "iso2": "PK", 
            "capital": "", 
            "lat": "28.419482", 
            "lng": "70.302386", 
            "population": "353203"
        }, 
        {
            "city": "Koh\u0101t", 
            "admin": "Khyber Pakhtunkhwa", 
            "country": "Pakistan", 
            "population_proper": "151427", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "33.581958", 
            "lng": "71.449291", 
            "population": "343027"
        }, 
        {
            "city": "Jhang Sadr", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "341210", 
            "iso2": "PK", 
            "capital": "", 
            "lat": "31.269811", 
            "lng": "72.316867", 
            "population": "341210"
        }, 
        {
            "city": "Gujr\u0101t", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "301506", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "32.574204", 
            "lng": "74.075423", 
            "population": "301506"
        }, 
        {
            "city": "Bard\u0101r", 
            "admin": "Khyber Pakhtunkhwa", 
            "country": "Pakistan", 
            "population_proper": "300424", 
            "iso2": "PK", 
            "capital": "", 
            "lat": "34.163737", 
            "lng": "72.011571", 
            "population": "300424"
        }, 
        {
            "city": "Kas\u016br", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "290643", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "31.115556", 
            "lng": "74.446667", 
            "population": "290643"
        }, 
        {
            "city": "Dera Gh\u0101zi Kh\u0101n", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "236093", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "30.056142", 
            "lng": "70.634766", 
            "population": "236093"
        }, 
        {
            "city": "Mas\u012bw\u0101la", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "235695", 
            "iso2": "PK", 
            "capital": "", 
            "lat": "30.683333", 
            "lng": "73.066667", 
            "population": "235695"
        }, 
        {
            "city": "Naw\u0101bsh\u0101h", 
            "admin": "Sindh", 
            "country": "Pakistan", 
            "population_proper": "229504", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "26.248334", 
            "lng": "68.409554", 
            "population": "229504"
        }, 
        {
            "city": "Ok\u0101ra", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "223648", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "30.808056", 
            "lng": "73.445833", 
            "population": "223648"
        }, 
        {
            "city": "Gilgit", 
            "admin": "Gilgit-Baltistan", 
            "country": "Pakistan", 
            "population_proper": "31633", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "35.920007", 
            "lng": "74.313656", 
            "population": "216760"
        }, 
        {
            "city": "Chiniot", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "201781", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "31.72", 
            "lng": "72.978889", 
            "population": "201781"
        }, 
        {
            "city": "S\u0101diq\u0101b\u0101d", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "189876", 
            "iso2": "PK", 
            "capital": "", 
            "lat": "28.30623", 
            "lng": "70.130646", 
            "population": "189876"
        }, 
        {
            "city": "Turbat", 
            "admin": "Balochist\u0101n", 
            "country": "Pakistan", 
            "population_proper": "75694", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "26.001224", 
            "lng": "63.048491", 
            "population": "147791"
        }, 
        {
            "city": "Dera Ism\u0101\u012bl Kh\u0101n", 
            "admin": "Khyber Pakhtunkhwa", 
            "country": "Pakistan", 
            "population_proper": "31737", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "31.832691", 
            "lng": "70.902398", 
            "population": "101616"
        }, 
        {
            "city": "Chaman", 
            "admin": "Balochist\u0101n", 
            "country": "Pakistan", 
            "population_proper": "88568", 
            "iso2": "PK", 
            "capital": "", 
            "lat": "30.917689", 
            "lng": "66.45259", 
            "population": "88568"
        }, 
        {
            "city": "Zhob", 
            "admin": "Balochist\u0101n", 
            "country": "Pakistan", 
            "population_proper": "50537", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "31.340817", 
            "lng": "69.449304", 
            "population": "88356"
        }, 
        {
            "city": "Mehra", 
            "admin": "Khyber Pakhtunkhwa", 
            "country": "Pakistan", 
            "population_proper": "66486", 
            "iso2": "PK", 
            "capital": "", 
            "lat": "34.312817", 
            "lng": "73.220525", 
            "population": "66486"
        }, 
        {
            "city": "Parachin\u0101r", 
            "admin": "Federally Administered Tribal Areas", 
            "country": "Pakistan", 
            "population_proper": "55685", 
            "iso2": "PK", 
            "capital": "", 
            "lat": "33.895672", 
            "lng": "70.098875", 
            "population": "55685"
        }, 
        {
            "city": "Gw\u0101dar", 
            "admin": "Balochist\u0101n", 
            "country": "Pakistan", 
            "population_proper": "23364", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "25.12163", 
            "lng": "62.325411", 
            "population": "51901"
        }, 
        {
            "city": "Kundi\u0101n", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "35406", 
            "iso2": "PK", 
            "capital": "", 
            "lat": "32.457747", 
            "lng": "71.478918", 
            "population": "35406"
        }, 
        {
            "city": "Shahd\u0101d Kot", 
            "admin": "Sindh", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "27.847263", 
            "lng": "67.906789", 
            "population": ""
        }, 
        {
            "city": "Har\u012bpur", 
            "admin": "Khyber Pakhtunkhwa", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "33.999967", 
            "lng": "72.934093", 
            "population": ""
        }, 
        {
            "city": "Mati\u0101ri", 
            "admin": "Sindh", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "25.59709", 
            "lng": "68.4467", 
            "population": ""
        }, 
        {
            "city": "Dera All\u0101hy\u0101r", 
            "admin": "Balochist\u0101n", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "28.373529", 
            "lng": "68.350778", 
            "population": ""
        }, 
        {
            "city": "Lodhr\u0101n", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "29.540507", 
            "lng": "71.63357", 
            "population": ""
        }, 
        {
            "city": "Batgr\u0101m", 
            "admin": "Khyber Pakhtunkhwa", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "34.679637", 
            "lng": "73.026299", 
            "population": ""
        }, 
        {
            "city": "Thatta", 
            "admin": "Sindh", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "24.747449", 
            "lng": "67.923528", 
            "population": ""
        }, 
        {
            "city": "B\u0101gh", 
            "admin": "Azad Kashmir", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "33.981106", 
            "lng": "73.776084", 
            "population": ""
        }, 
        {
            "city": "Bad\u012bn", 
            "admin": "Sindh", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "24.655995", 
            "lng": "68.836997", 
            "population": ""
        }, 
        {
            "city": "M\u0101nsehra", 
            "admin": "Khyber Pakhtunkhwa", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "34.330232", 
            "lng": "73.196788", 
            "population": ""
        }, 
        {
            "city": "Zi\u0101rat", 
            "admin": "Balochist\u0101n", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "30.382444", 
            "lng": "67.725624", 
            "population": ""
        }, 
        {
            "city": "Muzaffargarh", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "30.072576", 
            "lng": "71.193788", 
            "population": ""
        }, 
        {
            "city": "Tando All\u0101hy\u0101r", 
            "admin": "Sindh", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "25.462626", 
            "lng": "68.719233", 
            "population": ""
        }, 
        {
            "city": "Dera Mur\u0101d Jam\u0101li", 
            "admin": "Balochist\u0101n", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "28.546568", 
            "lng": "68.223081", 
            "population": ""
        }, 
        {
            "city": "Karak", 
            "admin": "Khyber Pakhtunkhwa", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "33.116334", 
            "lng": "71.093536", 
            "population": ""
        }, 
        {
            "city": "Mardan", 
            "admin": "Khyber Pakhtunkhwa", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "34.197943", 
            "lng": "72.04965", 
            "population": ""
        }, 
        {
            "city": "Uthal", 
            "admin": "Balochist\u0101n", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "25.807222", 
            "lng": "66.621944", 
            "population": ""
        }, 
        {
            "city": "Nank\u0101na S\u0101hib", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "31.4475", 
            "lng": "73.697222", 
            "population": ""
        }, 
        {
            "city": "B\u0101rkh\u0101n", 
            "admin": "Balochist\u0101n", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "29.897727", 
            "lng": "69.525584", 
            "population": ""
        }, 
        {
            "city": "H\u0101fiz\u0101b\u0101d", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "32.067857", 
            "lng": "73.685449", 
            "population": ""
        }, 
        {
            "city": "Kotli", 
            "admin": "Azad Kashmir", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "33.518362", 
            "lng": "73.902203", 
            "population": ""
        }, 
        {
            "city": "Loralai", 
            "admin": "Balochist\u0101n", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "30.370512", 
            "lng": "68.597949", 
            "population": ""
        }, 
        {
            "city": "Dera Bugti", 
            "admin": "Balochist\u0101n", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "29.036188", 
            "lng": "69.158493", 
            "population": ""
        }, 
        {
            "city": "Jhang City", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "31.305684", 
            "lng": "72.325941", 
            "population": ""
        }, 
        {
            "city": "S\u0101h\u012bw\u0101l", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "30.666667", 
            "lng": "73.1", 
            "population": ""
        }, 
        {
            "city": "S\u0101nghar", 
            "admin": "Sindh", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "26.046558", 
            "lng": "68.9481", 
            "population": ""
        }, 
        {
            "city": "P\u0101kpattan", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "30.341044", 
            "lng": "73.386642", 
            "population": ""
        }, 
        {
            "city": "Chakw\u0101l", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "32.933376", 
            "lng": "72.858531", 
            "population": ""
        }, 
        {
            "city": "Khush\u0101b", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "32.296667", 
            "lng": "72.3525", 
            "population": ""
        }, 
        {
            "city": "Ghotki", 
            "admin": "Sindh", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "28.00604", 
            "lng": "69.316077", 
            "population": ""
        }, 
        {
            "city": "Kohlu", 
            "admin": "Balochist\u0101n", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "29.896505", 
            "lng": "69.253235", 
            "population": ""
        }, 
        {
            "city": "Khuzd\u0101r", 
            "admin": "Balochist\u0101n", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "27.738385", 
            "lng": "66.643365", 
            "population": ""
        }, 
        {
            "city": "Aw\u0101r\u0101n", 
            "admin": "Balochist\u0101n", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "26.456768", 
            "lng": "65.231436", 
            "population": ""
        }, 
        {
            "city": "Nowshera", 
            "admin": "Khyber Pakhtunkhwa", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "34.015828", 
            "lng": "71.981232", 
            "population": ""
        }, 
        {
            "city": "Ch\u0101rsadda", 
            "admin": "Khyber Pakhtunkhwa", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "34.148221", 
            "lng": "71.740604", 
            "population": ""
        }, 
        {
            "city": "Qila Abdull\u0101h", 
            "admin": "Balochist\u0101n", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "30.728035", 
            "lng": "66.661174", 
            "population": ""
        }, 
        {
            "city": "Bah\u0101walnagar", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "29.998659", 
            "lng": "73.253604", 
            "population": ""
        }, 
        {
            "city": "D\u0101du", 
            "admin": "Sindh", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "26.730334", 
            "lng": "67.776896", 
            "population": ""
        }, 
        {
            "city": "Al\u012b\u0101bad", 
            "admin": "Gilgit-Baltistan", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "36.307028", 
            "lng": "74.615449", 
            "population": ""
        }, 
        {
            "city": "Lakki Marwat", 
            "admin": "Khyber Pakhtunkhwa", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "32.607953", 
            "lng": "70.911416", 
            "population": ""
        }, 
        {
            "city": "Chil\u0101s", 
            "admin": "Gilgit-Baltistan", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "35.412867", 
            "lng": "74.104068", 
            "population": ""
        }, 
        {
            "city": "Pishin", 
            "admin": "Balochist\u0101n", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "30.581762", 
            "lng": "66.994061", 
            "population": ""
        }, 
        {
            "city": "T\u0101nk", 
            "admin": "Khyber Pakhtunkhwa", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "32.217071", 
            "lng": "70.383154", 
            "population": ""
        }, 
        {
            "city": "Chitr\u0101l", 
            "admin": "Khyber Pakhtunkhwa", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "35.851802", 
            "lng": "71.786358", 
            "population": ""
        }, 
        {
            "city": "Qila Saifull\u0101h", 
            "admin": "Balochist\u0101n", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "30.700766", 
            "lng": "68.359843", 
            "population": ""
        }, 
        {
            "city": "Shik\u0101rpur", 
            "admin": "Sindh", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "27.957057", 
            "lng": "68.637886", 
            "population": ""
        }, 
        {
            "city": "Panjg\u016br", 
            "admin": "Balochist\u0101n", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "26.971861", 
            "lng": "64.094594", 
            "population": ""
        }, 
        {
            "city": "Mastung", 
            "admin": "Balochist\u0101n", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "29.799656", 
            "lng": "66.845527", 
            "population": ""
        }, 
        {
            "city": "Kal\u0101t", 
            "admin": "Balochist\u0101n", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "29.026629", 
            "lng": "66.593607", 
            "population": ""
        }, 
        {
            "city": "Gand\u0101v\u0101", 
            "admin": "Balochist\u0101n", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "28.613207", 
            "lng": "67.485643", 
            "population": ""
        }, 
        {
            "city": "Kh\u0101new\u0101l", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "30.301731", 
            "lng": "71.932124", 
            "population": ""
        }, 
        {
            "city": "N\u0101row\u0101l", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "32.1", 
            "lng": "74.883333", 
            "population": ""
        }, 
        {
            "city": "Khairpur", 
            "admin": "Sindh", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "27.529483", 
            "lng": "68.761698", 
            "population": ""
        }, 
        {
            "city": "Malakand", 
            "admin": "Khyber Pakhtunkhwa", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "34.565609", 
            "lng": "71.930432", 
            "population": ""
        }, 
        {
            "city": "Vih\u0101ri", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "30.033333", 
            "lng": "72.35", 
            "population": ""
        }, 
        {
            "city": "Saidu Sharif", 
            "admin": "Khyber Pakhtunkhwa", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "34.746548", 
            "lng": "72.355675", 
            "population": ""
        }, 
        {
            "city": "Jhelum", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "32.934484", 
            "lng": "73.731018", 
            "population": ""
        }, 
        {
            "city": "Mandi Bah\u0101udd\u012bn", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "32.587037", 
            "lng": "73.491231", 
            "population": ""
        }, 
        {
            "city": "Bhakkar", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "31.625247", 
            "lng": "71.06574", 
            "population": ""
        }, 
        {
            "city": "Toba Tek Singh", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "30.974326", 
            "lng": "72.482694", 
            "population": ""
        }, 
        {
            "city": "J\u0101mshoro", 
            "admin": "Sindh", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "25.436078", 
            "lng": "68.280172", 
            "population": ""
        }, 
        {
            "city": "Kh\u0101r\u0101n", 
            "admin": "Balochist\u0101n", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "28.584585", 
            "lng": "65.415007", 
            "population": ""
        }, 
        {
            "city": "Umarkot", 
            "admin": "Sindh", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "25.36157", 
            "lng": "69.736241", 
            "population": ""
        }, 
        {
            "city": "Hangu", 
            "admin": "Khyber Pakhtunkhwa", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "33.53198", 
            "lng": "71.059499", 
            "population": ""
        }, 
        {
            "city": "Timargara", 
            "admin": "Khyber Pakhtunkhwa", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "34.826595", 
            "lng": "71.844226", 
            "population": ""
        }, 
        {
            "city": "G\u0101kuch", 
            "admin": "Gilgit-Baltistan", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "36.176826", 
            "lng": "73.763834", 
            "population": ""
        }, 
        {
            "city": "Jacob\u0101b\u0101d", 
            "admin": "Sindh", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "28.281873", 
            "lng": "68.437613", 
            "population": ""
        }, 
        {
            "city": "Alp\u016brai", 
            "admin": "Khyber Pakhtunkhwa", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "34.920577", 
            "lng": "72.632556", 
            "population": ""
        }, 
        {
            "city": "Mi\u0101nw\u0101li", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "32.574095", 
            "lng": "71.526386", 
            "population": ""
        }, 
        {
            "city": "M\u016bsa Khel B\u0101z\u0101r", 
            "admin": "Balochist\u0101n", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "30.859443", 
            "lng": "69.82208", 
            "population": ""
        }, 
        {
            "city": "Naushahro F\u012broz", 
            "admin": "Sindh", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "26.840104", 
            "lng": "68.122651", 
            "population": ""
        }, 
        {
            "city": "New M\u012brpur", 
            "admin": "Azad Kashmir", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "33.147815", 
            "lng": "73.751867", 
            "population": ""
        }, 
        {
            "city": "Daggar", 
            "admin": "Khyber Pakhtunkhwa", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "34.511059", 
            "lng": "72.484375", 
            "population": ""
        }, 
        {
            "city": "Eidg\u0101h", 
            "admin": "Gilgit-Baltistan", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "35.347115", 
            "lng": "74.856317", 
            "population": ""
        }, 
        {
            "city": "Sibi", 
            "admin": "Balochist\u0101n", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "29.542989", 
            "lng": "67.87726", 
            "population": ""
        }, 
        {
            "city": "D\u0101lband\u012bn", 
            "admin": "Balochist\u0101n", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "28.888456", 
            "lng": "64.406156", 
            "population": ""
        }, 
        {
            "city": "R\u0101janpur", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "29.103513", 
            "lng": "70.325038", 
            "population": ""
        }, 
        {
            "city": "Leiah", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "30.961279", 
            "lng": "70.939043", 
            "population": ""
        }, 
        {
            "city": "Upper Dir", 
            "admin": "Khyber Pakhtunkhwa", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "35.207398", 
            "lng": "71.876801", 
            "population": ""
        }, 
        {
            "city": "Tando Muhammad Kh\u0101n", 
            "admin": "Sindh", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "25.123007", 
            "lng": "68.535773", 
            "population": ""
        }, 
        {
            "city": "Attock City", 
            "admin": "Punjab", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "33.76671", 
            "lng": "72.359766", 
            "population": ""
        }, 
        {
            "city": "R\u0101wala Kot", 
            "admin": "Azad Kashmir", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "33.857816", 
            "lng": "73.760426", 
            "population": ""
        }, 
        {
            "city": "Sw\u0101bi", 
            "admin": "Khyber Pakhtunkhwa", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "34.120181", 
            "lng": "72.46982", 
            "population": ""
        }, 
        {
            "city": "Kandhkot", 
            "admin": "Sindh", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "28.243963", 
            "lng": "69.182354", 
            "population": ""
        }, 
        {
            "city": "Dasu", 
            "admin": "Khyber Pakhtunkhwa", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "35.291687", 
            "lng": "73.290602", 
            "population": ""
        }, 
        {
            "city": "Athmuqam", 
            "admin": "Azad Kashmir", 
            "country": "Pakistan", 
            "population_proper": "", 
            "iso2": "PK", 
            "capital": "minor", 
            "lat": "34.571733", 
            "lng": "73.897236", 
            "population": ""
        }
  ];  