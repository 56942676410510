import { Injectable } from "@angular/core";
import { Actions, ofType, Effect } from "@ngrx/effects";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map, switchMap, catchError, tap } from "rxjs/operators";
import * as StockAction from "../action/stock.action";

const httpOptions = {
  headers: new HttpHeaders({ "content-type": "application/json" }),
};
@Injectable()
export class StockEffect {
  constructor(private action$: Actions, private http: HttpClient) {}

  @Effect()
  loadStock$: Observable<any> = this.action$.pipe(
    ofType(StockAction.GET_STOCK),
    map((action: StockAction.GetStock) => action.payload),
    switchMap(() =>
      this.http.get("http://localhost:3005/api/stockDetails").pipe(
        map((response: any) => {
          return new StockAction.GetStockSuccess(response);
        }),
        catchError((error) => {
          return of(new StockAction.GetStockError({ error: error }));
        })
      )
    )
  );

  @Effect()
  addStock$: Observable<any> = this.action$.pipe(
    ofType(StockAction.ADD_STOCK),
    map((action: StockAction.AddStock) => action.payload),
    switchMap((payload: any) =>
      this.http
        .post("http://localhost:3005/api/stockDetails", payload, httpOptions)
        .pipe(
          map((data: Response) => {
            return new StockAction.AddStockSuccess(data);
          }),
          catchError((error: any) => {
            return of(new StockAction.AddStockSuccess(error));
          })
        )
    )
  );
}
