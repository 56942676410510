import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CustomerService, BankService, PaymentService, SharedService } from './../../services';
import {  Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-recieve-money',
  templateUrl: './recieve-money.component.html',
  styleUrls: ['./recieve-money.component.scss']
})
export class RecieveMoneyComponent implements OnInit {

  @Input() try: any;
  @Output() recievePayment: EventEmitter<any>
  public recievePayForm: FormGroup;
  public customers;
  public banks;
  public isUpdatePayment: boolean;
  public upatePayment;
  public isButtonDisable:boolean = false;
  public isLoad: boolean;

  constructor(
    private fb: FormBuilder,
    private CS: CustomerService,
    private BS: BankService,
    private PS: PaymentService,
    private SS: SharedService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private datepipe: DatePipe,
    private toastr: ToastrService
  ) {
    this.recievePayment = new EventEmitter();
  }

  ngOnInit() {
    this.getCustomers();
    this.getBanks();
    this.initializeForm();

  }

  initializeForm(){
    let paymentId = this.activatedRoute.snapshot.params.paymentId;
    if (paymentId) {
      this.PS.getPaymentDetailById(paymentId).subscribe(detail => {
        this.isUpdatePayment = true;
        this.upatePayment = detail;
        this.recievePayForm = this.fb.group({
          transactionId: [detail.transactionId, Validators.required],
          date: [this.fromJsonDate(detail.date), Validators.required],
          customerId: [detail.customerId, Validators.required],
          amount: [detail.amount, Validators.required],
          description: [detail.description, Validators.required],
          bankId: [detail.bankId],
        })
      },err=>{
        this.toastr.error(err.error.message,'Error!');
      });
    } else {
      this.PS.getTransactionsId().subscribe(result => {
        this.recievePayForm = this.fb.group({
          transactionId: [result.trasactionsId, Validators.required],
          date: [this.datepipe.transform(new Date(), 'yyyy-MM-dd'), Validators.required],
          customerId: ['', Validators.required],
          amount: ['', Validators.required],
          description: ['', Validators.required],
          bankId: [''],
          requestType:['debit',Validators.required]
        })
      },err=>{
        this.toastr.error(err.error.message,'Error!');
      });
    }
  }

  getBanks() {
    if (!this.BS.getBanks()) {
      this.BS.getBanksFromBack().subscribe(result => {
        this.banks = result;
      },err=>{
        this.toastr.error(err.error.message,'Error!');
      });    } else {
      this.banks = this.BS.getBanks();
    }
  }

  fromJsonDate(jDate): string {
    const bDate: Date = new Date(jDate);
    return bDate.toISOString().substring(0, 10);
  }

  getCustomers() {
    this.CS.getCustomersFromBack().subscribe(result => {
      this.customers = result;
    },err=>{
      this.toastr.error(err.error.message,'Error!');
    });  }

  submit() {
    if(!this.recievePayForm.valid){
      return
    }
    this.isButtonDisable = true;
    this.isLoad = true;
    let obj = this.recievePayForm.value;
    if(obj.requestType == 'credit') delete obj['bankId'];
    obj['history'] = this.SS.getHistory(obj, 'add', '', this.customers, this.banks);
    this.PS.addPayment(obj).subscribe(result => {
      this.router.navigateByUrl('/dashboard/receive-money-report')
    },err=>{
      this.isButtonDisable = false;
      this.isLoad = false;
      this.toastr.error(err.error.message,'Error!');
    })
  }

  update() {
    if(!this.recievePayForm.valid){
      return
    }
    this.isButtonDisable = true;
    this.isLoad = true;
    let changes = this.SS.detectChange(this.recievePayForm.value, this.upatePayment);
    this.upatePayment['history'] = this.SS.getHistory(this.upatePayment, 'update', changes, this.customers, this.banks);
    this.upatePayment.customerId = this.recievePayForm.value.customerId;
    this.upatePayment.date = this.recievePayForm.value.date;
    this.upatePayment.amount = this.recievePayForm.value.amount;
    this.upatePayment.description = this.recievePayForm.value.description;
    this.upatePayment.bankId = this.recievePayForm.value.bankId;
    this.PS.updatePayment(this.upatePayment).subscribe(result => {
      this.router.navigateByUrl('/dashboard/recieve-money-report');
    },err=>{
      this.isButtonDisable = false;
      this.isLoad = false;
      this.toastr.error(err.error.message,'Error!');
    })
  }
}
