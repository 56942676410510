import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fPdf'
})
export class FPdfPipe implements PipeTransform {

  transform(value: any, pdfName: string, propName: string): any {
    return pdfName ? value.filter((brand) => {
      return brand ? brand.pdfName ? brand.pdfName.toLocaleLowerCase().indexOf(pdfName.toLocaleLowerCase()) !== -1 : value : value; 
    }) : value;
  }
}
