import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CustomerService, SharedService } from "./../../services";
import * as jsPDF from "jspdf";
import { DatePipe } from "@angular/common";
import { FCustomerPipe } from "./../../pipe/index";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-customer",
  templateUrl: "./customer.component.html",
  styleUrls: ["./customer.component.scss"],
})
export class CustomerComponent implements OnInit {
  fromDate: string = "";
  toDate: string = "";
  searchName: string = "";
  errMsg: string = "";
  customerList = [];
  currentPage = 1;
  public isAddCustomerAccess: boolean = false;

  constructor(
    private router: Router,
    private CS: CustomerService,
    private SS: SharedService,
    public datepipe: DatePipe,
    public FCustomerPipe: FCustomerPipe,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem("STU"));
    this.isAddCustomerAccess = user.updateAndCreateCustomer;
    this.CS.getCustomersFromBack().subscribe(
      (result) => {
        this.customerList = result;
      },
      (err) => {
        this.errMsg = err.error.message;
        this.toastr.error(err.error.message, "Error!");
      }
    );
  }

  createClient() {
    this.router.navigate(["dashboard/add-customer"]);
  }

  clearAll() {
    this.searchName = "";
  }

  deleteCustomer(customerId) {
    this.CS.deleteCustomer(customerId).subscribe(
      (result) => {
        this.CS.getCustomersFromBack().subscribe(
          (result) => {
            this.customerList = result;
          },
          (err) => {
            this.toastr.error(err.error.message, "Error!");
          }
        );
      },
      (err) => {
        this.toastr.error(err.error.message, "Error!");
      }
    );
  }

  editCustomer(customerId) {
    this.router.navigateByUrl(`dashboard/edit-customer/${customerId}`);
  }

  generatePdf() {
    if (this.customerList) {
      let list = this.FCustomerPipe.transform(
        this.customerList,
        this.searchName,
        "clientName"
      );
      const doc = new jsPDF("l");

      doc.setFontSize(18);
      doc.text("Customer Report", 14, 22);
      doc.autoTable({ html: "#my-table" });
      // Or use javascript directly:
      doc.autoTable({
        head: [
          [
            "Company Name",
            "Client Name",
            "Contact 1",
            "Contact 2",
            "Area",
            "City",
            "Email",
            "Website",
            "ST Reg",
          ],
        ],
        body: list.reduce((acc, cur) => {
          acc.push([
            cur.companyName ? cur.companyName : "",
            cur.clientName ? cur.clientName : "",
            cur.address ? cur.address : "",
            cur.contact1.toString() ? cur.contact1.toString() : "",
            cur.contact2.toString() ? cur.contact2.toString() : "",
            cur.area ? cur.area : "",
            cur.city ? cur.city : "",
            cur.email ? cur.email : "",
            cur.website ? cur.website : "",
            cur.stRegisteration ? cur.stRegisteration : "",
          ]);
          return acc;
        }, []),
        startY: 30,
        showHead: "firstPage",
        styles: { overflow: "ellipsize", cellWidth: "wrap" },
        columnStyles: {
          2: {
            columnWidth: "auto",
          },
          3: {
            columnWidth: "auto",
          },
        },
      });
      let date = new Date().toDateString();
      doc.save(`customer-report-${date}.pdf`);
    }
  }

  click(e: number): void {
    this.CS.getCustomersFromBack().subscribe(
      (result) => {
        this.customerList = result;
      },
      (err) => {
        this.errMsg = err.error.message;
        this.toastr.error(err.error.message, "Error!");
      }
    );
  }
}
