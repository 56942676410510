import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-add-color',
  templateUrl: './add-color.component.html',
  styleUrls: ['./add-color.component.scss']
})
export class AddColorComponent implements OnInit {
  
  @ViewChild('colorInput',{static: false}) colorInput: ElementRef;
  public modelList = ["abc", "ijk", "lmn", "pqr", "xyz"];
  public colorList = ["abc", "advadvad", "lmn", "pqr", "xyz"];

  constructor() { }

  ngOnInit() {
  }
  deleteColor(index) {
    this.colorList.splice(index, 1);
  }
  addColor() {
    if(this.colorInput.nativeElement.value != '')
    {
      this.colorList.push(this.colorInput.nativeElement.value);
      this.colorInput.nativeElement.value = '';
    }
  }
}

