import { Component, OnInit, Input } from '@angular/core';
import { SaleInvoiceService } from './../../services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import * as jsPDF from 'jspdf';
import { DatePipe } from '@angular/common'
import { ToastrService } from 'ngx-toastr';
import { NumberPipe } from './../../pipe/index';

@Component({
  selector: 'app-sale-invoice-modal',
  templateUrl: './sale-invoice-modal.component.html',
  styleUrls: ['./sale-invoice-modal.component.scss']
})
export class SaleInvoiceModalComponent implements OnInit {

  @Input() invoiceId: any;
  public invoice: any;
  public invoiceDetails = [];

  constructor(
    private router: Router,
    public modal: NgbActiveModal,
    public SIS: SaleInvoiceService,
    public datepipe: DatePipe,
    public numberPipe: NumberPipe,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    if (this.invoiceId) {
      this.SIS.getInvoiceWithInvoiceDetails(this.invoiceId).subscribe(result => {
        this.invoice = result;
        this.invoiceDetails = result.invoiceDetails;
      }, err => {
        this.toastr.error(err.error.message, 'Error!');
      });
    }
  }

  returnInvoice(invoiceId) {
    this.router.navigateByUrl(`/dashboard/add-return-invoice/${invoiceId}`)
    this.modal.dismiss('')
  }

  captureScreen() {
    let headArray = [
      'No.',
      'Product',
      'Brand\nName',
      'Model\nNumber',
      'Color',
      'Price',
      'Piece\nQuantity',
      'Total\nPrice',
      'Discount\n(%)',
      'Net\nAmount'
    ];

    let returnQty = this.invoiceDetails.reduce((acc, cur) => { return acc + cur.returnQty }, 0);
    let totalAmmount = 0;
    let totalQuantity = 0;
    let netAmmountAfterDiscount = 0;
    if (returnQty) headArray.splice(7, 0, 'Return\nQty');
    const doc = new jsPDF("p", "mm", "a4");
    doc.setFontSize(18)
    doc.text('SALE INVOICE', 14, 22)
    doc.setFontSize(12)
    doc.setTextColor(100)
    doc.text(`Customer :`, 14, 30)
    doc.setTextColor(80)
    doc.text(`${this.invoice.invoice.companyName}`, 50, 30)
    doc.setTextColor(100)
    doc.text(`Invoice No : `, 120, 30)
    doc.setTextColor(80)
    doc.text(`${this.invoice.invoice.invoiceNo}`, 155, 30)
    doc.setTextColor(100)
    doc.text(`Manual Book No : `, 14, 40)
    doc.setTextColor(80)
    doc.text(`${this.invoice.invoice.manualBookNo}`, 50, 40)
    doc.setTextColor(100)
    doc.text(`Order Date : `, 120, 40)
    doc.setTextColor(80)
    doc.text(`${this.datepipe.transform(this.invoice.invoice.createdAt)}`, 155, 40)
    doc.autoTable({
      head: [headArray],
      body: this.invoiceDetails.reduce((acc, cur, currentIndex) => {

        let totalCost = cur.rate * (cur.pieceQty - cur.returnQty);
        let afterDiscount = Math.round(totalCost - (totalCost * (cur.discount / 100)));

        totalQuantity += cur.pieceQty - cur.returnQty;
        totalAmmount += totalCost;
        netAmmountAfterDiscount += afterDiscount;

        returnQty ?
          acc.push([
            currentIndex + 1,
            cur.itemId.name,
            cur.brandId.brandName,
            cur.modelNumber,
            cur.color,
            this.numberPipe.transform(cur.rate),
            this.numberPipe.transform(cur.pieceQty),
            this.numberPipe.transform(cur.returnQty),
            this.numberPipe.transform(totalCost),
            this.numberPipe.transform(cur.discount),
            this.numberPipe.transform(afterDiscount)
          ])
          :
          acc.push([
            currentIndex + 1,
            cur.itemId.name,
            cur.brandId.brandName,
            cur.modelNumber,
            cur.color,
            this.numberPipe.transform(cur.rate),
            this.numberPipe.transform(cur.pieceQty),
            this.numberPipe.transform(cur.totalCost),
            this.numberPipe.transform(cur.discount),
            this.numberPipe.transform(cur.afterDiscount)
          ])
        return acc
      }, []),
      startY: 50,
      showHead: 'firstPage',
      styles: { overflow: 'ellipsize', cellWidth: 'wrap' },
      columnStyles: { text: { cellWidth: 'auto' } }
    })

    let startY = doc.autoTable.previous.finalY;

    if (parseInt(doc.autoTable.previous.finalY) > 250) {
      doc.addPage();
      startY = 14;
    }

    if (!returnQty) {
      doc.setTextColor(100)
      doc.text(`Total Quantity : `, 115, startY + 10)
      doc.setTextColor(80)
      doc.text(`${this.numberPipe.transform(this.invoice.invoice.totalQty)}`, 175, startY + 10)
      doc.setTextColor(100)
      doc.text(`Total Amount : `, 115, startY + 20)
      doc.setTextColor(80)
      doc.text(`${this.numberPipe.transform(this.invoice.invoice.totalAmmount)}`, 175, startY + 20)
      doc.setTextColor(100)
      doc.text(`Total Discount : `, 115, startY + 30)
      doc.setTextColor(80)
      doc.text(`${this.numberPipe.transform((this.invoice.invoice.totalAmmount - this.invoice.invoice.totalNetCost))}`, 175, startY + 30);
      doc.setTextColor(100)
      doc.text(`Net Amount After Discount : `, 115, startY + 40)
      doc.setTextColor(80)
    } else {
      doc.setTextColor(100)
      doc.text(`Total Quantity : `, 115, startY + 10)
      doc.setTextColor(80)
      doc.text(`${this.numberPipe.transform(totalQuantity)}`, 175, startY + 10)
      doc.setTextColor(100)
      doc.text(`Total Amount : `, 115, startY + 20)
      doc.setTextColor(80)
      doc.text(`${this.numberPipe.transform(totalAmmount)}`, 175, startY + 20)
      doc.setTextColor(100)
      doc.text(`Total Discount : `, 115, startY + 30)
      doc.setTextColor(80)
      doc.text(`${this.numberPipe.transform((totalAmmount - netAmmountAfterDiscount))}`, 175, startY + 30)
      doc.setTextColor(100)
      doc.text(`Net Amount After Discount : `, 115, startY + 40)
      doc.setTextColor(80)
    }
    doc.text(`${this.numberPipe.transform(netAmmountAfterDiscount)}`, 175, startY + 40)
    doc.save(`${this.invoice.invoice.companyName}.pdf`);
  }

}
