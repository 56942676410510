import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fActualQty'
})
export class FActualQtyPipe implements PipeTransform {

  transform(value: any, actualQty: string, propName: string): any {
    return actualQty ? value.filter((brand) => {
      if (actualQty)
        return brand.actualQty > 0
      else
        return brand.actualQty > -1
    }) : value;
  }

}
