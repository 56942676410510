import { ItemsContainerComponent } from './items-container/items-container.component'
import { BrandsContainerComponent } from './brands-container/brands-container.component'
import { AdditemContainerComponent } from './additem-container/additem-container.component'
import { AddStockContainerComponent } from './add-stock-container/add-stock-container.component'
import { SaleInvoiceContainerComponent } from './sale-invoice-container/sale-invoice-container.component'
import { BankLedgerContainerComponent } from './bank-ledger-container/bank-ledger-container.component'

export const Containers: any[] = [
    ItemsContainerComponent,
    BrandsContainerComponent,
    AdditemContainerComponent,
    AddStockContainerComponent,
    SaleInvoiceContainerComponent,
    BankLedgerContainerComponent,
]

export {
    ItemsContainerComponent,
    BrandsContainerComponent,
    AdditemContainerComponent,
    AddStockContainerComponent,
    SaleInvoiceContainerComponent,
    BankLedgerContainerComponent,
}
