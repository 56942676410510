import { Injectable } from '@angular/core';
import { HttpService } from './../http/http.service';
import { environment } from './../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PettyCashService {

  public pettyCash;

  constructor(private http: HttpService) { }

  public addPettyCash(obj) {
    return this.http.post(`${environment.baseUrl}/api/pettyCash/?token=${localStorage.getItem('STUID')}`, obj);
  }

  getPettyCashDetail(id) {
    return this.http.get(`${environment.baseUrl}/api/pettyCash/${id}?token=${localStorage.getItem('STUID')}`)
  }

  public getPettyCashId(){
    return this.http.get(`${environment.baseUrl}/api/pettyCash/getPettyCashTransactionId?token=${localStorage.getItem('STUID')}`);
  }

  public updatePettyCash(obj) {
    return this.http.put(`${environment.baseUrl}/api/pettyCash/?token=${localStorage.getItem('STUID')}`, obj);
  }

  public deletePettyCash(id) {
    return this.http.delete(`${environment.baseUrl}/api/pettyCash/${id}?token=${localStorage.getItem('STUID')}`)
  }

  public getPettyCash() {
    return this.http.get(`${environment.baseUrl}/api/pettyCash/?token=${localStorage.getItem('STUID')}`).pipe(map((response: any) => {
      this.pettyCash = response;
      return response;
    }),
      catchError(err => {
        throw err;
      })
    );
  }

}
