import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {

  transform(value: any, args: any): any {
    let date = new Date(value);

    return  `${new Date(value).toDateString()} ${new Date(value).toLocaleTimeString()}`;
  }

}
