import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'fItemName'
})
export class FItemNamePipe implements PipeTransform {
  transform (value: any, itemName: string, arg: string): any {
    if (arg == 'stockReportTwo') {
      return itemName
        ? value.filter(item => {
            return (
              item.itemName
                .toLocaleLowerCase()
                .indexOf(itemName.toLocaleLowerCase()) !== -1
            )
          })
        : value
    } else {
      return itemName
        ? value.filter(item => {
            return (
              item.itemId.name
                .toLocaleLowerCase()
                .indexOf(itemName.toLocaleLowerCase()) !== -1
            )
          })
        : value
    }
  }
}
