
import { FActualPiecePipe } from './fActualPiece/f-actual-piece.pipe'
import { FActualQtyPipe } from './fActualQty/f-actual-qty.pipe';
import { SearchByIdPipe } from './searchById/search-by-id.pipe';
import { SearchPipe } from './search/search.pipe';
import { FToDatePipe } from './fToDate/f-to-date.pipe';
import { FStockReportPipe } from './fStockReport/f-stock-report.pipe';
import { FItemNamePipe } from './fItemName/f-item-name.pipe';
import { FPaymentPipe } from './fPayment/f-payment.pipe';
import { FFromDatePipe } from './fFromDate/f-from-date.pipe';
import { SearchBrandPipe } from './searchBrand/search-brand.pipe';
import { FPdfPipe } from './fPdf/f-pdf.pipe';
import { FPettyCashPipe } from './fPettyCash/f-petty-cash.pipe';
import { FModelNoPipe } from './fModelNo/f-model-no.pipe';
import { FInvoicePipe } from './fInvoice/f-invoice.pipe';
import { FCustomerPipe } from './fCustomer/f-customer.pipe';
import { FColorPipe } from './fColor/f-color.pipe';
import { FBrandNamePipe } from './fBrandName/f-brand-name.pipe';
import { TransactionPipe } from './transaction/transaction.pipe';
import { ModelwisePipe } from './fModalwise/modelwise.pipe';
import { PostedPipe } from './posted/posted.pipe';
import { NumberPipe } from './number/number.pipe';
import { BankNamePipe } from './bankName/bank-name.pipe';
import { DateTimePipe } from './dateTime/date-time.pipe';
import { SubStringPipe } from './subString/sub-string.pipe';
import { SaleInvoicePipe } from './saleInvoice/sale-invoice.pipe';
import { SortingPipe } from './sorting/sorting.pipe';

export const Pipes: any[] = [
  FActualPiecePipe,
  FActualQtyPipe,
  FBrandNamePipe,
  FColorPipe,
  FCustomerPipe,
  FInvoicePipe,
  FFromDatePipe,
  FItemNamePipe,
  FModelNoPipe,
  FPaymentPipe,
  FPettyCashPipe,
  FStockReportPipe,
  FPdfPipe,
  FToDatePipe,
  SearchBrandPipe,
  SearchPipe,
  SearchByIdPipe,
  TransactionPipe,
  ModelwisePipe,
  PostedPipe,
  NumberPipe,
  BankNamePipe,
  DateTimePipe,
  SubStringPipe,
  SaleInvoicePipe,
  SortingPipe
];

export {
  FActualPiecePipe,
  FActualQtyPipe,
  FBrandNamePipe,
  FColorPipe,
  FCustomerPipe,
  FInvoicePipe,
  FFromDatePipe,
  FItemNamePipe,
  FModelNoPipe,
  FPaymentPipe,
  FPettyCashPipe,
  FStockReportPipe,
  FPdfPipe,
  FToDatePipe,
  SearchBrandPipe,
  SearchPipe,
  SearchByIdPipe,
  TransactionPipe,
  ModelwisePipe,
  PostedPipe,
  NumberPipe,
  BankNamePipe,
  DateTimePipe,
  SubStringPipe,
  SaleInvoicePipe,
  SortingPipe
};
