import { DashboardComponent } from './dashboard/dashboard.component'
import { ItemsComponent } from './items/items.component'
import { BrandsComponent } from './brands/brands.component'
import { ModelsComponent } from './models/models.component'
import { ColorsComponent } from './colors/colors.component'
import { AddBrandComponent } from './add-brand/add-brand.component'
import { AdditemComponent } from './additem/additem.component'
import { AddModelComponent } from './add-model/add-model.component'
import { AddColorComponent } from './add-color/add-color.component'
import { AddCustomerComponent } from './add-customer/add-customer.component'
import { AddBankComponent } from './add-bank/add-bank.component'
import { AddPettyComponent } from './add-petty/add-petty.component'
import { AddStockComponent } from './add-stock/add-stock.component'
import { BankComponent } from './bank/bank.component'
import { BankLedgerComponent } from './bank-ledger/bank-ledger.component'
import { PettyCashComponent } from './petty-cash/petty-cash.component'
import { RecieveMoneyComponent } from './recieve-money/recieve-money.component'
import { SaleInvoiceComponent } from './sale-invoice/sale-invoice.component'
import { ShowDamageComponent } from './show-damage/show-damage.component'
import { SidebarComponent } from './sidebar/sidebar.component'
import { StockComponent } from './stock/stock.component'
import { StockReport2Component } from './stock-report2/stock-report2.component'
import { StockSummaryComponent } from './stock-summary/stock-summary.component'
import { UploadPdfComponent } from './upload-pdf/upload-pdf.component'
import { ModelColorComponent } from './model-color/model-color.component';
import { AddModalComponent } from './add-modal/add-modal.component';
import { StockDetailModalComponent } from './stock-detail-modal/stock-detail-modal.component'
import { ReturnInvoiceComponent } from './return-invoice/return-invoice.component'
import { RecieveMoneyReportComponent } from './recieve-money-report/recieve-money-report.component'
import { LedgerReportComponent } from './ledger-report/ledger-report.component'
import { PdfListingComponent } from './pdf-listing/pdf-listing.component'
import { CustomerComponent } from './customer/customer.component'
import { AddReturnInvoiceComponent } from './add-return-invoice/add-return-invoice.component'
import { SaleInvoiceListingComponent } from './sale-invoice-listing/sale-invoice-listing.component'
import { SignInComponent } from './sign-in/sign-in.component'
import { UsersComponent } from './users/users.component'
import { UserRoleComponent } from './user-role/user-role.component'
import { AddUserRoleComponent } from './add-user-role/add-user-role.component'
import { HistoryComponent } from './history/history.component';
import { ReturnInvoiceModalComponent } from './return-invoice-modal/return-invoice-modal.component';
import { InvoiceModalComponent } from './invoice-modal/invoice-modal.component';
import { SaleInvoiceModalComponent } from './sale-invoice-modal/sale-invoice-modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { DashboardHomeComponent } from './dashboard-home/dashboard-home.component';

export const Components: any[] = [
  DashboardComponent,
  ItemsComponent,
  BrandsComponent,
  ModelsComponent,
  ColorsComponent,
  AddBrandComponent,
  AdditemComponent,
  AddModelComponent,
  AddColorComponent,
  AddBankComponent,
  AddCustomerComponent,
  AddPettyComponent,
  AddStockComponent,
  BankComponent,
  BankLedgerComponent,
  PettyCashComponent,
  RecieveMoneyComponent,
  SaleInvoiceComponent,
  ShowDamageComponent,
  SidebarComponent,
  StockComponent,
  StockReport2Component,
  StockSummaryComponent,
  UploadPdfComponent,
  ModelColorComponent,
  AddModalComponent,
  StockDetailModalComponent,
  ReturnInvoiceComponent,
  RecieveMoneyReportComponent,
  LedgerReportComponent,
  PdfListingComponent,
  CustomerComponent,
  AddReturnInvoiceComponent,
  SaleInvoiceListingComponent,
  SignInComponent,
  UsersComponent,
  UserRoleComponent,
  AddUserRoleComponent,
  HistoryComponent,
  ReturnInvoiceModalComponent,
  InvoiceModalComponent,
  SaleInvoiceModalComponent,
  ConfirmModalComponent,
  DashboardHomeComponent
]

export {
  DashboardComponent,
  ItemsComponent,
  BrandsComponent,
  ModelsComponent,
  ColorsComponent,
  AddBrandComponent,
  AdditemComponent,
  AddModelComponent,
  AddColorComponent,
  AddBankComponent,
  AddCustomerComponent,
  AddPettyComponent,
  AddStockComponent,
  BankComponent,
  BankLedgerComponent,
  PettyCashComponent,
  RecieveMoneyComponent,
  SaleInvoiceComponent,
  ShowDamageComponent,
  SidebarComponent,
  StockComponent,
  StockReport2Component,
  StockSummaryComponent,
  UploadPdfComponent,
  ModelColorComponent,
  AddModalComponent,
  StockDetailModalComponent,
  ReturnInvoiceComponent,
  RecieveMoneyReportComponent,
  LedgerReportComponent,
  PdfListingComponent,
  CustomerComponent,
  AddReturnInvoiceComponent,
  SaleInvoiceListingComponent,
  SignInComponent,
  UsersComponent,
  UserRoleComponent,
  AddUserRoleComponent,
  HistoryComponent,
  ReturnInvoiceModalComponent,
  InvoiceModalComponent,
  SaleInvoiceModalComponent,
  ConfirmModalComponent,
  DashboardHomeComponent
}
