import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StockService, ExcelService } from './../../services'
import { FItemNamePipe, FFromDatePipe, FToDatePipe } from './../../pipe/index';
import { ToastrService } from 'ngx-toastr';
import jsPDF from 'jspdf'
import 'jspdf-autotable'

@Component({
  selector: 'app-stock-summary',
  templateUrl: './stock-summary.component.html',
  styleUrls: ['./stock-summary.component.scss']
})
export class StockSummaryComponent implements OnInit {

  stockSummaryList = [];
  public errMsg: string = '';
  public fromDate: Date;
  public toDate: Date;
  public searchName: string = '';

  constructor(
    private SS: StockService,
    private router: Router,
    private ES: ExcelService,
    public itemNamePipe: FItemNamePipe,
    public fromDatePipe: FFromDatePipe,
    public toDatePipe: FToDatePipe,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.SS.getStockSummaryReport().subscribe(result => {
      this.stockSummaryList = result;
    },err=>{
      this.errMsg = err.error.message
      this.toastr.error(err.error.message,'Error!');
    });
  }
  createStock() {
    this.router.navigateByUrl('/dashboard/add-stock')
  }

  captureScreen() {
    if (this.stockSummaryList) {
      let list = this.itemNamePipe.transform(this.stockSummaryList, this.searchName, 'name');
      list = this.fromDatePipe.transform(list, this.fromDate, 'pettyCashList');
      list = this.toDatePipe.transform(list, this.toDate, 'pettyCashList');
      const doc = new jsPDF()

      doc.setFontSize(18)
      doc.text('Stock Details', 14, 22)
      doc.autoTable({ html: '#my-table' })
    // Or use javascript directly:
    doc.autoTable({
      head: [
        [
          'Product Name',
          'Brand Name',
          'quantity'
        ]
      ],
      body: list.reduce((acc, cur) => {
        acc.push([
          cur.itemId.name,
          cur.brandId.brandName,
          cur.actualQty.toString()
        ])
        return acc
      }, []),
      startY: 30,
      showHead: 'firstPage',
      styles: { overflow: 'ellipsize', cellWidth: 'wrap' },
      columnStyles: { text: { cellWidth: 'auto' } }
    })
      let date = new Date().toDateString()
      doc.save(`stock-detail-${date}.pdf`);
    }
  }

  clearAll() {
    this.searchName = '';
    this.fromDate = null;
    this.toDate = null;
  }
}
