import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "saleInvoice",
})
export class SaleInvoicePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let query = args[0];
    let type = args[1];
    switch (type) {
      case "manualBookNo":
        return query
          ? value.filter((obj) => {
              return obj.manualBookNo == query;
            })
          : value;
    }
  }
}
