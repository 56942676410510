import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StockService, ExcelService } from './../../services'
import { FItemNamePipe } from './../../pipe/index';
import { ToastrService } from 'ngx-toastr';
import {NumberPipe } from './../../pipe/index';

import jsPDF from 'jspdf'
import 'jspdf-autotable'

@Component({
  selector: 'app-stock-report2',
  templateUrl: './stock-report2.component.html',
  styleUrls: ['./stock-report2.component.scss']
})
export class StockReport2Component implements OnInit {
  itemList = [];
  public errMsg: string = '';
  public searchName: string = '';

  constructor(
    private SS: StockService,
    private router: Router,
    private ES: ExcelService,
    public FItemNamePipe: FItemNamePipe,
    public numberPipe:NumberPipe,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.SS.getStockSecondReport().subscribe(result => {
      this.itemList = result;
    },err=>{
      this.errMsg = err.error.message;
      this.toastr.error(err.error.message,'Error!');
    });
  }

  exportExcel() {
    let json = []
    this.itemList.map(obj => {
      let newObj = { "Item Name": obj.itemName, "Brand Name": obj.brandName, "Color": obj.color, "Model Number": obj.modelNumber, "Actual Quantity": obj.actualQty };
      json.push(newObj)
    })

    this.ES.exportAsExcelFile(json, 'stock summery');
  }

  createStock() {
    this.router.navigateByUrl('/dashboard/add-stock')
  }

  captureScreen() {
    if (this.itemList) {

      let list = this.FItemNamePipe.transform(this.itemList, this.searchName, 'stockReportTwo');
      const doc = new jsPDF()

      doc.setFontSize(18)
      doc.text('Stock Summery', 14, 22)
      doc.autoTable({ html: '#my-table' })
    // Or use javascript directly:
    doc.autoTable({
      head: [
        [
          'Product\nName',
          'Brand\nName',
          'Model\nNumber',
          'Color',
          'Unit Cost',
          'Actual qty'
        ]
      ],
      body: list.reduce((acc, cur) => {
        acc.push([
          cur.itemName,
          cur.brandName,
          cur.modelNumber,
          cur.color,
          this.numberPipe.transform(cur.unitCost),
          this.numberPipe.transform(cur.actualQty)
        ])
        return acc
      }, []),
      startY: 30,
      showHead: 'firstPage',
      styles: { overflow: 'ellipsize', cellWidth: 'wrap' },
      columnStyles: { text: { cellWidth: 'auto' } }
    })
    doc.save('stock-summery.pdf');
    }
  }

  clearAll() {
    this.searchName = '';
  }
}
