import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "searchBrand",
})
export class SearchBrandPipe implements PipeTransform {
  transform(value: any, searchName: string, propName: string): any {
    switch (propName) {
      case "brandList":
        return searchName
          ? value.filter((brand) => {
              return (
                brand.brandName
                  .toLocaleLowerCase()
                  .indexOf(searchName.toLocaleLowerCase()) !== -1 ||
                brand.itemId.name
                  .toLocaleLowerCase()
                  .indexOf(searchName.toLocaleLowerCase()) !== -1
              );
            })
          : value;
        break;

      case "brandDamage":
        return searchName
          ? value.filter((brand) => {
              return (
                brand.brandName
                  .toLocaleLowerCase()
                  .indexOf(searchName.toLocaleLowerCase()) !== -1
              );
            })
          : value;
        break;

      default:
        return value;
    }
  }
}
