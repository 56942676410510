import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bank-ledger-container',
  templateUrl: './bank-ledger-container.component.html',
  styleUrls: ['./bank-ledger-container.component.scss']
})
export class BankLedgerContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
