import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fActualPiece'
})
export class FActualPiecePipe implements PipeTransform {

  transform(value: any, actualPiece: string, propName: string): any {
    return actualPiece ? value.filter((brand) => { 
      return brand.actualPiece > 0 
    }) : value;
  }

}
