import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UploadPdfService } from './../../services';
import * as jsPDF from 'jspdf';
import { DatePipe } from '@angular/common';
import { FPdfPipe, FFromDatePipe, FToDatePipe } from './../../pipe/index';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-pdf-listing',
  templateUrl: './pdf-listing.component.html',
  styleUrls: ['./pdf-listing.component.scss']
})
export class PdfListingComponent implements OnInit {

  public fromDate: Date;
  public toDate: Date;
  public searchName: string = '';
  public errMsg: string;
  public isLoading: boolean = true;
  public pdfList = []
  public isAddPdfAccess: boolean = false;

  constructor(
    private router: Router,
    private UPS: UploadPdfService,
    public datepipe: DatePipe,
    public FPdfPipe: FPdfPipe,
    public fromDatePipe:FFromDatePipe,
    public toDatePipe:FToDatePipe,
    private toastr: ToastrService
  ) { }

  ngOnInit() {

    let user = JSON.parse(localStorage.getItem('STU'));
    this.isAddPdfAccess = user.uploadPdf

    this.UPS.getUploadPdf().subscribe(result => {
      this.pdfList = result;
      this.isLoading = false;
    },err=>{
      this.isLoading = false;
      this.errMsg = err.error.message;
      this.toastr.error(err.error.message,'Error!');
    });
  }

  downloadPdf(pdfObj) {
    window.open(pdfObj.pdfUrl, '_blank');
  }

  UploadPdf() {
    this.router.navigateByUrl('/dashboard/add-pdf')
  }

  clearAll() {
    this.searchName = '';
    this.fromDate = null;
    this.toDate = null;
  }

  generatePdf() {
    if (this.pdfList) {
      let list = this.FPdfPipe.transform(this.pdfList, this.searchName, 'pdfName');
      list = this.fromDatePipe.transform(list, this.fromDate, 'pdfList');
      list = this.toDatePipe.transform(list, this.toDate, 'pdfList');
      const doc = new jsPDF();

      doc.setFontSize(18)
      doc.text('PDF Listing report', 14, 22)
      doc.autoTable({ html: '#my-table' })
    // Or use javascript directly:
    doc.autoTable({
      head: [
        [
          'PDF Date',
          'PDF Name'
        ]
      ],
      body: list.reduce((acc, cur) => {
        let date = this.datepipe.transform(cur.createdAt, 'yyyy-MM-dd');
        acc.push([
          date,
          cur.pdfName
        ])
        return acc
      }, []),
      startY: 30,
      showHead: 'firstPage',
      styles: { overflow: 'ellipsize', cellWidth: 'wrap' },
      columnStyles: { text: { cellWidth: 'auto' } }
    })
      let date = new Date().toDateString()
      doc.save(`pdflisting-report-${date}.pdf`);
    }
  }

}
