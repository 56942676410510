import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "bankName",
})
export class BankNamePipe implements PipeTransform {
  transform(value: any, arg: any): any {
    return arg
      ? value.filter((list) => {
          return list
            ? list.bankName
              ? list.bankName
                  .toLocaleLowerCase()
                  .indexOf(arg.toLocaleLowerCase()) !== -1
              : value
            : value;
        })
      : value;
  }
}
