import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'modelwise'
})
export class ModelwisePipe implements PipeTransform {

  transform(value: any, customerName: string, propName: string): any {
    return customerName ? value.filter((customer) => {
      return customer.companyName.toLocaleLowerCase().indexOf(customerName.toLocaleLowerCase()) !== -1 }) : value;
  }

}
