import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ItemList } from '../../store/interface/item';
import { BrandsService } from './../../services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-brand',
  templateUrl: './add-brand.component.html',
  styleUrls: ['./add-brand.component.scss']
})
export class AddBrandComponent implements OnInit {

  public brandList = [];
  public itemValidator: boolean;
  public brandValidator: boolean;
  public show: boolean;
  public itemList: ItemList[];
  public brandName: string = '';
  public itemName: string = '';
  public item: any;
  public brandListObj:any = [];

  @Input() list: ItemList[];
  @Input() brand_list:any = [];
  @Input() editBrand: any;
  @Input() idEdit: boolean;

  constructor(public modal: NgbActiveModal,
     private BS: BrandsService,
     private toastr: ToastrService
     ) { }

  ngOnInit() {
    this.show = false;
    if (this.idEdit && this.editBrand) {
      this.brandName = this.editBrand.brandName;
      this.itemName = this.editBrand.itemId.name;
    }
  }

  addBrand() {
    if (!this.editBrand) {
      let brandcheck = this.brand_list.find(x => (x.brandName === this.brandName && x.itemId.name === this.itemName));
      if (!brandcheck) {
        if (this.brandList.find(brand => brand == this.brandName)) {
          return
        }
        this.item = this.list.find(x => x.name === this.itemName);
        if (this.brandName != '' && this.item != undefined) {
          this.brandList.push(this.brandName);
          this.brandName = '';
        }
      }
      else {
        this.toastr.warning('Record already exist','Warning!');
      }
    }
  }

  onItemFocusOut() {
    this.itemValidator = true;

    if (this.itemName) {
      this.itemValidator = false;
    }
  }

  onBrandFocusOut() {
    this.brandValidator = true;
    if (this.brandName) {
      this.brandValidator = false;
    }
  }

  deleteBrand(index) {
    this.brandList.splice(index, 1);
  }

  submit() {
    if (this.item != undefined && this.editBrand == undefined) {
      let history = [];
      for (var i = 0; i < this.brandList.length; ++i) {
        this.brandListObj.push({ 'itemId': this.item._id, 'brandName': this.brandList[i] });
        history.push({ objkey: 'Brand Name', newValue: this.brandList[i] })
      }
      this.modal.close({history:JSON.stringify(history),brands:this.brandListObj});
    }
  }

  update() {
    let brand = this.brand_list.find(x => (x.brandName === this.brandName && x.itemId.name === this.itemName));
    this.item = this.list.find(x => x.name === this.itemName);
    this.editBrand.itemId._id = this.item._id;
    this.editBrand.itemId.name = this.item.name;

    if (!brand) {
      this.editBrand['history'] = JSON.stringify([{
        objkey: 'Brand Name',
        oldValue: this.editBrand.brandName,
        newValue: this.brandName
      }]);
      this.editBrand.brandName = this.brandName;
      this.BS.updateBrand(this.editBrand).subscribe(result => {
        this.modal.close();
      },err=>{
        this.toastr.error(err.error.message,'Error!');
      })
    }
    else {
      this.toastr.warning('Record already exist','Warning!');
    }
  }
}
