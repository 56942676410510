import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "searchById",
})
export class SearchByIdPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let type = args[1];
    let id = args[0];
    switch (type) {
      case "customer":
        return id
          ? value.filter((item) => {
              return item.customerId.indexOf(id) !== -1;
            })
          : value;
        break;
      default:
        return value;
    }
  }
}
