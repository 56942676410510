import { Component, OnInit } from "@angular/core";
import { SaleInvoiceService } from "./../../services";
import * as jsPDF from "jspdf";
import { DatePipe } from "@angular/common";
import {
  FModelNoPipe,
  FColorPipe,
  ModelwisePipe,
  NumberPipe,
} from "./../../pipe/index";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-model-color",
  templateUrl: "./model-color.component.html",
  styleUrls: ["./model-color.component.scss"],
})
export class ModelColorComponent implements OnInit {
  public modelColorList = [];
  public errMsg: string;
  public isLoading: boolean = true;
  public netCost: number = 0;
  public netAmount: number = 0;
  public netProfit: number = 0;
  public customerName: string;
  public color: string;
  public modelNo: string;

  constructor(
    private SIS: SaleInvoiceService,
    public datepipe: DatePipe,
    public ModelwisePipe: ModelwisePipe,
    public FModelNoPipe: FModelNoPipe,
    public FColorPipe: FColorPipe,
    private toastr: ToastrService,
    public numberPipe: NumberPipe
  ) {}

  ngOnInit() {
    this.SIS.getModelColorWiseSale().subscribe(
      (result) => {
        this.modelColorList = result;
        this.getSummries();
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        this.errMsg = err.error.message;
        this.toastr.error(err.error.message, "Error!");
      }
    );
  }

  getSummries() {
    let list = this.FColorPipe.transform(this.modelColorList, this.color);
    list = this.FModelNoPipe.transform(list, this.modelNo);
    list = this.ModelwisePipe.transform(
      list,
      this.customerName,
      "customerName"
    );

    this.netCost = list.reduce((acc, cu) => {
      return acc + cu.avgCost * (cu.pieceQty - cu.returnQty);
    }, 0);
    this.netAmount = list.reduce((acc, cu) => {
      return acc + cu.afterDiscount;
    }, 0);
    this.netProfit = this.netAmount - this.netCost;
  }

  captureScreen() {
    if (this.modelColorList) {
      let list = this.FColorPipe.transform(this.modelColorList, this.color);
      list = this.FModelNoPipe.transform(list, this.modelNo);
      list = this.ModelwisePipe.transform(
        list,
        this.customerName,
        "customerName"
      );
      const doc = new jsPDF("l");

      doc.setFontSize(18);
      doc.text("Model color wise list", 14, 22);
      doc.autoTable({ html: "#my-table" });
      // Or use javascript directly:
      doc.autoTable({
        head: [
          [
            "S No",
            "Date",
            "Invoice No",
            "Customer\nName",
            "Item Name",
            "Brand Name",
            "Model\nNumber",
            "Color",
            "Price",
            "Total\nQty",
            "Total\nCost",
            "Discount",
            "After\nDiscount",
          ],
        ],
        body: list.reduce((acc, cur, currentIndex) => {
          let date = this.datepipe.transform(cur.date, "yyyy-MM-dd");
          acc.push([
            currentIndex + 1,
            date,
            cur.invoiceNo,
            cur.companyName,
            cur.itemName,
            cur.brandName,
            cur.modelNumber,
            cur.color,
            this.numberPipe.transform(cur.rate),
            this.numberPipe.transform(cur.pieceQty),
            this.numberPipe.transform(cur.totalCost),
            this.numberPipe.transform(cur.discount),
            this.numberPipe.transform(cur.afterDiscount),
          ]);
          return acc;
        }, []),
        startY: 30,
        showHead: "firstPage",
        styles: { overflow: "ellipsize", cellWidth: "wrap" },
        columnStyles: { text: { cellWidth: "auto" } },
      });
      let date = new Date().toDateString();
      doc.save(`modelcolorwise-${date}.pdf`);
    }
  }
}
