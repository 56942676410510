import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { StockDetailModalComponent } from '../stock-detail-modal/stock-detail-modal.component'
import { StockService, ItemService, BrandsService, ExcelService } from './../../services';
import * as jsPDF from 'jspdf';
import { DatePipe } from '@angular/common';

import {
  FFromDatePipe,
  FToDatePipe,
  FBrandNamePipe,
  FModelNoPipe,
  FColorPipe,
  NumberPipe,
  FItemNamePipe
} from './../../pipe/index'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss']
})

export class StockComponent implements OnInit {

  private addBrandRef: NgbModalRef;
  public stockList: [];
  public itemList: [];
  public brandList = [];
  public checkBoxInfo: any;
  public showAll_cb: boolean;
  public errMsg: string = '';
  public itemName: string = '';
  public brandName: string = '';
  public color: string = '';
  public modelNo: string = '';
  public fromDate: Date;
  public toDate: Date;
  public actualQty = 0;
  public stockDataList;
  public isCreateStockAccess: boolean = false;
  public totalQty;
  public totalDamageQty;
  public netQty;
  public totalNetCost;

  constructor(
    private router: Router,
    private SS: StockService,
    private _stockModal: NgbModal,
    private IS: ItemService,
    private BS: BrandsService,
    private ES: ExcelService,
    public datepipe: DatePipe,
    public fromDatePipe: FFromDatePipe,
    public toDatePipe: FToDatePipe,
    public ModelNoPipe: FModelNoPipe,
    public ColorPipe: FColorPipe,
    public ItemNamePipe: FItemNamePipe,
    public BrandNamePipe: FBrandNamePipe,
    public numberPipe:NumberPipe,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem('STU'));
    this.isCreateStockAccess = user.addStock
    this.SS.getStocksFromBack().subscribe((result: any) => {
      this.stockDataList = result;
      this.getSummeryDetails(result);
    },err=>{
      this.errMsg = err.error.message ? err.error.message : 'internal server error' ;
      this.toastr.error(err.error.message ? err.error.message : 'internal server error','Error!');
    });

    this.IS.getItemsFromBack().subscribe(result => {
      this.itemList = result;
    },err=>{
      this.toastr.error(err.error.message,'Error!');
    });
    if (!this.BS.getBrands()) {
      this.BS.getBrandsFromBack().subscribe(result => {
        this.brandList = result
      },err=>{
        this.toastr.error(err.error.message,'Error!');
      });
    } else {
      this.brandList = this.BS.getBrands();
    }
  }

  getSummeryDetails(list) {
    this.totalQty = list.reduce((ac, cu) => { return ac + cu.initialQty }, 0);
    this.totalDamageQty = list.reduce((ac, cu) => { return ac + cu.damageQty }, 0);
    this.netQty = list.reduce((ac, cu) => { return ac + cu.actualQty }, 0);
    this.totalNetCost = list.reduce((ac, cu) => { return ac + (cu.unitCost * cu.actualQty) }, 0);
  }

  filterChange(val, type) {

    let filteredArray;
    let itemPipe = new FItemNamePipe();
    let brandPipe = new FBrandNamePipe();
    let fromDatePipe = new FFromDatePipe();
    let toDatePipe = new FToDatePipe();
    let colorPipe = new FColorPipe();
    let modelNoPipe = new FModelNoPipe();

    filteredArray = itemPipe.transform(this.stockDataList, this.itemName, 'stockSummery');
    filteredArray = brandPipe.transform(filteredArray, this.brandName);
    filteredArray = fromDatePipe.transform(filteredArray, this.fromDate, 'stockDataList');
    filteredArray = toDatePipe.transform(filteredArray, this.toDate, 'stockDataList');
    filteredArray = colorPipe.transform(filteredArray, this.color, );
    filteredArray = modelNoPipe.transform(filteredArray, this.modelNo);
    this.getSummeryDetails(filteredArray);
  }

  viewDetails(id) {
    this.addBrandRef = this._stockModal.open(StockDetailModalComponent, { size: 'lg' });
    this.addBrandRef.componentInstance.stockId = id;
    this.addBrandRef.result.then((result) => {
    }, (reason) => { })
  }

  createModel() {
    this.router.navigateByUrl('/dashboard/add-stock')
  }

  onShowAllCbox(e) {
    this.checkBoxInfo = e;
    if (e) {
      this.itemName = '';
      this.brandName = '';
      this.color = '';
      this.modelNo = '';
      this.fromDate = null;
      this.toDate = null;
      this.actualQty = 0
    }
    this.getSummeryDetails(this.stockDataList);
  }

  onShowRemainCbox(e) {
    if (e.target.checked) {
      this.checkBoxInfo.target.checked = false;
    }
  }

  editStock(stock) {
    this.router.navigateByUrl(`/dashboard/edit-stock/${stock.stock._id}`)
  }

  exportExcel() {
    let tempStockDetails = [];
    let list = this.ItemNamePipe.transform(this.stockDataList, this.itemName, 'stockSummery');
      list = this.fromDatePipe.transform(list, this.fromDate, 'stockDataList');
      list = this.toDatePipe.transform(list, this.toDate, 'stockDataList');
      list = this.BrandNamePipe.transform(list, this.brandName);
      list = this.ModelNoPipe.transform(list,this.modelNo);
      list = this.ColorPipe.transform(list,this.color);
    Promise.all(
      list.map(detail => {
        let obj = {
          "PRODUCTS": detail.itemId.name,
          "BRANDS": detail.brandId.brandName,
          "MODEL NUMBER": detail.modelNumber,
          "COLOR": detail.color,
          "SOLD QUANTITY": detail.soldQty,
          "ACTUAL QUANTITY": detail.actualQty
        }
        tempStockDetails.push(obj);
      })
    )
    this.ES.exportAsExcelFile(tempStockDetails, `stock-details-${new Date().toDateString()}`)
  }

  captureScreen() {
    if (this.stockDataList) {
      let list = this.ItemNamePipe.transform(this.stockDataList, this.itemName, 'stockSummery');
      list = this.fromDatePipe.transform(list, this.fromDate, 'stockDataList');
      list = this.toDatePipe.transform(list, this.toDate, 'stockDataList');
      list = this.BrandNamePipe.transform(list, this.brandName);
      list = this.ModelNoPipe.transform(list,this.modelNo);
      list = this.ColorPipe.transform(list,this.color);
      const doc = new jsPDF('l')
      doc.setFontSize(18)
      doc.text('Stock Report', 14, 22)
      doc.setFontSize(12)
      doc.setTextColor(100)
      doc.text(`Total Quantity :  ${this.numberPipe.transform(this.totalQty)}`, 14, 30)
      doc.text(`Total Damage Quantity :${this.numberPipe.transform(this.totalDamageQty)}`, 70, 30)
      doc.text(`Net Quantity : ${this.numberPipe.transform(this.netQty)}`, 140, 30)
      doc.text(`Total Net Cost : ${this.numberPipe.transform(this.totalNetCost)}`, 200, 30)
    doc.autoTable({
      head: [
        [
          'Created on',
          'Stock id',
          'Item name',
          'Brand name',
          'Model no',
          'Color',
          'Sold Qty',
          'Cost Price',
          'Actual Piece'
        ]
      ],
      body: list.reduce((acc, cur) => {
        acc.push([
          this.datepipe.transform(cur.date, 'yyyy-MM-dd'),
          cur.stock.stockId,
          cur.itemId.name,
          cur.brandId.brandName,
          cur.modelNumber,
          cur.color,
          this.numberPipe.transform(cur.soldQty),
          this.numberPipe.transform(cur.unitCost),
          this.numberPipe.transform(cur.actualQty)
        ])
        return acc
      }, []),
      startY: 35,
      showHead: 'firstPage',
      styles: { overflow: 'ellipsize', cellWidth: 'wrap' },
      columnStyles: { text: { cellWidth: 'auto' } }
    })
      let date = new Date().toDateString()
      doc.save(`stock-report-${date}.pdf`);
    }
  }

}
