import { Injectable } from '@angular/core';
import { HttpService } from './../http/http.service';
import { environment } from './../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BrandsService {

  brands: any;
  brandsWithItems:any;
  constructor(private http: HttpService) { }

  public getBrands(){
    return this.brands;
  }

  public getBrandsWithItems(){
    return this.brandsWithItems;
  }

  public getBrandsWithItemsFromBack(){
    return this.http.get(`${environment.baseUrl}/api/brand/getBrandsWithItems?token=${localStorage.getItem('STUID')}`).pipe(map((response: any) => {
      this.brandsWithItems = response;
      return response;
    }),
      catchError(err => {
        return err;
      })
    );
  }


  public getBrandsFromBack() {
    return this.http.get(`${environment.baseUrl}/api/brand/?token=${localStorage.getItem('STUID')}`)
  }

  public updateBrand(obj){
    obj['token'] = localStorage.getItem('STUID');
    return this.http.put(`${environment.baseUrl}/api/brand/?token=${localStorage.getItem('STUID')}`,obj);
  }

  public addBrands(obj){
    return this.http.post(`${environment.baseUrl}/api/brand/?token=${localStorage.getItem('STUID')}`,obj);
  }

  public deleteBrand(id){
    return this.http.delete(`${environment.baseUrl}/api/brand/${id}?token=${localStorage.getItem('STUID')}`)
  }

  public getItemBrands(obj){
    return this.http.post(`${environment.baseUrl}/api/brand/getItemBrands?token=${localStorage.getItem('STUID')}`,obj);
  }

}
