import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AddModalComponent } from './../add-modal/add-modal.component';
import { ItemService } from './../../services'
import { SearchPipe } from './../../pipe/index';
import { DatePipe, JsonPipe } from '@angular/common'
import { ToastrService } from 'ngx-toastr';
import jsPDF from 'jspdf'
import 'jspdf-autotable'

const MODALS = {
  focusFirst: AddModalComponent
};

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemsComponent implements OnInit {

  private modalRef: NgbModalRef
  public itemlist: any = [];
  public isEdit: Boolean = false;
  public editItem;
  public isLoading: Boolean = true;
  public errMsg: string;
  public itemNameInput: string = ''
  public isViewable: boolean = false;
  public searchName: string = '';
  public isButtonDisable: boolean = false;

  @Input() list: [];
  @Output() deleteItem: EventEmitter<any>;
  @Output() itemName: EventEmitter<any>;

  constructor(
    private router: Router,
    private _modalService: NgbModal,
    private IT: ItemService,
    public datepipe: DatePipe,
    public SearchPipe: SearchPipe,
    private toastr: ToastrService
  ) {
    this.deleteItem = new EventEmitter();
    this.itemName = new EventEmitter();
  }

  ngOnInit() {
    this.IT.getItemsFromBack().subscribe(items => {
      this.isLoading = false;
      this.itemlist = items;
    }, err => {
      this.isLoading = false;
      this.errMsg = err.error.message;
      this.toastr.error(err.error.message, 'Error!');
    });
  }

  addAndUpdate(){
    this.isEdit ?  this.updateEditItem() : this.createItem();
  }

  createItem() {
    if (!this.itemNameInput) {
      return
    }
    this.isLoading = true;
    this.isButtonDisable = true
    this.errMsg = '';
    let item = this.itemlist.find(obj => obj['name'] == this.itemNameInput);
    if (this.itemNameInput != '') {
      const addItems: object = {
        "name": this.itemNameInput
      }
      if (!item && !this.isEdit) {
        addItems['history'] = JSON.stringify([{
          objkey: 'name',
          newValue: this.itemNameInput
        }]);
        this.IT.addItem(addItems).subscribe((result: any) => {
          this.itemlist = result
          this.itemNameInput = '';
          this.isLoading = false;
          this.isButtonDisable = false
        }, err => {
          this.isLoading = false;
          this.toastr.error(err.error.message, 'Error!');
        });
      }
      else {
        this.toastr.warning('This item name already exist', 'Warning!');
        this.isLoading = false;
      }
    }
  }

  itemEdit(item) {
    this.itemNameInput = item.name;
    this.editItem = item;
    this.isEdit = true;
  }

  itemDelete(id: string) {

    this.modalRef = this._modalService.open(MODALS['focusFirst']);
    this.modalRef.result.then((result) => {
      this.IT.deleteItem(id).subscribe(data => {
      }, err => {
        this.toastr.error(err.error.message, 'Error!');
      });
    }, (reason) => {
    })
  }

  updateEditItem() {
    if (!this.itemNameInput) {
      return
    }
    this.isLoading = true;
    this.isButtonDisable = true

    this.errMsg = '';
    let item = this.itemlist.find(obj => obj['name'] == this.itemNameInput);
    if (!item) {
      this.editItem['history'] = JSON.stringify([{
        objkey: 'Item Name',
        oldValue: this.editItem.name,
        newValue: this.itemNameInput
      }]);
      this.editItem.name = this.itemNameInput;
      this.IT.editItem(this.editItem).subscribe(result => {
        this.isButtonDisable = false;
        this.editItem = {};
        this.isEdit = false;
        this.itemlist = result;
        this.isLoading = false;
        this.itemNameInput = '';
      }, err => {
        this.isLoading = false;
        this.isButtonDisable = false;
        this.toastr.error(err.error.message, 'Error!');
      });
    }
    else {
      this.isEdit = false;
      this.isLoading = false;
      this.itemNameInput = '';
      this.isButtonDisable = false;
      this.editItem = {};
      this.isEdit = false;
    }
  }

  captureScreen() {
    if (this.itemlist) {
      let list = this.SearchPipe.transform(this.itemlist, this.searchName, 'name');
      const doc = new jsPDF()

      doc.setFontSize(18)
      doc.text('Items Detail', 14, 22)
      doc.autoTable({ html: '#my-table' })
      doc.autoTable({
        head: [
          [
            'Date',
            'Item Name'
          ]
        ],
        body: list.reduce((acc, cur) => {
          let date = this.datepipe.transform(cur.createdAt, 'yyyy-MM-dd');
          acc.push([
            date,
            cur.name,
          ])
          return acc
        }, []),
        startY: 30,
        showHead: 'firstPage',
        styles: { overflow: 'ellipsize', cellWidth: 'wrap' },
        columnStyles: { text: { cellWidth: 'auto' } }
      })
      let date = new Date().toDateString()
      doc.save(`items-${date}.pdf`);
    }
  }
}
