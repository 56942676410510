import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { PettyCashService, SharedService, BankService } from "./../../services";
import { DatePipe } from "@angular/common";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-add-petty",
  templateUrl: "./add-petty.component.html",
  styleUrls: ["./add-petty.component.scss"],
})
export class AddPettyComponent implements OnInit {
  errMsg: string = "";
  pettyCash = [];

  public isUpdatePettyCash: boolean;
  public isLoad: boolean;
  public updatePettyCash;
  public addPettyForm: FormGroup;
  public isButtonDisable: boolean = false;
  public banks;

  constructor(
    private fb: FormBuilder,
    private PCS: PettyCashService,
    private SS: SharedService,
    private BS: BankService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.initializeForm();
    this.getBanks();
  }

  initializeForm() {
    let pettyCashId = this.activatedRoute.snapshot.params.pettyCashId;
    if (pettyCashId) {
      this.PCS.getPettyCashDetail(pettyCashId).subscribe(
        (result) => {
          this.isUpdatePettyCash = true;
          this.updatePettyCash = result;
          this.addPettyForm = this.fb.group({
            pettyCashTransactionId: [
              result.pettyCashTransactionId,
              Validators.required,
            ],
            date: [result.date, Validators.required],
            pettyCashName: [result.pettyCashName, Validators.required],
            cash: [result.cash, Validators.required],
            description: [result.description],
            bankId: [result.bankId ? result.bankId : ""],
          });
        },
        (err) => {
          this.toastr.error(err.error.message, "Error!");
        }
      );
    } else {
      this.PCS.getPettyCashId().subscribe(
        (result) => {
          this.addPettyForm = this.fb.group({
            pettyCashTransactionId: [
              result.pettyCashTransactionId,
              Validators.required,
            ],
            date: [
              this.datepipe.transform(new Date(), "yyyy-MM-dd"),
              Validators.required,
            ],
            pettyCashName: ["", Validators.required],
            cash: ["", Validators.required],
            description: [""],
            bankId: [""],
          });
        },
        (err) => {
          this.toastr.error(err.error.message, "Error!");
        }
      );
    }
  }

  getBanks() {
    if (!this.BS.getBanks()) {
      this.BS.getBanksFromBack().subscribe(
        (result) => {
          this.banks = result;
        },
        (err) => {
          this.toastr.error(err.error.message, "Error!");
        }
      );
    } else {
      this.banks = this.BS.getBanks();
    }
  }

  submit() {
    if (!this.addPettyForm.valid) {
      return;
    }
    this.isButtonDisable = true;
    this.isLoad = true;
    let obj = this.addPettyForm.value;
    obj["history"] = this.SS.getHistory(obj, "add", "", null, this.banks);
    this.PCS.addPettyCash(obj).subscribe(
      (result) => {
        this.isLoad = true;
        this.PCS.getPettyCash().subscribe(
          (petty) => {
            this.isLoad = false;
            this.router.navigate(["/dashboard/petty-cash-list"]);
          },
          (err) => {
            this.toastr.error(err.error.message, "Error!");
          }
        );
      },
      (err) => {
        this.toastr.error(err.error.message, "Error!");
      }
    );
  }

  update() {
    if (!this.addPettyForm.valid) {
      return;
    }
    this.isButtonDisable = true;
    this.isLoad = true;
    let changes = this.SS.detectChange(
      this.addPettyForm.value,
      this.updatePettyCash
    );
    this.updatePettyCash["history"] = this.SS.getHistory(
      this.updatePettyCash,
      "update",
      changes,
      null,
      this.banks
    );
    this.updatePettyCash.pettyCashTransactionId =
      this.addPettyForm.value.pettyCashTransactionId;
    this.updatePettyCash.date = this.addPettyForm.value.date;
    this.updatePettyCash.bankId = this.addPettyForm.value.bankId;
    this.updatePettyCash.pettyCashName = this.addPettyForm.value.pettyCashName;
    this.updatePettyCash.cash = this.addPettyForm.value.cash;
    this.updatePettyCash.description = this.addPettyForm.value.description;
    this.PCS.updatePettyCash(this.updatePettyCash).subscribe(
      (result) => {
        this.isLoad = true;
        this.PCS.getPettyCash().subscribe(
          (petty) => {
            this.isLoad = false;
            this.router.navigateByUrl("/dashboard/petty-cash-list");
          },
          (err) => {
            this.toastr.error(err.error.message, "Error!");
          }
        );
      },
      (err) => {
        this.toastr.error(err.error.message, "Error!");
      }
    );
  }
}
