import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  ItemService,
  BrandsService,
  StockService,
  SharedService,
} from "./../../services";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import * as XLSX from "xlsx";

@Component({
  selector: "app-add-stock",
  templateUrl: "./add-stock.component.html",
  styleUrls: ["./add-stock.component.scss"],
})
export class AddStockComponent implements OnInit {
  public itemList;
  public itemBrandList;
  public brands;
  public totalQuantity: number = 0;
  public totalDamageQuantity: number = 0;
  public netQuantity: number = 0;
  public netCost: number = 0;
  public isEdit: Boolean;
  public EditSockValues;
  public editStockDetailValues;
  public isEditStockData: Boolean;
  public editStockDataIndex;
  public itemNameEditValue: String;
  public addStockForm: FormGroup;
  public stockSelectorForm: FormGroup;
  public stockDataList = [];
  public isLoad: boolean;
  public isButtonDisable: boolean = false;
  public stockInfo: {
    stockId: "";
    totalQuantity: 0;
    totalDamageQuantity: 0;
    netCost: 0;
    netQuantity: 0;
    date: "";
  };
  public file: any;
  public excel: any[];
  
  @Output() getItemBrands: EventEmitter<any>;
  @Output() saveStockData: EventEmitter<any>;
  
  constructor(
    private fb: FormBuilder,
    private IT: ItemService,
    private BS: BrandsService,
    private SS: StockService,
    private HS: SharedService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private toastr: ToastrService
  ) {
    this.isLoad = false;
    this.getItemBrands = new EventEmitter();
    this.saveStockData = new EventEmitter();
  }

  ngOnInit() {
    let stockList = JSON.parse(localStorage.getItem("stockDataList"));
    if (stockList) {
      this.stockDataList = stockList;
      this.calculateSummeryDetails();
    }
    this.getItems();
    let stock = this.activatedRoute.snapshot.params.stockId;
    if (stock) {
      this.isEdit = true;
      this.SS.getStockWithDetails(stock).subscribe(
        (result: any) => {
          this.EditSockValues = result.stock;
          let stock = result.stock;
          this.totalDamageQuantity = stock.totalDamageQuantity;
          this.totalQuantity = stock.totalQuantity;
          this.netQuantity = stock.netQuantity;
          this.netCost = stock.netCost;
          this.stockDataList = result.stockDetails;
          this.stockSelectorForm = this.fb.group({
            stockId: [stock.stockId, Validators.required],
            createdDate: [stock.date, Validators.required],
          });
        },
        (err) => {
          this.toastr.error(err.error.message, "Error!");
        }
      );
    } else {
      this.SS.getStockId().subscribe(
        (result) => {
          this.stockSelectorForm = this.fb.group({
            stockId: [result.stockId, Validators.required],
            createdDate: [
              this.datepipe.transform(new Date(), "yyyy-MM-dd"),
              Validators.required,
            ],
          });
        },
        (err) => {
          this.toastr.error(err.error.message, "Error!");
        }
      );
    }
    this.addStockForm = this.fb.group({
      itemId: ["", Validators.required],
      brandId: ["", Validators.required],
      modelNumber: ["", Validators.required],
      color: ["", Validators.required],
      size: [""],
      initialQty: [0, Validators.required],
      damageQty: [0, Validators.required],
      unitCost: [0, Validators.required],
    });
  }

  getItems() {
    this.IT.getItemsFromBack().subscribe(
      (items) => {
        this.itemList = items;
      },
      (err) => {
        this.toastr.error(err.error.message, "Error!");
      }
    );
  }

  submit() {
    if (!this.addStockForm.valid) {
      return;
    }
    if (!this.stockSelectorForm.value.createdDate) {
      alert("kindly select stock date");
      return;
    }
    if (this.checkDuplication()) {
      alert("duplicate data");
      return;
    }
    if (!this.isEditStockData) {
      let obj = this.addStockForm.value;
      let item = this.itemList.find(
        (obj) => obj._id == this.addStockForm.value.itemId
      );
      let brand = this.brands.find(
        (x) => x._id == this.addStockForm.value.brandId
      );
      obj["stockId"] = this.stockSelectorForm.value.stockId;
      obj["itemName"] = item.name;
      obj["brandName"] = brand.brandName;
      if (obj.damageQty > obj.initialQty) {
        alert("Damage Quantity should be less then initial Quantity");
        return;
      }
      obj["actualQty"] = obj.initialQty - obj.damageQty;
      obj["totalCost"] = obj.unitCost * obj.actualQty;
      obj["date"] = this.stockSelectorForm.value.createdDate;
      let sendHistoryObj = Object.assign({}, obj);
      delete sendHistoryObj["itemId"];
      delete sendHistoryObj["brandId"];
      obj["history"] = this.HS.getHistory(
        sendHistoryObj,
        "add",
        "",
        null,
        null
      );
      this.stockDataList.push(obj);
      this.calculateSummeryDetails();
      if (!this.isEdit)
        localStorage.setItem(
          "stockDataList",
          JSON.stringify(this.stockDataList)
        );
      this.resetForm();
      this.addStockForm.patchValue({
        damageQty: 0,
      });
    } else {
      let obj = Object.assign({}, this.editStockDetailValues);
      if (obj.damageQty > obj.initialQty) {
        alert("Damage Quantity should be less then initial Quantity");
        return;
      }
      if (obj.soldQty > this.addStockForm.value.initialQty) {
        alert(
          "Sold Quantity is more then your updated initial quantity, you can not decrese less then sold quantity"
        );
        return;
      }
      let item = this.itemList.find(
        (obj) => obj._id == this.addStockForm.value.itemId
      );
      let brand = this.brands.find(
        (x) => x._id == this.addStockForm.value.brandId
      );
      obj["itemId"] = item._id;
      obj["brandId"] = brand._id;
      obj["itemName"] = item.name;
      obj["brandName"] = brand.brandName;
      (obj.color = this.addStockForm.value.color),
        (obj.size = this.addStockForm.value.size),
        (obj.initialQty = this.addStockForm.value.initialQty),
        (obj.damageQty = this.addStockForm.value.damageQty),
        (obj.unitCost = this.addStockForm.value.unitCost),
        (obj.actualQty =
          obj.initialQty - (obj.damageQty + (obj.soldQty ? obj.soldQty : 0)));
      obj.totalCost = obj.unitCost * obj.initialQty;
      if (
        this.editStockDetailValues &&
        this.editStockDetailValues.hasOwnProperty("_id")
      ) {
        let changes = this.HS.detectChange(obj, this.editStockDetailValues);
        let historyObj = Object.assign({}, this.editStockDetailValues);
        delete historyObj["itemId"];
        delete historyObj["brandId"];
        delete historyObj["stock"];
        obj["history"] = this.HS.getHistory(
          historyObj,
          "update",
          changes,
          "",
          ""
        );
      }
      obj.stockId = this.stockSelectorForm.value.stockId;
      (obj.modelNumber = this.addStockForm.value.modelNumber),
        this.stockDataList.push(obj);
      this.calculateSummeryDetails();
      let sendHistoryObj = Object.assign({}, obj);
      delete sendHistoryObj["itemId"];
      delete sendHistoryObj["brandId"];
      delete sendHistoryObj["stock"];

      this.editStockDetailValues = "";
      this.isEditStockData = false;
      this.resetForm();
    }
  }

  resetForm() {
    this.addStockForm.patchValue({
      modelNumber: "",
      color: "",
      size: "",
      initialQty: 0,
      damageQty: 0,
      unitCost: 0,
    });
  }

  calculateSummeryDetails() {
    this.totalQuantity = this.stockDataList.reduce((acc, cu) => {
      return acc + cu.initialQty;
    }, 0);
    this.totalDamageQuantity = this.stockDataList.reduce((acc, cu) => {
      return acc + cu.damageQty;
    }, 0);
    this.netQuantity = this.stockDataList.reduce((acc, cu) => {
      return acc + cu.actualQty;
    }, 0);
    this.netCost = this.stockDataList.reduce((acc, cu) => {
      return acc + cu.totalCost;
    }, 0);
  }

  checkDuplication() {
    let obj = this.addStockForm.value;
    if (
      this.stockDataList.length &&
      this.stockDataList.find(
        (detail) =>
          detail.itemId == obj.itemId &&
          detail.brandId == obj.brandId &&
          detail.modelNumber.toLocaleLowerCase() ==
            obj.modelNumber.toLocaleLowerCase() &&
          detail.color.toLocaleLowerCase() == obj.color.toLocaleLowerCase()
      )
    ) {
      return true;
    }
  }

  ngOnChanges(changes) {
    if (changes) {
      this.addStockForm.value.actualPiece =
        this.addStockForm.value.initialQty - this.addStockForm.value.damageQty;
      if (
        changes &&
        changes.itemBrandList &&
        changes.itemBrandList.currentValue.length
      ) {
        if (this.itemBrandList.length > 0) {
          this.brands = this.itemBrandList;
        }
      }
    }
  }

  changeItem(item) {
    this.BS.getItemBrands({ itemId: item._id }).subscribe(
      (itemBrands) => {
        this.brands = itemBrands;
      },
      (err) => {
        this.toastr.error(err.error.message, "Error!");
      }
    );
  }

  saveStock() {
    if (this.stockDataList.length < 0) {
      return;
    }
    this.isLoad = true;
    let stock = {
      totalQuantity: this.totalQuantity,
      totalDamageQuantity: this.totalDamageQuantity,
      netQuantity: this.netQuantity,
      netCost: this.netCost,
      date: this.stockSelectorForm.value.createdDate,
      stockId: this.stockSelectorForm.value.stockId,
    };
    let obj = { stock: stock };
    obj.stock["history"] = this.HS.getHistory(stock, "add", "", null, null);
    obj["stockDetails"] = this.stockDataList;
    this.SS.addStock(obj).subscribe(
      (result) => {
        localStorage.removeItem("stockDataList");
        this.router.navigateByUrl("/dashboard/stock-report");
      },
      (err) => {
        this.isLoad = false;
        this.toastr.error(err.error.message, "Error!");
      }
    );
  }

  updateStock() {
    if (this.stockDataList.length < 0) {
      return;
    }
    this.isLoad = true;
    let stock = { ...this.EditSockValues };
    stock.totalQuantity = this.totalQuantity;
    stock.totalDamageQuantity = this.totalDamageQuantity;
    stock.netQuantity = this.netQuantity;
    stock.netCost = this.netCost;
    stock.date = this.stockSelectorForm.value.createdDate;
    stock.stockId = this.stockSelectorForm.value.stockId;
    stock["history"] = this.getStockHistory(stock);

    let obj = { stock: stock };
    obj["stockDetails"] = this.stockDataList;
    this.SS.updateEditStock(obj).subscribe(
      (result) => {
        this.router.navigateByUrl("/dashboard/stock-report");
      },
      (err) => {
        this.isLoad = false;
        this.toastr.error(err.error.message, "Error!");
      }
    );
  }

  deleteStockFromList(stock, i) {
    this.stockDataList.splice(i, 1);
    this.totalDamageQuantity -= stock.damageQty;
    this.totalQuantity -= stock.initialQty;
    this.netQuantity -= stock.actualQty;
    this.netCost -= stock.totalCost;
  }

  getStockHistory(stock) {
    let changes = this.HS.detectChange(stock, this.EditSockValues);
    if (changes != undefined) {
      return this.HS.getHistory(
        { ...this.EditSockValues },
        "update",
        changes,
        null,
        null
      );
    } else if (!this.isEdit) {
      return this.HS.getHistory(stock, "add", "", null, null);
    } else {
      let tempObj = Object.assign({}, this.EditSockValues);
      delete tempObj["_id"];
      delete tempObj["__v"];
      delete tempObj["status"];
      delete tempObj["createdAt"];
      delete tempObj["updatedAt"];
      return this.HS.getUnChangeStock(tempObj);
    }
  }

  editDetail(stock, index) {
    if (this.editStockDetailValues) {
      alert("please update first record");
      return;
    }
    this.changeItem({ _id: stock.itemId });
    this.editStockDetailValues = stock;
    this.isEditStockData = true;
    this.editStockDataIndex = index;
    this.addStockForm.patchValue({
      itemId: stock.itemId,
      brandId: stock.brandId,
      itemName: stock.itemName,
      brandName: stock.brandName,
      modelNumber: stock.modelNumber,
      color: stock.color,
      size: stock.size,
      initialQty: stock.initialQty,
      damageQty: stock.damageQty,
      unitCost: stock.unitCost,
    });
    this.totalDamageQuantity -= stock.damageQty;
    this.totalQuantity -= stock.initialQty;
    this.netQuantity -= stock.actualQty;
    this.netCost -= stock.totalCost;
    this.stockDataList.splice(index, 1);
  }

  clearAll() {
    localStorage.removeItem("stockDataList");
    this.stockDataList = [];
    this.totalQuantity = 0;
    this.totalDamageQuantity = 0;
    this.netQuantity = 0;
    this.netCost = 0;
  }

  addfile(ev) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = () => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: "binary", cellDates: true });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      Object.keys(jsonData).map((key) => {
        this.excel = jsonData[key];
      });
      this.excel.map((excelItem) => {
        Object.keys(excelItem).map((excelItemKey) => {
          this.itemList.map((item) => {
            if (
              item.name.toLowerCase() ===
              String(excelItem[excelItemKey]).toLowerCase()
            ) {
              this.BS.getItemBrands({ itemId: item._id }).subscribe(
                (itemBrands) => {
                  Object.keys(excelItem).map((filteredExcelItemKey) => {
                    itemBrands.map((brand) => {
                      if (
                        brand.brandName.toLowerCase() ===
                        String(excelItem[filteredExcelItemKey]).toLowerCase()
                      ) {
                        const matchedItem = this.stockDataList.find(
                          (stock) =>
                            excelItem.MODELNO === stock.modelNumber &&
                            excelItem.COL === stock.color &&
                            excelItem.ITEMNAME === stock.itemName &&
                            excelItem.BRANDNAME === stock.brandName
                        );
                        const matchedItemIndex =
                          this.stockDataList.indexOf(matchedItem);
                        if (matchedItem) {
                          const history = {
                            actualQty:
                              excelItem.INITIALQTY +
                              matchedItem.initialQty -
                              (0 + matchedItem.damageQty),
                            brandName: excelItem.BRANDNAME,
                            color: excelItem.COL,
                            damageQty: 0 + matchedItem.damageQty,
                            date: this.stockSelectorForm.value.createdDate,
                            initialQty:
                              excelItem.INITIALQTY + matchedItem.initialQty,
                            itemName: excelItem.ITEMNAME,
                            modelNumber: excelItem.MODELNO,
                            size: 0,
                            stockId: this.stockSelectorForm.value.stockId,
                            totalCost:
                              (excelItem.UNITPRICE + matchedItem.unitCost) *
                              (excelItem.INITIALQTY + matchedItem.initialQty),
                            unitCost:
                              excelItem.UNITPRICE + matchedItem.unitCost,
                          };
                          const obj = {
                            brandId: brand._id,
                            itemId: item._id,
                            actualQty:
                              excelItem.INITIALQTY +
                              matchedItem.initialQty -
                              (0 + matchedItem.damageQty),
                            brandName: excelItem.BRANDNAME,
                            color: excelItem.COL,
                            damageQty: 0 + matchedItem.damageQty,
                            date: this.stockSelectorForm.value.createdDate,
                            initialQty:
                              excelItem.INITIALQTY + matchedItem.initialQty,
                            itemName: excelItem.ITEMNAME,
                            modelNumber: excelItem.MODELNO,
                            size: 0,
                            history: this.HS.getHistory(
                              history,
                              "add",
                              "",
                              null,
                              null
                            ),
                            stockId: this.stockSelectorForm.value.stockId,
                            totalCost:
                              (excelItem.UNITPRICE + matchedItem.unitCost) *
                              (excelItem.INITIALQTY + matchedItem.initialQty),
                            unitCost:
                              excelItem.UNITPRICE + matchedItem.unitCost,
                          };
                          this.stockDataList[matchedItemIndex] = obj;
                          this.stockDataList = this.stockDataList;
                          localStorage.setItem(
                            "stockDataList",
                            JSON.stringify(this.stockDataList)
                          );
                          this.calculateSummeryDetails();
                        } else {
                          const historyObj = {
                            actualQty: excelItem.INITIALQTY - 0,
                            brandName: excelItem.BRANDNAME,
                            color: excelItem.COL,
                            damageQty: 0,
                            date: this.stockSelectorForm.value.createdDate,
                            initialQty: excelItem.INITIALQTY,
                            itemName: excelItem.ITEMNAME,
                            modelNumber: excelItem.MODELNO,
                            size: 0,
                            stockId: this.stockSelectorForm.value.stockId,
                            totalCost:
                              excelItem.UNITPRICE * excelItem.INITIALQTY,
                            unitCost: excelItem.UNITPRICE,
                          };
                          const obj = {
                            brandId: brand._id,
                            itemId: item._id,
                            actualQty: excelItem.INITIALQTY - 0,
                            brandName: excelItem.BRANDNAME,
                            color: excelItem.COL,
                            damageQty: 0,
                            date: this.stockSelectorForm.value.createdDate,
                            initialQty: excelItem.INITIALQTY,
                            itemName: excelItem.ITEMNAME,
                            modelNumber: excelItem.MODELNO,
                            size: 0,
                            history: this.HS.getHistory(
                              historyObj,
                              "add",
                              "",
                              null,
                              null
                            ),
                            stockId: this.stockSelectorForm.value.stockId,
                            totalCost:
                              excelItem.UNITPRICE * excelItem.INITIALQTY,
                            unitCost: excelItem.UNITPRICE,
                          };
                          this.stockDataList.push(obj);
                          localStorage.setItem(
                            "stockDataList",
                            JSON.stringify(this.stockDataList)
                          );
                          this.calculateSummeryDetails();
                        }
                      }
                    });
                  });
                }
              );
            }
          });
        });
      });
      ev.target.value = ''
    };
    reader.readAsBinaryString(file);
  }
}
