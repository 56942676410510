import { Injectable } from '@angular/core';
import { HttpService } from './../http/http.service';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(private http: HttpService) { }

  public getHistory(start,end,userId,toDate,fromDate){
    return this.http.get(`${environment.baseUrl}/api/history/getHistory?skip=${start}&limit=${end}&userId=${userId}&toDate=${toDate}&fromDate=${fromDate}`);
  }
}
