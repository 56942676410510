import { Injectable } from '@angular/core';
import { HttpService } from './../http/http.service';
import { environment } from './../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UploadPdfService {

  public uploadPdf;

  constructor(private http: HttpService) { }

  public addUploadPdf(obj) {
    obj['token'] = localStorage.getItem('STUID');
    return this.http.post(`${environment.baseUrl}/api/uploadPdf/?token=${localStorage.getItem('STUID')}`, obj);
  }

  public getUploadPdf() {
    return this.http.get(`${environment.baseUrl}/api/uploadPdf/?token=${localStorage.getItem('STUID')}`).pipe(map((response: any) => {
      return response;
    }),
      catchError(err => {
        throw err;
      })
    );
  }

}
