import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
import { Router } from '@angular/router'
import { PettyCashService, BankService } from './../../services'
import * as jsPDF from 'jspdf'
import { FPettyCashPipe, FFromDatePipe, FToDatePipe, BankNamePipe, NumberPipe } from './../../pipe/index';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-petty-cash',
  templateUrl: './petty-cash.component.html',
  styleUrls: ['./petty-cash.component.scss']
})
export class PettyCashComponent implements OnInit {
  @ViewChild('pdfShowTable', { static: false }) pdfShowTable: ElementRef

  public pdfTable: boolean = false
  public errMsg: string = '';
  public pettyCash = [];
  public pettyCashName: string = '';
  public debit: number;
  public credit: number;
  public balance: number;
  public fromDate: Date
  public toDate: Date
  public isAddPettyCashAccess: boolean = false
  public banks;
  public bankSearchName: string;

  constructor(
    private router: Router,
    private PCS: PettyCashService,
    private BS: BankService,
    public FPettyCashPipe: FPettyCashPipe,
    public fromDatePipe: FFromDatePipe,
    public toDatePipe: FToDatePipe,
    public bankPipe: BankNamePipe,
    private toastr: ToastrService,
    public numberPipe: NumberPipe
  ) { }

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem('STU'))
    this.isAddPettyCashAccess = user.updateAndCreatePettyCash;
    this.getBanks();

    this.PCS.getPettyCash().subscribe(
      result => {
        result.sort((a: any, b: any) => {
          return this.compare(new Date(a.date), new Date(b.date), true);
        });
        this.pettyCash = result;
        this.getSummries('','');
      }, err => {
        this.errMsg = err.error.message;
        this.toastr.error(err.error.message, 'Error!');
      });
  }

  compare(a: any, b: any, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getBanks() {
    if (!this.BS.getBanks()) {
      this.BS.getBanksFromBack().subscribe(result => {
        this.banks = result;
      }, err => {
        this.toastr.error(err.error.message, 'Error!');
      });
    } else {
      this.banks = this.BS.getBanks();
    }
  }

  getSummries(event,type) {
    switch(type){
      case 'bank':
        this.bankSearchName = event;
        break;
      case 'toDate':
        this.toDate = event;
        break;
      case 'fromDate':
        this.fromDate = event;
        break;
      case 'pettyCashName':
        this.pettyCashName = event;
    }
    let list = this.FPettyCashPipe.transform(this.pettyCash, this.pettyCashName, 'pettyCashName');
    list = this.bankPipe.transform(list, this.bankSearchName);
    list = this.fromDatePipe.transform(list, this.fromDate, 'pettyCashList');
    list = this.toDatePipe.transform(list, this.toDate, 'pettyCashList');
    this.debit = list.reduce((acc, current) => { return acc + current.cash }, 0);
    this.credit = 0;
    this.balance = list.reduce((acc, current) => { return acc + current.cash }, 0);
  }

  captureScreen() {
    if (this.pettyCash) {
      let list = this.FPettyCashPipe.transform(this.pettyCash, this.pettyCashName, 'pettyCashName');
      list = this.bankPipe.transform(list, this.bankSearchName);
      list = this.fromDatePipe.transform(list, this.fromDate, 'pettyCashList');
      list = this.toDatePipe.transform(list, this.toDate, 'pettyCashList');
      let balance = list.reduce((acc, cur) => { return acc + cur.cash }, 0);

      const doc = new jsPDF()

      doc.setFontSize(18)
      doc.text('Petty Cash List', 14, 22)
      doc.setFontSize(12)
      doc.setTextColor(100)
      doc.text(`Total Balance : ${this.numberPipe.transform(balance)}`, 140, 30)
      doc.autoTable({ html: '#my-table' })
      // Or use javascript directly:
      doc.autoTable({
        head: [
          [
            'Transaction date',
            'TransactionId',
            'Bank Name',
            'Petty Cash',
            'Description',
            'Cash'
          ]
        ],
        body: list.reduce((acc, cur) => {
          let dec = cur.description
          if (cur.description.length > 40) {
            for (let k = 1; k < dec.length; ++k) {
              if (k % 40 == 0) {
                dec = dec.slice(0, k) + '\n' + dec.slice(k)
              }
            }
          }
          acc.push([
            cur.date,
            cur.pettyCashTransactionId,
            cur.bankName,
            cur.pettyCashName,
            dec,
            cur.cash.toString()
          ])
          return acc
        }, []),
        startY: 35,
        showHead: 'firstPage',
        styles: { overflow: 'ellipsize', cellWidth: 'wrap' },
        columnStyles: {
          4: {
            columnWidth: 'auto'
          }
        }
      })
      let date = new Date().toDateString()
      doc.save(`petty-cash-list-${date}.pdf`);
    }
  }
  generatPdf() {
    let doc = new jsPDF()

    let specialElementHandlers = {
      '#editor': function (element, renderr) {
        return true
      }
    }
    let pdfShowTable = this.pdfShowTable.nativeElement

    doc.fromHTML(pdfShowTable.innerHTML, 15, 15, {
      width: 190,
      elementHandlers: specialElementHandlers
    })
    doc.save('test.pdf')
  }

  tableToJson(table) {
    var data = []

    // first row needs to be headers
    var headers = []
    for (var i = 0; i < table.rows[0].cells.length; i++) {
      headers[i] = table.rows[0].cells[i].innerHTML
        .toLowerCase()
        .replace(/ /gi, '')
    }
    data.push(headers)
    // go through cells
    for (var i = 1; i < table.rows.length; i++) {
      var tableRow = table.rows[i]
      var rowData = {}

      for (var j = 0; j < tableRow.cells.length; j++) {
        rowData[headers[j]] = tableRow.cells[j].innerHTML
      }
      data.push(rowData)
    }

    return data
  }

  createPettyCash() {
    this.router.navigateByUrl('/dashboard/add-petty-cash')
  }

  editPettyCash(petty) {
    this.router.navigateByUrl(`/dashboard/update-petty/${petty._id}`)
  }

  clearAll() {
    this.pettyCashName = ''
    this.fromDate = null
    this.toDate = null
  }
}
