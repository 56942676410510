import { Injectable } from "@angular/core";
import { Actions, ofType, Effect } from "@ngrx/effects";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";
import * as BrandAction from "../action/brand.action";
import { debug } from "util";

const httpOptions = {
  headers: new HttpHeaders({ "content-type": "application/json" }),
};
@Injectable()
export class BrandEffect {
  constructor(private action$: Actions, private http: HttpClient) {}
}
