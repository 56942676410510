import { Injectable } from '@angular/core';
import { HttpService } from './../http/http.service';
import { environment } from './../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class CustomerService {

  public customers: any;

  constructor(private http: HttpService) { }

  public addCustomer(obj) {
    return this.http.post(`${environment.baseUrl}/api/customer/?token=${localStorage.getItem('STUID')}`, obj).pipe(map((response: any) => {
      this.customers = response;
      return response;
    }),catchError(err => {
        throw err;
      })
    );
  }

  public getCustomers() {
    return this.customers;
  }


  public updateCustomer(obj){
    return this.http.put(`${environment.baseUrl}/api/customer/?token=${localStorage.getItem('STUID')}`,obj).pipe(map((response: any) => {
      this.customers = response;
      return response;
    }),
      catchError(err => {
        throw err;
      })
    );
  }

  public getOneCustomerDetail(customerId){
    return this.http.get(`${environment.baseUrl}/api/customer/${customerId}?token=${localStorage.getItem('STUID')}`);
  }

  public deleteCustomer(customerId){
    return this.http.delete(`${environment.baseUrl}/api/customer/${customerId}?token=${localStorage.getItem('STUID')}`);
  }

  public getCustomersFromBack() {
    return this.http.get(`${environment.baseUrl}/api/customer/?token=${localStorage.getItem('STUID')}`).pipe(map((response: any) => {
      this.customers = response;
      return response;
    }),
      catchError(err => {
        throw err;
      })
    );
  }
}

