import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "app-additem",
  templateUrl: "./additem.component.html",
  styleUrls: ["./additem.component.scss"],
})
export class AdditemComponent implements OnInit {
  @Output() itemName: EventEmitter<any>;
  itemNameInput: string = "";

  constructor() {
    this.itemName = new EventEmitter();
  }

  ngOnInit() {}
  addItem() {
    if (this.itemNameInput != "") {
      const addItems: object = {
        name: this.itemNameInput,
      };
      this.itemName.emit(addItems);
      this.itemNameInput = "";
    }
  }
}
