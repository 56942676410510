import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { HttpService } from './../http/http.service';

@Injectable({
  providedIn: 'root'
})

export class PaymentService {

  constructor(private http:HttpService) { }

  public getTransactionsId(){
    return this.http.get(`${environment.baseUrl}/api/paymentReceive/getTransactionId?token=${localStorage.getItem('STUID')}`);
  }

  public getPayments(){
    return this.http.get(`${environment.baseUrl}/api/paymentReceive/?token=${localStorage.getItem('STUID')}`);
  }

  public addPayment(obj){
    return this.http.post(`${environment.baseUrl}/api/paymentReceive/?token=${localStorage.getItem('STUID')}`,obj);
  }

  public getPaymentDetailById(id){
    return this.http.get(`${environment.baseUrl}/api/paymentReceive/${id}?token=${localStorage.getItem('STUID')}`);
  }

  public updatePayment(obj){
    return this.http.put(`${environment.baseUrl}/api/paymentReceive/?token=${localStorage.getItem('STUID')}`,obj);
  }

  public deletePayment(id){
    return this.http.delete(`${environment.baseUrl}/api/paymentReceive/${id}?token=${localStorage.getItem('STUID')}`)
  }

  public getPaymentLedgerReport(){
    return this.http.get(`${environment.baseUrl}/api/paymentReceive/getLedgerReport?token=${localStorage.getItem('STUID')}`);
  }

}
