import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-role',
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.scss']
})
export class UserRoleComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {
  }

  createUserRole(){
    this.router.navigateByUrl('/dashboard/add-user-role')
  }
}
