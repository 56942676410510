import { ItemService } from './item/item.service';
import { BrandListService } from './brand-list/brand-list.service';
import { HttpService } from './http/http.service';
import { BrandsService } from './brands/brands.service';
import { StockService } from './stock/stock.service';
import { CustomerService } from './customer/customer.service';
import { BankService } from './bank/bank.service';
import { PaymentService } from './payment/payment.service';
import { SaleInvoiceService } from './sale-invoice/sale-invoice.service';
import { PettyCashService } from './petty-cash/petty-cash.service';
import { ReturnInvoiceService } from './return-invoice/return-invoice.service';
import { UploadPdfService } from './upload-pdf/upload-pdf.service';
import { UsersService } from './users/users.service';
import { HistoryService } from './history/history.service';
import { SharedService } from './shared/shared.service';
import { AuthGuradService } from './auth-guard/auth-gurad.service';
import { ExcelService } from './excel/excel.service';

export const Services: any[] = [
  ItemService,
  BrandListService,
  HttpService,
  BrandsService,
  StockService,
  CustomerService,
  BankService,
  PaymentService,
  SaleInvoiceService,
  PettyCashService,
  ReturnInvoiceService,
  UploadPdfService,
  UsersService,
  HistoryService,
  SharedService,
  AuthGuradService,
  ExcelService
];

export {
  ItemService,
  BrandListService,
  HttpService,
  BrandsService,
  StockService,
  CustomerService,
  BankService,
  PaymentService,
  SaleInvoiceService,
  PettyCashService,
  ReturnInvoiceService,
  UploadPdfService,
  UsersService,
  HistoryService,
  SharedService,
  AuthGuradService,
  ExcelService
};
