import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fPayment'
})
export class FPaymentPipe implements PipeTransform {

  transform(value: any, transactionNo: string, propName: string): any {
    return transactionNo ? value.filter((brand) => {
      return brand ? brand.transactionNo ? brand.transactionNo.toLocaleLowerCase().indexOf(transactionNo.toLocaleLowerCase()) !== -1 : value : value; 
    }) : value;
  }

}
