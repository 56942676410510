import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fColor'
})
export class FColorPipe implements PipeTransform {

  transform(value: any, color: string, ): any {
    return color ? value.filter((brand) => {
      return brand.color.toLocaleLowerCase().indexOf(color.toLocaleLowerCase()) !== -1;
    }) : value;
  }

}
