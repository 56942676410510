import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { BankService, SharedService } from './../../services';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-bank',
  templateUrl: './add-bank.component.html',
  styleUrls: ['./add-bank.component.scss']
})
export class AddBankComponent implements OnInit {

  public addBankForm: FormGroup;
  public isUpdateBank: boolean;
  public isLoad: boolean;
  public updateBank;
  public isButtonDisable:boolean = false;

  constructor(
    private fb: FormBuilder,
    private BS: BankService,
    private SS: SharedService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    let bankId = this.activatedRoute.snapshot.params.bankId;
    if (bankId) {
      this.BS.getBankDetail(bankId).subscribe(result => {
        this.isUpdateBank = true;
        this.updateBank = result;
        this.addBankForm = this.fb.group({
          name: [result.name, Validators.required],
          accountNo: [result.accountNo],
          address: [result.address]
        })
      },err=>{
        this.toastr.error(err.error.message,'Error!');
      })
    } else {
      this.addBankForm = this.fb.group({
        name: ['', Validators.required],
        accountNo: [''],
        address: ['']
      })
    }
  }

  submit() {
    if(!this.addBankForm.valid) return
    
    this.isButtonDisable = true;
    this.isLoad = true;
    let obj = this.addBankForm.value;
    obj['history'] = this.SS.getHistory(obj, 'add', '', null,null);
    this.BS.addBank(obj).subscribe(result => {
        this.isLoad = false;
        this.router.navigate(['/dashboard/banks']);
    },err=>{
      this.toastr.error(err.error.message,'Error!');
      this.isButtonDisable = false;
      this.isLoad = false;
    })
  }

  update() {
    if(!this.addBankForm.valid){
      return
    }
    this.isButtonDisable = true;
    this.isLoad = true;
    let changes = this.SS.detectChange(this.addBankForm.value, this.updateBank);
    this.updateBank['history'] = this.SS.getHistory(this.updateBank, 'update', changes, null, null);
    this.updateBank.name = this.addBankForm.value.name;
    this.updateBank.accountNo = this.addBankForm.value.accountNo;
    this.updateBank.address = this.addBankForm.value.address;
    this.BS.updateBank(this.updateBank).subscribe(result => {
      this.isLoad = true;
      this.BS.getBanksFromBack().subscribe(banks => {
        this.isLoad = false;
        this.router.navigateByUrl('/dashboard/banks')
      },err=>{
        this.toastr.error(err.error.message,'Error!');
        this.isButtonDisable = false;
        this.isLoad = false;
      })
    },err=>{
      this.toastr.error(err.error.message,'Error!');
      this.isButtonDisable = false;
      this.isLoad = false;
    })
  }

}
