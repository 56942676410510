import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-add-model',
  templateUrl: './add-model.component.html',
  styleUrls: ['./add-model.component.scss']
})
export class AddModelComponent implements OnInit {
  
  @ViewChild('modelNoInput',{static: false}) modelNoInput: ElementRef;
  public brandList = ["abc", "ijk", "lmn", "pqr", "xyz"];
  public modelList = ["abc", "advadvad", "lmn", "pqr", "xyz"];

  constructor() { }

  ngOnInit() {
  }
  deleteModel(index) {
    this.modelList.splice(index, 1);
  }
  addModel() {
    if(this.modelNoInput.nativeElement.value != '')
    {
      this.modelList.push(this.modelNoInput.nativeElement.value);
      this.modelNoInput.nativeElement.value = '';
    }
  }
}
