import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as jsPDF from 'jspdf';
import { DatePipe } from '@angular/common'


@Component({
  selector: 'app-return-invoice-modal',
  templateUrl: './return-invoice-modal.component.html',
  styleUrls: ['./return-invoice-modal.component.scss']
})
export class ReturnInvoiceModalComponent {

  @Input() returnInvoiceDetail;

  constructor(public modal: NgbActiveModal, public datepipe: DatePipe) { }

  captureScreen() {
    const doc = new jsPDF();

    doc.setFontSize(18)
    doc.text('RETURN INVOICE DETAIL', 14, 22)
    doc.setFontSize(12)
    doc.setTextColor(100)
    doc.text(`Customer :`, 14, 30)
    doc.setTextColor(80)
    doc.text(`${this.returnInvoiceDetail.customer}`, 50, 30)
    doc.setTextColor(100)
    doc.text(`Manual Book No : `, 120, 30)
    doc.setTextColor(80)
    doc.text(`${this.returnInvoiceDetail.manualBookNo}`, 155, 30)
    doc.setTextColor(100)
    doc.text(`Invoice No : `, 14, 40)
    doc.setTextColor(80)
    doc.text(`${this.returnInvoiceDetail.manualBookNo}`, 50, 40)
    doc.setTextColor(100)
    doc.text(`Order Date : `, 120, 40)
    doc.setTextColor(80)
    doc.text(`${this.datepipe.transform(this.returnInvoiceDetail.invoiceDate)}`, 155, 40)
  doc.autoTable({
    head: [
      [
        'Return\nDate',
        'Product\nName',
        'Brand\nName',
        'Model\nNo',
        'Color',
        'Price',
        'Discount(%)',
        'Return\nQuantity',
        'Damage\nQuantity',
        'Total Return\nQuantity'
      ]
    ],
    body:[
      [this.datepipe.transform(this.returnInvoiceDetail.invoiceDate),
      this.returnInvoiceDetail.itemName,
      this.returnInvoiceDetail.brandName,
      this.returnInvoiceDetail.modelNumber,
      this.returnInvoiceDetail.color,
      this.returnInvoiceDetail.rate.toString(),
      this.returnInvoiceDetail.discount.toString(),
      this.returnInvoiceDetail.returnQty.toString(),
      this.returnInvoiceDetail.damageQty.toString(),
      this.returnInvoiceDetail.totalReturnQty.toString()]
    ],
    startY: 50,
    showHead: 'firstPage',
    styles: { overflow: 'ellipsize', cellWidth: 'wrap' },
    columnStyles: { text: { cellWidth: 'auto' } }
  })

    doc.setTextColor(100)
    doc.text(`Return Quantity : `, 115, doc.autoTable.previous.finalY + 10)
    doc.setTextColor(80)
    doc.text(`${this.returnInvoiceDetail.totalReturnQty}`, 175, doc.autoTable.previous.finalY + 10)
    doc.setTextColor(100)
    doc.text(`Total Amount : `, 115, doc.autoTable.previous.finalY + 20)
    doc.setTextColor(80)
    doc.text(`${this.returnInvoiceDetail.totalReturnQty * this.returnInvoiceDetail.rate}`, 175, doc.autoTable.previous.finalY + 20)
    doc.setTextColor(100)
    doc.text(`Total Return Amount : `, 115, doc.autoTable.previous.finalY + 30)
    doc.setTextColor(80)
    doc.text(`${this.returnInvoiceDetail.returnAmmount}`, 175, doc.autoTable.previous.finalY + 30)
    doc.save(`return-invoice.pdf`);
  }
}
