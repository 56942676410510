import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CustomerService, SharedService } from "./../../services";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Cities } from "./../../services/cities/cities";

@Component({
  selector: "app-add-customer",
  templateUrl: "./add-customer.component.html",
  styleUrls: ["./add-customer.component.scss"],
})
export class AddCustomerComponent implements OnInit {
  public addCustomerForm: FormGroup;
  public isEdit;
  public editCustomer;
  public isButtonDisable: boolean = false;

  isLoad: boolean;
  errMsg: string = "";
  customerList = [];
  citiesList = [...Cities];

  constructor(
    private fb: FormBuilder,
    private CS: CustomerService,
    private SS: SharedService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.intializeForm();
  }

  intializeForm() {
    let customerId = this.activatedRoute.snapshot.params.customerId;
    if (customerId) {
      this.CS.getOneCustomerDetail(customerId).subscribe(
        (detail) => {
          this.isEdit = true;
          this.editCustomer = detail;
          this.addCustomerForm = this.fb.group({
            companyName: [detail.companyName, Validators.required],
            clientName: [detail.clientName, Validators.required],
            address: [detail.address],
            contact1: [detail.contact1],
            contact2: [detail.contact2],
            area: [detail.area],
            city: [detail.city, Validators.required],
            email: [detail.email],
            website: [detail.website],
            stRegisteration: [detail.stRegisteration],
            aboutCompany: [detail.aboutCompany],
          });
        },
        (err) => {
          this.toastr.error(err.error.message, "Error!");
        }
      );
    } else {
      this.addCustomerForm = this.fb.group({
        companyName: ["", Validators.required],
        clientName: ["", Validators.required],
        address: [""],
        contact1: [""],
        contact2: [""],
        area: [""],
        city: [""],
        email: [""],
        website: [""],
        stRegisteration: [""],
        aboutCompany: [""],
      });
    }
  }

  submit() {
    if (!this.addCustomerForm.valid) return;
    this.isButtonDisable = true;
    this.isLoad = true;
    let obj = this.addCustomerForm.value;
    obj["history"] = this.SS.getHistory(obj, "add", "", null, null);
    this.CS.addCustomer(obj).subscribe(
      (result) => {
        this.router.navigateByUrl("/dashboard/customer");
      },
      (err) => {
        this.isLoad = false;
        this.isButtonDisable = false;
        this.toastr.error(err.error.message, "Error!");
      }
    );
  }

  update() {
    if (!this.addCustomerForm.valid) return;
    this.isButtonDisable = true;
    this.isLoad = true;
    let obj = this.addCustomerForm.value;
    let changes = this.SS.detectChange(
      this.addCustomerForm.value,
      this.editCustomer
    );
    obj["history"] = this.SS.getHistory(obj, "update", changes, null, null);
    obj["_id"] = this.editCustomer._id;
    this.CS.updateCustomer(obj).subscribe(
      (result) => {
        this.router.navigateByUrl("/dashboard/customer");
      },
      (err) => {
        this.isLoad = false;
        this.isButtonDisable = false;
        this.toastr.error(err.error.message, "Error!");
      }
    );
  }
}
