import { Action } from "@ngrx/store";

export const GET_BRAND = "GET_BRAND";
export const GET_BRAND_SUCCESS = "GET_BRAND_SUCCESS";
export const GET_BRAND_ERROR = "GET_BRAND_ERROR";
export const ADD_BRAND = "ADD_BRAND";
export const ADD_BRAND_SUCCESS = "ADD_BRAND_SUCCESS";
export const DELETE_BRAND = "DELETE_BRAND";
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS";
export const UPDATE_BRAND = "UPDATE_BRAND";
export const UPDATE_BRAND_SUCCESS = "UPDATE_BRAND_SUCCESS";
export const GET_ITEM_BRAND = "GET_ITEM_BRAND";
export const GET_ITEM_BRAND_SUCCESS = "GET_ITEM_BRAND_SUCCESS";
export const GET_ITEM_BRAND_ERROR = "GET_ITEM_BRAND_ERROR";

export class GetBrand implements Action {
  readonly type = GET_BRAND;

  constructor(public payload: any) {
  }
}

export class GetBrandSuccess implements Action {
  readonly type = GET_BRAND_SUCCESS;
  constructor(public payload: any) {
  }
}

export class GetBrandError implements Action {
  readonly type = GET_BRAND_ERROR;
  constructor(public payload: any) {}
}

export class AddBrand implements Action {
  readonly type = ADD_BRAND;
  constructor(public payload: any) {}
}
export class AddBrandSuccess implements Action {
  readonly type = ADD_BRAND_SUCCESS;
  constructor(public payload: any) {}
}
export class DeleteBrand implements Action {
  readonly type = DELETE_BRAND;
  constructor(public payload: any) {}
}
export class DeleteBrandSuccess implements Action {
  readonly type = DELETE_BRAND_SUCCESS;
  constructor(public payload: any) {}
}
export class UpdateBrand implements Action {
  readonly type = UPDATE_BRAND;
  constructor(public payload: any) {}
}
export class UpdateBrandSuccess implements Action {
  readonly type = UPDATE_BRAND_SUCCESS;
  constructor(public payload: any) {}
}

export class GetItemBrand implements Action {
  readonly type = GET_ITEM_BRAND;

  constructor(public payload: any) {
  }
}

export class GetItemBrandSuccess implements Action {
  readonly type = GET_ITEM_BRAND_SUCCESS;
  constructor(public payload: any) {
  }
}

export class GetItemBrandError implements Action {
  readonly type = GET_ITEM_BRAND_ERROR;
  constructor(public payload: any) {}
}

export type Actions =
  | GetBrand
  | GetBrandSuccess
  | GetBrandError
  | AddBrand
  | AddBrandSuccess
  | DeleteBrand
  | DeleteBrandSuccess
  | UpdateBrand
  | UpdateBrandSuccess
  | GetItemBrand
  | GetItemBrandSuccess
  | GetItemBrandError;
