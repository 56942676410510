import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fFromDate'
})
export class FFromDatePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let date = args[0];
    let type = args[1];

    switch (type) {
      case 'customer':
        return date ? value.filter((val) => {
          return val.date >= date
        }) : value;
        break;

      case 'pettyCashList':
        return date ? value.filter((val) => {
          return val.date >= date
        }) : value;
        break;
      case 'recieveMoneyList':
        return date ? value.filter((val) => {
          return val.date >= date
        }) : value;
        break;

      case 'pdfList':
        return date ? value.filter((val) => {
          return val.date >= date
        }) : value;
        break;

      case 'bankLedgerList':
        return date ? value.filter((val) => {
          return val.date >= date
        }) : value;
        break;

      case 'stockSummaryList':
        return date ? value.filter((val) => {
          return val.date >= date
        }) : value;
        break;

      case 'stockDamageList':
        return date ? value.filter((val) => {
          return val.date >= date
        }) : value;
        break;

      case 'stockDataList':
        return date ? value.filter((val) => {
          return val.date >= date
        }) : value;
        break;

      case 'returnInvoice':
        return date ? value.filter((val) => {
          return val.date >= date
        }) : value;
        break;

      case 'invoiceReport':
        return date ? value.filter((val) => {
          return new Date(val.date) >= new Date(date)
        }) : value;
        break;

      default:
        return value;
    }
  }

}
