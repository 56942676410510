import { Injectable } from "@angular/core";
import { HttpService } from "./../http/http.service";
import { from, fromEventPattern } from "rxjs";
import { ReturnStatement } from "@angular/compiler";
import { catchError, map } from "rxjs/operators";
import { environment } from "./../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ItemService {
  items: any;

  constructor(private http: HttpService) {}

  public getItems() {
    return this.items;
  }

  public addItem(obj) {
    return this.http.post(
      `${environment.baseUrl}/api/item/?token=${localStorage.getItem("STUID")}`,
      obj
    );
  }

  public getItemsFromBack() {
    return this.http.get(
      `${environment.baseUrl}/api/item/?token=${localStorage.getItem("STUID")}`
    );
  }

  public deleteItem(id) {
    return this.http
      .delete(
        `${environment.baseUrl}/api/item/${id}?token=${localStorage.getItem(
          "STUID"
        )}`
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err) => {
          return err;
        })
      );
  }

  public editItem(obj) {
    return this.http.put(
      `${environment.baseUrl}/api/item/?token=${localStorage.getItem("STUID")}`,
      obj
    );
  }
}
