import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'
import {
  SaleInvoiceService,
  CustomerService,
  StockService,
  SharedService
} from './../../services'
import { Router, ActivatedRoute } from '@angular/router'
import { DatePipe } from '@angular/common'
import { ToastrService } from 'ngx-toastr'
import { NumberPipe } from './../../pipe/index'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from './../confirm-modal/confirm-modal.component';


@Component({
  selector: 'app-sale-invoice',
  templateUrl: './sale-invoice.component.html',
  styleUrls: ['./sale-invoice.component.scss']
})
export class SaleInvoiceComponent implements OnInit {
  @Output() saleInvForm: EventEmitter<any>
  @Output() invSelectForm: EventEmitter<any>
  public saleInvoiceForm: FormGroup
  public invoiceSelectorForm: FormGroup
  public customers
  public itemList = []
  public itemBrands = []
  public modelList = []
  public colorList = []
  public invoiceDataList = []
  public saleInvoiceDataList = []
  public editObject: any
  public postedStatus: boolean = false
  public isEdit: boolean
  public editInvoiceOfInvoiceDetails = [];
  public isLoad: boolean
  public val = {}
  public profitLoss: number = 0
  public totalQuantity: number = 0
  public totalNetCost: number = 0
  public totalAmmount: number = 0
  public editInvoiceDetailValues: any
  public isEditInvoiceData: boolean
  public editInvoiceDataIndex: any
  public isDamageInvoice: boolean = false
  public isButtonDisable: boolean = false

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private SIS: SaleInvoiceService,
    private CS: CustomerService,
    private SS: StockService,
    private HSS: SharedService,
    private activatedRoute: ActivatedRoute,
    public numberPipe: NumberPipe,
    public datepipe: DatePipe,
    private toastr: ToastrService,
    private _confirmDeleteUserModal: NgbModal
  ) {
    this.saleInvForm = new EventEmitter()
  }

  ngOnInit() {
    let saleInvoiceDetailList = JSON.parse(localStorage.getItem('saleInvoiceDataList'))
    if (saleInvoiceDetailList) {
      this.saleInvoiceDataList = saleInvoiceDetailList.reduce((acc, cur) => {
        let obj = Object.assign({}, cur);
        obj['brandId'] = obj.brandId._id;
        obj['itemId'] = obj.itemId._id;
        acc.push(obj);
        return acc;
      }, []);
      this.invoiceDataList = saleInvoiceDetailList
      this.calculateInvoiceSummeryDetails()
    }

    this.getItems()
    this.getCustomers()
    this.initializeForm()
  }

  initializeForm() {
    if (this.router.url.split('/')[2].split('-')[0] == 'damage') this.isDamageInvoice = true;

    let invoice = this.activatedRoute.snapshot.params.invoiceId;

    if (invoice) {
      this.SIS.getInvoiceWithInvoiceDetails(invoice).subscribe(
        result => {
          this.isEdit = true;
          localStorage.removeItem('saleInvoiceDataList')
          this.saleInvoiceDataList = [];
          this.editObject = result;
          this.editInvoiceOfInvoiceDetails = [...result.invoiceDetails];
          this.invoiceDataList = result.invoiceDetails;
          let date = this.datepipe.transform(result.invoice.date, 'yyyy-MM-dd');
          this.totalQuantity = result.invoice.totalQty;
          this.totalNetCost = result.invoice.totalNetCost;
          this.totalAmmount = result.invoice.totalAmmount;
          this.postedStatus = result.invoice.postedStatus;
          this.profitLoss = Math.trunc(result.invoice.profitLoss);
          this.invoiceSelectorForm = this.fb.group({
            invoiceNo: [result.invoice.invoiceNo, Validators.required],
            manualBookNo: [result.invoice.manualBookNo, Validators.required],
            postedStatus: [, Validators.required],
            customerId: [result.invoice.customerId, Validators.required],
            date: [date, Validators.required]
          })
          for (var i = 0; i < this.invoiceDataList.length; ++i) {
            this.val = {
              afterDiscount: this.invoiceDataList[i].afterDiscount,
              totalCost: this.invoiceDataList[i].totalCost,
              discount: this.invoiceDataList[i].discount,
              pieceQty: this.invoiceDataList[i].pieceQty,
              rate: this.invoiceDataList[i].rate,
              color: this.invoiceDataList[i].color,
              modelNumber: this.invoiceDataList[i].modelNumber,
              itemId: this.invoiceDataList[i].itemId._id,
              brandId: this.invoiceDataList[i].brandId._id,
              invoiceId: this.invoiceDataList[i].invoiceId,
              _id: this.invoiceDataList[i]._id
            }
            this.saleInvoiceDataList.push(this.val)
          }
          if (this.postedStatus) {
            this.invoiceSelectorForm.patchValue({
              postedStatus: true
            })
          }
        },
        err => {
          this.toastr.error(err.error.message, 'Error!')
        }
      )
    } else {
      this.SIS.getInvoiceId().subscribe(
        result => {
          this.invoiceSelectorForm = this.fb.group({
            invoiceNo: [result.invoiceId, Validators.required],
            manualBookNo: ['', Validators.required],
            postedStatus: [, Validators.required],
            customerId: ['', Validators.required],
            date: [
              this.datepipe.transform(new Date(), 'yyyy-MM-dd'),
              Validators.required
            ]
          })
        },
        err => {
          this.toastr.error(err.error.message, 'Error!')
        }
      )
    }
    this.saleInvoiceForm = this.fb.group({
      itemId: ['', Validators.required],
      brandId: ['', Validators.required],
      modelNumber: ['', Validators.required],
      color: ['', Validators.required],
      stock: [0, Validators.required],
      avgCost: [0, Validators.required],
      rate: [null, Validators.required],
      pieceQty: [null, Validators.required],
      discount: [0, Validators.required],
      afterDiscount: [0, Validators.required],
      totalCost: [null, Validators.required]
    })
  }

  costCalculation(e) {
    this.saleInvoiceForm.patchValue({
      totalCost:
        this.saleInvoiceForm.value.pieceQty * this.saleInvoiceForm.value.rate
    })
    this.saleInvoiceForm.patchValue({
      afterDiscount:
        this.saleInvoiceForm.value.totalCost -
        this.saleInvoiceForm.value.totalCost *
        (this.saleInvoiceForm.value.discount / 100)
    })
  }

  getPostedStatus(e) {
    if (e.target.checked) {
      this.postedStatus = true
    }
  }
  getItems() {
    this.SS.getItemFromStockDetails().subscribe(
      result => {
        this.itemList = result
      },
      err => {
        this.toastr.error(err.error.message, 'Error!')
      }
    )
  }

  getItemBrands(id) {
    this.SS.getBrandsFromStockDetails(id).subscribe(
      result => {
        this.itemBrands = result
        this.saleInvoiceForm.patchValue({
          brandId: ''
        })
      },
      err => {
        this.toastr.error(err.error.message, 'Error!')
      }
    )
  }

  getModalNumber() {
    if (
      this.saleInvoiceForm.value.itemId != '' &&
      this.saleInvoiceForm.value.brandId != ''
    ) {
      let obj = {
        itemId: this.saleInvoiceForm.value.itemId,
        brandId: this.saleInvoiceForm.value.brandId
      }
      this.SS.getModelsFromStockDetails(obj).subscribe(
        result => {
          this.modelList = result
          this.saleInvoiceForm.patchValue({ modelNumber: '' })
        },
        err => {
          this.toastr.error(err.error.message, 'Error!')
        }
      )
    }
  }

  getColors() {
    if (
      this.saleInvoiceForm.value.itemId != '' &&
      this.saleInvoiceForm.value.brandId != '' &&
      this.saleInvoiceForm.value.modelNumber != ''
    ) {
      let obj = {
        itemId: this.saleInvoiceForm.value.itemId,
        brandId: this.saleInvoiceForm.value.brandId,
        modelNumber: this.saleInvoiceForm.value.modelNumber
      }
      this.SS.getColorsFromStockDetails(obj).subscribe(
        result => {
          this.colorList = result
          this.saleInvoiceForm.patchValue({
            color: ''
          })
        },
        err => {
          this.toastr.error(err.error.message, 'Error!')
        }
      )
    }
  }

  getStock() {
    if (
      this.saleInvoiceForm.value.itemId != '' &&
      this.saleInvoiceForm.value.brandId != '' &&
      this.saleInvoiceForm.value.modelNumber != '' &&
      this.saleInvoiceForm.value.color != ''
    ) {
      let obj = {
        itemId: this.saleInvoiceForm.value.itemId,
        brandId: this.saleInvoiceForm.value.brandId,
        modelNumber: this.saleInvoiceForm.value.modelNumber,
        color: this.saleInvoiceForm.value.color
      }
      if (this.isDamageInvoice) {
        this.SS.getDamageFromStockDetails(obj).subscribe(
          result => {
            this.saleInvoiceForm.patchValue({
              stock: result.stock,
              avgCost: result.price
            })
          },
          err => {
            this.toastr.error(err.error.message, 'Error!')
          }
        )
      } else {
        this.SS.getStockFromStockDetails(obj).subscribe(
          result => {
            this.saleInvoiceForm.patchValue({
              stock: result.stock,
              avgCost: result.price
            })
          },
          err => {
            this.toastr.error(err.error.message, 'Error!')
          }
        )
      }
    }
  }

  getCustomers() {
    this.CS.getCustomersFromBack().subscribe(
      result => {
        this.customers = result;
      },
      err => {
        this.toastr.error(err.error.message, 'Error!')
      }
    )
  }

  checkDuplication() {
    let obj = this.saleInvoiceForm.value;
    if (
      this.saleInvoiceDataList.length &&
      this.saleInvoiceDataList.find(
        detail =>
          detail.itemId == obj.itemId &&
          detail.brandId == obj.brandId &&
          detail.modelNumber.toLocaleLowerCase() ==
          obj.modelNumber.toLocaleLowerCase() &&
          detail.color.toLocaleLowerCase() == obj.color.toLocaleLowerCase()
      )
    ) {
      return true
    }
  }

  calculateInvoiceSummeryDetails() {
    this.profitLoss = this.invoiceDataList.reduce((acc, cu) => {
      return acc + (cu.afterDiscount - cu.pieceQty * cu.avgCost)
    }, 0)
    this.totalQuantity = this.invoiceDataList.reduce((acc, cu) => {
      return acc + cu.pieceQty
    }, 0)
    this.totalNetCost = this.invoiceDataList.reduce((acc, cu) => {
      return acc + cu.afterDiscount
    }, 0)
    this.totalAmmount = this.invoiceDataList.reduce((acc, cu) => {
      return acc + cu.pieceQty * cu.rate
    }, 0)
  }

  private confirmInvoicesDeleteModal: NgbModalRef;


  deleteLocalStorage() {
    this.profitLoss = 0;
    this.totalQuantity = 0;
    this.totalNetCost = 0;
    this.totalAmmount = 0;

    this.confirmInvoicesDeleteModal = this._confirmDeleteUserModal.open(ConfirmModalComponent)
    this.confirmInvoicesDeleteModal.componentInstance.title = `Delete tempraory Invoice Details `;
    this.confirmInvoicesDeleteModal.componentInstance.description = `Are you sure you want to delete this temporary invoice details, this will only delete from your computer not from backend ?`;

    this.confirmInvoicesDeleteModal.result.then(result => {
      localStorage.removeItem('saleInvoiceDataList');
      this.invoiceDataList = [];
    })
  }

  submit() {
    if (!this.saleInvoiceForm.valid) return

    if (!this.invoiceSelectorForm.value.date) {
      this.toastr.warning('kindly select invoice date', 'Warning!')
      return
    }

    if (this.checkDuplication()) {
      this.toastr.warning('Duplicate Invoice details', 'Warning!');
      return
    }

    if(this.saleInvoiceForm.value.pieceQty == 0){
      this.toastr.warning('Piece Quantity can not be 0', 'Warning!');
      return
    }


    if (this.isEdit && this.editInvoiceDetailValues && this.editInvoiceOfInvoiceDetails) {
      let oldPieceQty = this.editInvoiceOfInvoiceDetails.find(obj => { return this.saleInvoiceForm.value.itemId == obj.itemId._id && this.saleInvoiceForm.value.brandId == obj.brandId._id && this.saleInvoiceForm.value.modelNumber == obj.modelNumber && this.saleInvoiceForm.value.color == obj.color }).pieceQty;
      if ((this.saleInvoiceForm.value.pieceQty - oldPieceQty) > this.saleInvoiceForm.value.stock) {
        this.toastr.warning('Piece Quantity Out of stock', 'Warning!');
        return
      }

    } else if (this.editInvoiceDetailValues && (this.saleInvoiceForm.value.pieceQty > this.saleInvoiceForm.value.stock)) {
      this.toastr.warning('Piece Quantity Out of stock', 'Warning!');
      return
    } else if (this.saleInvoiceForm.value.pieceQty > this.saleInvoiceForm.value.stock && !this.isEditInvoiceData) {
      this.toastr.warning('Piece Quantity Out of stock', 'Warning!');
      return
    }

    if (!this.isEditInvoiceData) {

      let invoiceObj = this.saleInvoiceForm.value;

      this.resetForm();

      invoiceObj['invoiceId'] = this.invoiceSelectorForm.value.invoiceNo;

      let tableObj = {};

      let item = this.itemList.find(obj => obj.itemId._id == invoiceObj.itemId);

      let brand = this.itemBrands.find(obj => obj.brandId._id == invoiceObj.brandId);

      let listingData = Object.assign(tableObj, invoiceObj);

      let sendHistoryObj = Object.assign({}, invoiceObj);

      delete sendHistoryObj['brandId'];

      delete sendHistoryObj['itemId'];

      invoiceObj['history'] = this.HSS.getHistory(sendHistoryObj, 'add', '', null, null);

      this.saleInvoiceDataList.push(invoiceObj);

      delete listingData['itemId'];

      delete listingData['brandId'];

      listingData['itemId'] = item.itemId;

      listingData['brandId'] = brand.brandId;

      listingData['history'] = invoiceObj.history;

      this.invoiceDataList.push(listingData);

      if (!this.isEdit) localStorage.setItem('saleInvoiceDataList', JSON.stringify(this.invoiceDataList));

      this.calculateInvoiceSummeryDetails();

    } else {

      let invoiceObj = this.saleInvoiceForm.value;

      this.resetForm();

      let tableObj = {};

      let item = this.itemList.find(obj => obj.itemId._id == invoiceObj.itemId);

      let brand = this.itemBrands.find(obj => obj.brandId._id == invoiceObj.brandId);

      let listingData = Object.assign(tableObj, invoiceObj);

      delete invoiceObj['stock'];

      let oldInvoiceDetailValue = Object.assign({}, this.editInvoiceDetailValues);

      oldInvoiceDetailValue['itemName'] = oldInvoiceDetailValue.itemId.name;

      oldInvoiceDetailValue['brandName'] = oldInvoiceDetailValue.brandId.brandName;

      delete oldInvoiceDetailValue['invoiceId'];

      delete oldInvoiceDetailValue['itemId'];

      delete oldInvoiceDetailValue['brandId'];

      let sendHistoryObj = Object.assign({}, invoiceObj);

      sendHistoryObj['itemName'] = item.itemId.name;

      sendHistoryObj['brandName'] = brand.brandId.brandName;

      delete sendHistoryObj['brandId'];

      delete sendHistoryObj['itemId'];

      let changes = this.HSS.detectChange(sendHistoryObj, oldInvoiceDetailValue);

      if (this.editInvoiceDetailValues._id && this.editObject.invoice._id) {
        invoiceObj['_id'] = this.editInvoiceDetailValues._id;
        invoiceObj['invoiceId'] = this.editObject.invoice._id;
        invoiceObj['history'] = this.HSS.getHistory(oldInvoiceDetailValue, 'update', changes, null, null)
      };

      this.saleInvoiceDataList.push(invoiceObj)

      delete listingData['itemId'];

      delete listingData['brandId'];

      listingData['itemId'] = item.itemId;

      listingData['brandId'] = brand.brandId;

      listingData['history'] = invoiceObj.history;

      this.invoiceDataList.push(listingData);

      if (!this.isEdit) localStorage.setItem('saleInvoiceDataList', JSON.stringify(this.invoiceDataList));

      this.calculateInvoiceSummeryDetails();

      this.isEditInvoiceData = false;
    }
  }

  resetForm(){
    this.saleInvoiceForm.patchValue({
      modelNumber:'',
      color:'',
      stock:0,
      avgCost:0,
      rate:0,
      pieceQty:0,
      discount:0,
      afterDiscount:0,
      totalCost:null
    })
  }

  deleteOneDetail(invDetail, index) {

    this.invoiceDataList.splice(index, 1);

    this.saleInvoiceDataList.splice(index, 1);

    if (!this.isEdit) localStorage.setItem('saleInvoiceDataList', JSON.stringify(this.invoiceDataList));

    this.calculateInvoiceSummeryDetails();
  }

  deleteInvoiceFromList(invoice, i) {
    this.invoiceDataList.splice(i, 1);

    this.saleInvoiceDataList.splice(i, 1);

    let totalPieceAvgCost = invoice.pieceQty * invoice.avgCost;

    this.profitLoss -= invoice.afterDiscount - totalPieceAvgCost;

    this.totalQuantity -= invoice.pieceQty;

    this.totalNetCost -= invoice.afterDiscount;
  }

  getItemsForEditDetail(invoice) {
    this.SS.getItemFromStockDetails().subscribe(
      result => {
        this.itemList = result;
        this.getBrandsForEditDetail(invoice);
      },
      err => {
        this.toastr.error(err.error.message, 'Error!')
      }
    )
  }

  getBrandsForEditDetail(invoice) {
    let id = invoice.itemId._id
    this.SS.getBrandsFromStockDetails(id).subscribe(
      result => {
        this.itemBrands = result
        this.getModelForEditDetail(invoice)
      },
      err => {
        this.toastr.error(err.error.message, 'Error!')
      }
    )
  }

  getModelForEditDetail(invoice) {
    let obj = {
      itemId: invoice.itemId._id,
      brandId: invoice.brandId._id
    }
    this.SS.getModelsFromStockDetails(obj).subscribe(
      result => {
        this.modelList = result
        this.getColorForEditDetail(invoice)
      },
      err => {
        this.toastr.error(err.error.message, 'Error!')
      }
    )
  }

  getColorForEditDetail(invoice) {
    let obj = {
      itemId: invoice.itemId._id,
      brandId: invoice.brandId._id,
      modelNumber: invoice.modelNumber
    }
    this.SS.getColorsFromStockDetails(obj).subscribe(
      result => {
        this.colorList = result
        if (this.colorList) {
          let obj1 = {
            itemId: invoice.itemId._id,
            brandId: invoice.brandId._id,
            modelNumber: invoice.modelNumber,
            color: invoice.color
          }
          this.SS.getStockFromStockDetails(obj1).subscribe(
            result => {
              this.saleInvoiceForm.patchValue({
                itemId: invoice.itemId._id,
                brandId: invoice.brandId._id,
                modelNumber: invoice.modelNumber,
                color: invoice.color,
                stock: result.stock,
                avgCost: result.price
              })
            },
            err => {
              this.toastr.error(err.error.message, 'Error!')
            })
        }
      },
      err => {
        this.toastr.error(err.error.message, 'Error!')
      }
    )
  }

  editDetail(invoice, index) {
    if (!this.isEditInvoiceData) {
      this.getItemsForEditDetail(invoice)
      this.editInvoiceDetailValues = invoice;
      this.isEditInvoiceData = true;
      this.editInvoiceDataIndex = index;
      this.saleInvoiceForm.patchValue({
        rate: invoice.rate,
        pieceQty: invoice.pieceQty,
        discount: invoice.discount,
        afterDiscount: invoice.afterDiscount,
        totalCost: invoice.totalCost
      })
      this.deleteInvoiceFromList(invoice, index);
    } else {
      alert('First save the the current record')
    }
  }

  saveIvoice() {
    if (
      !this.invoiceSelectorForm.value.customerId &&
      !this.invoiceSelectorForm.value.manualBookNo
    ) {
      alert('Add Invoice Number and Customer Name')
      return
    }
    let invoice = {}
    this.isButtonDisable = true;
    this.isLoad = true;

    if (!this.isEdit) {
      invoice = {
        date: this.invoiceSelectorForm.value.date,
        customerId: this.invoiceSelectorForm.value.customerId,
        invoiceNo: this.invoiceSelectorForm.value.invoiceNo,
        manualBookNo: this.invoiceSelectorForm.value.manualBookNo,
        totalQty: this.totalQuantity,
        totalNetCost: this.totalNetCost,
        postedStatus: this.postedStatus,
        balance: 0
      }

      invoice['history'] = this.HSS.getHistory(invoice, 'add', '', this.customers, null)

      let obj = { invoice: invoice }

      obj['invoiceDetails'] = this.saleInvoiceDataList

      if (this.isDamageInvoice) {
        this.SIS.addDamageSaleInvoice(obj).subscribe(
          result => {
            this.router.navigateByUrl('/dashboard/sale-report')
          },
          err => {
            this.toastr.error(err.error.message, 'Error!')
          }
        )
      } else {
        this.SIS.addSaleInvoice(obj).subscribe(
          result => {
            localStorage.removeItem('saleInvoiceDataList')
            this.router.navigateByUrl('/dashboard/sale-report')
          },
          err => {
            this.isButtonDisable = false
            this.isLoad = false
            this.toastr.error(err.error.message, 'Error!')
          }
        )
      }
    } else {

      invoice = {
        _id: this.editObject.invoice._id,
        date: this.invoiceSelectorForm.value.date,
        customerId: this.invoiceSelectorForm.value.customerId,
        invoiceNo: this.invoiceSelectorForm.value.invoiceNo,
        manualBookNo: this.invoiceSelectorForm.value.manualBookNo,
        totalQty: this.totalQuantity,
        totalNetCost: this.totalNetCost,
        postedStatus: this.postedStatus,
        balance: 0
      }

      let obj = { invoice: invoice }

      let changes = this.HSS.detectChange(invoice, this.editObject.invoice)

      changes != undefined ? invoice['history'] = this.HSS.getHistory(this.editObject.invoice, 'update', changes, this.customers, null) : invoice['history'] = this.HSS.getHistory(invoice, 'add', '', this.customers, null)

      obj['invoiceDetails'] = this.saleInvoiceDataList

      this.SIS.ubdateSaleInvoice(obj).subscribe(
        result => {
          localStorage.removeItem('saleInvoiceDataList');
          this.router.navigateByUrl('/dashboard/sale-report');
        },
        err => {
          this.isButtonDisable = false
          this.isLoad = false
          this.toastr.error(err.error.message, 'Error!')
        }
      )
    }
  }
}
