import { Action } from '@ngrx/store'

export const GET_STOCK ='GET_STOCK';
export const GET_STOCK_SUCCESS ='GET_STOCK_SUCCESS';
export const GET_STOCK_ERROR ='GET_STOCK_ERROR';
export const ADD_STOCK ='ADD_STOCK';
export const ADD_STOCK_SUCCESS ='ADD_STOCK_SUCCESS';
export const DELETE_STOCK ='DELETE_STOCK';
export const DELETE_STOCK_SUCCESS ='DELETE_STOCK_SUCCESS';
export const UPDATE_STOCK ='UPDATE_STOCK';
export const UPDATE_STOCK_SUCCESS ='UPDATE_STOCK_SUCCESS';


export class GetStock implements Action {
    readonly type = GET_STOCK;
    constructor(public payload: any){}
}

export class GetStockSuccess implements Action {
    readonly type = GET_STOCK_SUCCESS;
    constructor(public payload: any){}
}
export class GetStockError implements Action {
    readonly type = GET_STOCK_ERROR;
    constructor(public payload: any){}
}

export class AddStock implements Action {
    readonly type = ADD_STOCK;
    constructor(public payload: any){}
}
export class AddStockSuccess implements Action {
    readonly type = ADD_STOCK_SUCCESS;
    constructor(public payload: any){}
}
export class DeleteStock implements Action {
    readonly type = DELETE_STOCK;
    constructor(public payload: any){}
}
export class DeleteStockSuccess implements Action {
    readonly type = DELETE_STOCK_SUCCESS;
    constructor(public payload: any){}
}
export class UpdateStock implements Action {
    readonly type = UPDATE_STOCK;
    constructor(public payload: any){}
}
export class UpdateStockSuccess implements Action {
    readonly type = UPDATE_STOCK_SUCCESS;
    constructor(public payload: any){}
}

export type Actions = GetStock | GetStockSuccess | GetStockError | AddStock | AddStockSuccess | DeleteStock| DeleteStockSuccess | UpdateStock | UpdateStockSuccess;
