import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'number'
})
export class NumberPipe implements PipeTransform {

  transform(value: number, ...args: any[]): any {
    return value ? new Intl.NumberFormat().format(value) : 0;
  }

}
