import { Component, OnInit } from '@angular/core';
import { ItemList } from '../../store/interface/item';
import { Store } from '@ngrx/store';
import { Subscription, Observable, fromEventPattern } from 'rxjs'
import { State } from '../../store/reducer/item.reducer'
import * as ItemAction from '../../store/action/item.action';
import { filter } from 'rxjs/operators';
import { ItemService } from './../../services'


@Component({
  selector: 'app-items-container',
  templateUrl: './items-container.component.html',
  styleUrls: ['./items-container.component.scss']
})
export class ItemsContainerComponent {

  constructor(private store: Store<State>, private IT: ItemService) {
  }
}
