import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Cloudinary } from '@cloudinary/angular-5.x';
import { Router } from '@angular/router';
import { UploadPdfService, SharedService } from './../../services';
import axios from "axios";
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-upload-pdf',
  templateUrl: './upload-pdf.component.html',
  styleUrls: ['./upload-pdf.component.scss']
})
export class UploadPdfComponent implements OnInit {
  public uploadPdfForm: FormGroup;
  public file: any;
  public show: boolean = false;
  public isLoad: boolean = false;
  public pdfName: String;
  public isButtonDisable:boolean = false;

  constructor(
    private fb: FormBuilder,
    private cloudinary: Cloudinary,
    private UPS: UploadPdfService,
    private SS: SharedService,
    private router: Router,
    public datepipe: DatePipe,
    private toastr: ToastrService

  ) {
  }

  ngOnInit() {
    this.uploadPdfForm = this.fb.group({
      pdfName: ['', Validators.required],
      date: [this.datepipe.transform(new Date(), 'yyyy-MM-dd'), Validators.required],
    })
  }

  onFileSelect(e) {
    if (e.target.files[0].type == 'application/pdf') {
      this.pdfName = e.target.files[0].name;
      this.file = e.target.files[0];
      this.show = true;
    }
    else {
      alert('Selected file must be pdf')
    }
  }

  submit() {
    if(!this.show || !this.uploadPdfForm.valid){
      return
    }
    this.isButtonDisable = true;
    this.isLoad = true;
    var formData = new FormData();
    formData.append('file', this.file)
    formData.append('upload_preset',  this.cloudinary.config().upload_preset)
    this.isLoad = true

    axios({
      url: `https://api.cloudinary.com/v1_1/${this.cloudinary.config().cloud_name}/upload`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: formData
    }).then((res) => {
      let pdfObj = {
        pdfName: this.uploadPdfForm.value.pdfName,
        date: this.uploadPdfForm.value.date,
        publicId: res.data.public_id,
        pdfUrl: res.data.url
      }
      pdfObj['history'] = this.getHistory(Object.assign({},pdfObj));
      this.UPS.addUploadPdf(pdfObj).subscribe(result => {
        this.isLoad = false;
        this.router.navigateByUrl('/dashboard/pdf-list');
      },err=>{
        this.isLoad = false;
        this.toastr.error(err.error.message,'Error!');
      });
    }).catch((err) => {
      this.isLoad = false
      this.isButtonDisable = false
      this.toastr.error(err.error.message,'Error!');

    })
  }
  getHistory(obj){
    delete obj['publicId']
    return this.SS.getHistory( obj, 'add', '', null, null);
  }
}
