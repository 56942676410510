import * as ItemAction from '../action/item.action'

export interface State {
    item: any,
}

const initialState: State = {
    item: null,
}
export function getItemReducer(state = initialState, action: ItemAction.Actions) {
    switch (action.type) {
        case ItemAction.GET_ITEM:
            return {
                ...state,
                item: action.payload,
            }
        case ItemAction.GET_ITEM_SUCCESS:
            return {
                ...state,
                item: action.payload,
            }

        case ItemAction.GET_ITEM_ERROR:
            return {
                ...state,
                item: null,
            }
        case ItemAction.ADD_ITEM:
            return {
                ...state,
                item: null
            }
        case ItemAction.ADD_ITEM_SUCCESS:
            return {
                ...state,
                item: action.payload
            }
        case ItemAction.DELETE_ITEM:
            return {
                ...state,
                item: null
            }
        case ItemAction.DELETE_ITEM_SUCCESS:
            return {
                ...state,
                item: action.payload
            }
        case ItemAction.UPDATE_ITEM:
            return {
                ...state,
                item: null
            }
        case ItemAction.UPDATE_ITEM_SUCCESS:
            return {
                ...state,
                item: action.payload
            }
        default:
            break;
    }
}
