import {
  Component,
  OnInit,
  Input,
} from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { StockService } from './../../services'
import { ToastrService } from 'ngx-toastr'
import { NumberPipe } from './../../pipe/index';

import jsPDF from 'jspdf'
import 'jspdf-autotable'

@Component({
  selector: 'app-stock-detail-modal',
  templateUrl: './stock-detail-modal.component.html',
  styleUrls: ['./stock-detail-modal.component.scss']
})
export class StockDetailModalComponent implements OnInit {
  @Input() stockId: any

  stock = {}
  stockDetail = []

  constructor (
    public modal: NgbActiveModal,
    public SS: StockService,
    private toastr: ToastrService,
    public numberPipe:NumberPipe
  ) {}

  ngOnInit () {
    if (this.stockId) {
      this.SS.getStockWithDetails(this.stockId).subscribe(
        result => {
          this.stock = result.stock
          this.stockDetail = result.stockDetails
        },
        err => {
          this.toastr.error(err.error.message, 'Error!')
        }
      )
    }
  }

  captureScreen () {
    const doc = new jsPDF('l')

    doc.setFontSize(18)
    doc.text('Stock Details', 14, 22)
    doc.autoTable({ html: '#my-table' })

    // Or use javascript directly:
    doc.autoTable({
      head: [
        [
          'Product\nName',
          'Brand\nName',
          'Model\nNumber',
          'Color',
          'Size',
          'Initial qty',
          'Damage qty',
          'Sold qty',
          'Actual qty',
          'Unit Price',
          'Total Price'
        ]
      ],
      body: this.stockDetail.reduce((acc, cur) => {
        acc.push([
          cur.itemName,
          cur.brandName,
          cur.modelNumber,
          cur.color,
          cur.size,
          this.numberPipe.transform(cur.initialQty),
          this.numberPipe.transform(cur.damageQty),
          this.numberPipe.transform(cur.soldQty),
          this.numberPipe.transform(cur.actualQty),
          this.numberPipe.transform(cur.unitCost),
          this.numberPipe.transform(cur.totalCost)
        ])
        return acc
      }, []),
      startY: 30,
      showHead: 'firstPage',
      styles: { overflow: 'ellipsize', cellWidth: 'wrap' },
      columnStyles: { text: { cellWidth: 'auto' } }
    })

    doc.save('saleReport.pdf');
  }
}
