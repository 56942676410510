import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  damageStock: boolean = false;

  constructor() {}

  public getHistory(obj, type, change, customers, banks) {
    let arr = [];
    if (type == "add") {
      Object.keys(obj).map((a) => {
        switch (a) {
          case "customerId":
            let customer = customers.find((customer) => {
              return customer._id == obj["customerId"];
            });

            arr.push({
              objkey: "Customer Name",
              newValue: customer.companyName,
            });
            break;
          case "bankId":
            let bank = banks.find((bank) => {
              return bank._id == obj["bankId"];
            });
            arr.push({ objkey: "Bank Name", newValue: bank.name });
            break;
          case "date":
            arr.push({
              objkey: "Date",
              newValue: obj[a].toString().slice(0, 10),
            });
            break;
          case "_id":
            break;
          case "status":
            break;
          case "__v":
            break;
          case "updatedAt":
            break;
          case "createdAt":
            break;
          default:
            arr.push({ objkey: this.camelCaseToWords(a), newValue: obj[a] });
            break;
        }
      });
    } else if ("update" && change != "" && change != undefined) {
      Object.keys(obj).map((a) => {
        switch (a) {
          case "customerId":
            let customer = customers.find((customer) => {
              return customer._id == obj["customerId"];
            });
            arr.push({
              objkey: "Customer Name",
              newValue: change[a],
              oldValue: customer.companyName,
            });
            break;
          case "bankId":
            let bank = banks.find((bank) => {
              return bank._id == obj["bankId"];
            });
            arr.push({
              objkey: "Bank Name",
              newValue: change[a],
              oldValue: bank.name,
            });
            break;
          case "_id":
            break;
          case "status":
            break;
          case "__v":
            break;
          case "updatedAt":
            break;
          case "createdAt":
            break;
          case "date":
            arr.push({
              objkey: "Date",
              newValue: change[a] ? change[a].toString().slice(0, 10) : "",
              oldValue: obj[a].toString().slice(0, 10),
            });
            break;
          default:
            arr.push({
              objkey: this.camelCaseToWords(a),
              newValue: change[a],
              oldValue: obj[a],
            });
            break;
        }
      });
    }
    return JSON.stringify(arr);
  }

  getUnChangeStock(obj) {
    let arr = [];
    Object.keys(obj).map((a) => {
      arr.push({ objkey: this.camelCaseToWords(a), oldValue: obj[a] });
    });
    return JSON.stringify(arr);
  }

  camelCaseToWords(str) {
    return str
      .match(/^[a-z]+|[A-Z][a-z]*/g)
      .map(function (x) {
        return x[0].toUpperCase() + x.substr(1).toLowerCase();
      })
      .join(" ");
  }

  public detectChange(formData, trueData) {
    let UpdatedForm = formData;
    let keys = Object.keys(UpdatedForm);
    let changedFormObject = {};
    for (let i = 0; i < keys.length; ++i) {
      if (UpdatedForm[keys[i]] != trueData[keys[i]]) {
        if (
          keys[i] == "date" &&
          this.fromJsonDate(UpdatedForm[keys[i]]) ==
            this.fromJsonDate(trueData[keys[i]])
        ) {
          continue;
        } else {
          changedFormObject[keys[i]] = UpdatedForm[keys[i]];
        }
      }
    }

    if (Object.entries(changedFormObject).length > 0) {
      return changedFormObject;
    }
  }

  fromJsonDate(jDate): string {
    const bDate: Date = new Date(jDate);
    return bDate.toISOString().substring(0, 10); //Ignore time
  }

  public setSaleDamageStock(stockDetails) {
    this.damageStock = stockDetails;
  }

  public getSaleDamageStock() {
    return this.damageStock;
  }
}
