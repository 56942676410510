// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //  baseUrl:'https://sasko-backend.herokuapp.com',
  baseUrl: 'https://sasco.inappsolutions.com',
  // baseUrl: "http://localhost:3005",
  // baseUrl:'https://sasco.inappsolutions.com',
  cloudinaryName: "sascotraders",
  cloudinaryApiKey: "666827516866947",
  cloudinaryApiSecret: "2xjOHXAmLGWN6P-SOKzBD3wu4zo",
  CloudinaryUploadPreset: "sascotrader",
  // cloudinaryName: 'glimpax',
  // cloudinaryApiKey: '391855435394826',
  // cloudinaryApiSecret: 'fvn7mo97HbbwxeT8pIH_pCoIOsE',
  // CloudinaryUploadPreset: 'glimpax',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
``;
