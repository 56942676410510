import { Component, OnInit } from '@angular/core';
import { BankService } from './../../services'
import * as jsPDF from 'jspdf';
import { DatePipe } from '@angular/common';
import { BankNamePipe, FFromDatePipe, FToDatePipe,NumberPipe } from './../../pipe/index';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-bank-ledger',
  templateUrl: './bank-ledger.component.html',
  styleUrls: ['./bank-ledger.component.scss']
})
export class BankLedgerComponent implements OnInit {

  public fromDate: Date;
  public toDate: Date;
  public searchName: string = '';
  public bankLedgerList = [];
  public balance: number;
  public debit: number;
  public credit: number;
  public isLoading: boolean;
  public errMsg: string;
  public searchBank: string;
  public showDetailDescription:boolean = false;
  public showDetailIndex:number = null;
  public banks;

  constructor(private BS: BankService,
    public datepipe: DatePipe,
    public BankNamePipe: BankNamePipe,
    public fromDatePipe: FFromDatePipe,
    public toDatePipe: FToDatePipe,
    public numberPipe:NumberPipe,
    private toastr: ToastrService

  ) {

  }

  ngOnInit() {
    this.isLoading = true;
    this.getLedgerListing();
    this.getBanks();

  }

  getBanks() {
    if (!this.BS.getBanks()) {
      this.BS.getBanksFromBack().subscribe(result => {
        this.banks = result;
      },err=>{
        this.toastr.error(err.error.message,'Error!');
      });    } else {
      this.banks = this.BS.getBanks();
    }
  }

  getLedgerListing(){
    this.BS.getBankLedgerListing().subscribe(data => {
      this.bankLedgerList = data;
      this.bankLedgerList.map((ledger, i) => { return this.bankLedgerList[i]['credit'] = 0 });
      this.credit = 0;
      this.balance = data.reduce((accumulator, current) => { return accumulator + current.amount }, 0);
      this.debit = this.balance;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      this.errMsg = err.error.message;
      this.toastr.error(err.error.message,'Error!');
    })
  }

  clearAll() {
    this.searchBank = null;
    this.fromDate = null;
    this.toDate = null;
  }

  captureScreen() {
    if (this.bankLedgerList) {
      let list = this.BankNamePipe.transform(this.bankLedgerList,this.searchBank);
      list = this.fromDatePipe.transform(list,this.fromDate,'customer');
      list = this.toDatePipe.transform(list,this.toDate,'customer');
      const doc = new jsPDF()

      doc.setFontSize(18)
      doc.text('Bank Ledger report', 14, 22)
      doc.autoTable({ html: '#my-table' })
    // Or use javascript directly:
    doc.autoTable({
      head: [
        [
          'Transaction Date',
          'Transaction Id',
          'Bank Name',
          'Description',
          'Debit',
          'Credit',
          'Balance'
        ]
      ],
      body: list.reduce((acc, cur) => {
        let date = this.datepipe.transform(cur.date, 'yyyy-MM-dd');
      acc.push([
          date,
          cur.transactionId,
          cur.bankName,
          cur.description ? cur.description : '',
          this.numberPipe.transform(cur.amount),
          this.numberPipe.transform(cur.credit),
          this.numberPipe.transform(cur.amount)
        ])
        return acc
      }, []),
      startY: 30,
      showHead: 'firstPage',
      styles: { overflow: 'ellipsize', cellWidth: 'wrap' },
      columnStyles: {  3: {
        columnWidth: 'auto'
      } }    })
      let date = new Date().toDateString()
      doc.save(`bank-ledger-${date}.pdf`);
    }
  }

}
